<template>
    <div style=" margin: -15px -15px;overflow: hidden;background-color: #f0f2f5;min-height: 680px">
      <a-spin :spinning="spinning">
        <div class="feature-top">
          <div class="feature-top-left">{{titleTop}}</div>
          <div class="feature-top-right">
            <a-button
                @click="listPersons"
                style="margin-right: 10px">人员名单</a-button>
  <!--          <span>会议地址 </span>-->
  <!--          <a-input disabled style="width: 10%;" v-model="linkVal"/>-->
            <a-input disabled style="width: 200px" placeholder="会议地址"/>
            <a-icon
                style="margin-left: 5px"
                @click="copySum(linkVal)"
                class="feature-top-right-icon" type="copy" />
          </div>
        </div>
        <div class="feature-list">
          <a-row :gutter="16">
            <a-col class="gutter-row" :span="6">
              <div class="gutter-box-add" @click="addCard">
                +新增
              </div>
            </a-col>
            <a-col class="gutter-row" :span="6" v-for="(list,index) in dataList" :key="index">
              <div class="gutter-box">
                <div class="gutter-box-top">
                  <div class="image">
                    <img v-if="list.icon" :src="list.icon" alt="">
                  </div>
                  <div class="gutter-box-top-title">
                    <div class="gutter-box-top-title-title">{{ list.title}}</div>
                    <div class="gutter-box-top-title-type">类型：{{list.intro}}-{{list.funcCode}}</div>
                    <div class="gutter-box-top-title-open">
                      <a-switch
                        v-model="list.isPublished === 1?true:false"
                        checked-children="开"
                        un-checked-children="关"
                        @change="functionModuleSwitch(list,list.isPublished === 1?0:1)"
                        default-checked />
                      <span class="upDown">
                        <a class="upDown_up" @click="shiftDown(list.id)">上移</a>
                        <a class="upDown_up" @click="moveUp(list.id)" style="margin-left: 5px">下移</a></span>
                    </div>
                  </div>
                </div>
                <div class="gutter-box-btn">
                  <div class="gutter-box-btn-font">
                    <div
                        class="font"
                        v-if="list.funcCode !== 'XFSJ' && list.funcCode !== 'DHZB' && list.funcCode !== 'ZCJF' && list.funcCode!=='ZBHF' || list.funcCode === 'DBHF'"
                        @click="contentBtn(list)"
                    >内容</div>
                    <div
                        class="font"
                        v-if="list.funcCode === 'DHZB'"
                    >
                      <span v-if="list.liveMeetingId" @click="relevanceBtn(list,list.liveMeetingId)">关联</span>
                      <span v-else style="color: red" @click="relevanceBtn(list)">关联</span>
                    </div>
                    <div
                        class="font"
                        v-if="list.funcCode !== 'LBGG' && list.funcCode !== 'ZMTZ'"
                        @click="setBtn(list)">设置</div>
                    <div
                        class="font"
                        v-if="list.funcCode === 'YZT' || list.funcCode === 'QYHB' "
                        @click="dataBtn">数据</div>
                    <div
                        class="font"
                        v-if="list.funcCode === 'RCYL'"
                        @click="addressBtn(list)">地点</div>
                    <div
                        class="font"
                        v-if="list.funcCode === 'ZCJF' || list.funcCode === 'XFSJ'"
                        @click="nameBtn(list)">名单</div>
                    <div
                        class="font"
                        v-if="list.funcCode === 'DHZB' || list.funcCode === 'ZBHF' || list.funcCode === 'ZCJF'"
                        @click="timeBtn(list)">时长</div>
                    <div
                        class="font"
                        v-if="list.funcCode === 'XFSJ'"
                        @click="configurationBtn(list)">配置</div>
                    <div
                        class="font"
                        v-if="list.funcCode === 'DBHF'"
                        @click="timeDBHFBtn(list)">时长</div>
                    <div
                        class="font"
                        v-if="list.funcCode === 'ZCJF'"
                        @click="numberBtn(list)">人数</div>
                    <div
                        class="font"
                        @click="restBtn(list)">修改</div>
                    <div>
                      <a-popconfirm
                          style="margin-left: 8px"
                          title="是否确认删除?"
                          @confirm="delCard(list)">
                        <a style="color: black">删除</a>
                      </a-popconfirm>
                    </div>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      <!-- 底部   -->
        <div style="height: 60px"></div>
        <div style="width:100%;height:50px;position: fixed;bottom: 10px;background-color: #f0f2f5;border-bottom: 1px solid #fff">
          <div
              style="width: 80%;display: flex;justify-content: right;margin-top: 10px">
            <a-button @click="returnBtn">返回上一层</a-button>
          </div>
        </div>
  <!------------------------------------------对话框-------------------------->
        <a-drawer
            title="学分配置"
            :width="600"
            :visible="configuration_visible"
            :body-style="{ paddingBottom: '80px' }"
            @close="configurationClose">
          <a-form-model
              ref="configuration_ruleForm"
              :model="configuration_form"
              :label-col="labelCol_configuration"
              :rules="configurationRules"
              :wrapper-col="wrapperCol"
          >
            <a-form-model-item label="备注事项" prop="remark">
              <a-textarea
                  v-model="configuration_form.remark"
                  placeholder="请输入"
                  style="width: 300px"
                  :auto-size="{ minRows: 3, maxRows: 5 }"
              />
            </a-form-model-item>
            <a-form-model-item label="授予学分" prop="credit">
              <a-input-number :min="1" style="width: 300px" v-model="configuration_form.credit" placeholder="请输入" />
            </a-form-model-item>
            <a-form-model-item label="限制报名人数" prop="limitEnrollCount" >
              <a-input-number :min="0" style="width: 300px" v-model="configuration_form.limitEnrollCount" placeholder="请输入" />
            </a-form-model-item>
          </a-form-model>
          <div
              :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
          >
            <a-button  @click="configurationClose">取消</a-button>
            <a-button style="margin-left: 10px" type="primary" @click="configurationSubmit">确定</a-button>
          </div>
        </a-drawer>
  <!--    新增-->
      <a-drawer
          title="新增/修改"
          :width="500"
          :visible="content_addCard_visible"
          :body-style="{ paddingBottom: '80px' }"
          @close="closeAddOrRest"
      >
        <a-form-model
            ref="content_add_ruleForm"
            :model="content_addCard_form"
            :rules="content_addCard_rules"
            :label-col="labelCol_addCard"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="类型" prop="funcCode">
            <a-select
                show-search
                placeholder="请选择"
                option-filter-prop="children"
                style="width: 260px"
                v-model="content_addCard_form.funcCode">
              <a-select-option
                  @click="content_addCard_form_handleChange(code.code)"
                  v-for="code in code_selectData"
                  v-model="code.code">
                {{code.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="ruleOneOrTwo" prop="icon" label="图标">
            <div style="display: flex;width: 450px;">
              <div class="uploadImg" >
                <input
                    id="uploadFile_add"
                    style="width: 100%;height:100%;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="content_add_image"
                    ref="detailCoverUrl_addICON" />
              </div>
              <div
                  class="upload_LiveBroadcast">
                <img
                    v-if="content_addCard_form.icon"
                    alt=""
                    style="width: 100%;height:100%"
                    :src="content_addCard_form.icon"
                />
              </div>
            </div>
  <!--          <div style="color: #a1a1a1">注:尺寸640*360，大小不超过500KB</div>-->
          </a-form-model-item>
          <a-form-model-item v-if="ruleOneOrTwo">
            <template slot="label">
                <span>功能名称
                <a-tooltip placement="top">
              <template slot="title">
                <span>当设置的图标带功能名称，则不用设置功能名称</span>
              </template>
              <a-icon type="exclamation-circle" />
            </a-tooltip></span>
            </template>
            <a-input
                style="width: 260px"
                v-model="content_addCard_form.title"
                placeholder="请输入"/>
          </a-form-model-item>
          <a-form-model-item v-if="ruleOneOrTwo" prop="intro">
            <template slot="label">
                <span>功能名称备注
                <a-tooltip placement="top">
              <template slot="title">
                <span>当功能名称为空时，设置备注名称方便识别功能</span>
              </template>
              <a-icon type="exclamation-circle" />
            </a-tooltip></span>
            </template>
            <a-input
                style="width: 260px"
                v-model="content_addCard_form.intro"
                placeholder="请输入"/>
          </a-form-model-item>
        </a-form-model>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button @click="closeAddOrRest">
            取消
          </a-button>
          <a-button style="margin-left: 15px" type="primary" @click="addSubmit">
           确定
          </a-button>

        </div>
      </a-drawer>
  <!---------------人数-报名缴费------------------------>
      <a-modal
          v-model="per_OfThe_visible"
          title="人数-报名缴费">
        <div style="display: flex;height: 35px;line-height: 25px">
          <span style="width: 90px;">报名人数：</span>
          <a-input
              placeholder="请输入"
              v-model="per_OfThe_input"/>
        </div>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button  @click="()=>{this.per_OfThe_visible = false;this.per_OfThe_input = ''}">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="per_OfThe_ok">
            确认
          </a-button>
        </div>
      </a-modal>
  <!---------------关联会议------------------------>
      <a-modal
          @cancel="onClose_LiveBroadcast"
          v-model="conferenceLiveBroadcastVisible"
          title="大会直播">
        <a-form-model
            ref="ruleFormLiveBroadcast"
            :model="LiveBroadcastForm"
            :rules="LiveBroadcastRules"
            :label-col="labelCol_CalendarCalendar"
            :wrapper-col="wrapperCol"
        >
  <!--        <a-alert style="width: 400px;margin-left: 20px;margin-bottom: 10px" message="只能选择【多会场】类型的【非测试】直播会议" type="info" show-icon />-->
          <a-alert
              message="帮助提示"
              type="info"
              show-icon
          >
            <template slot="description">
              <div style="color: #849096">1.只能选择【多会场】类型的【非测试】直播会议</div>
              <div style="color: #849096">2.使用外部直播时一定要勾选【保留大会直播界面】</div>
            </template>
          </a-alert>
          <a-form-model-item label="关联直播会议" prop="conferenceLiveBroadcastValue">
          <!-- @search="select_LiveBroadcast"  show-search  option-filter-prop="children" -->
          <a-select
              @popupScroll="popupScroll_LiveBroadcast"
              v-model="LiveBroadcastForm.conferenceLiveBroadcastValue"
              placeholder="请选择多会场类型直播会议"
              style="width: 300px"
          >
            <a-select-option
                v-for="lives in conferenceLiveBroadcast"
                @click="LiveBroadcastChange()"
                :value="lives.id">
              {{lives.title}}
            </a-select-option>
          </a-select>
            <a-checkbox-group
                v-model="LiveBroadcastForm.isCheck"
                name="checkboxgroup"
                :options="plainOption"
                @change="LiveBroadcastCheckChange"
            />
          </a-form-model-item>

        </a-form-model>
        <div
            :style="{
           position: 'absolute',
           right: 0,
           bottom: 0,
           width: '100%',
           borderTop: '1px solid #e9e9e9',
           padding: '10px 16px',
           background: '#fff',
           textAlign: 'right',
           zIndex: 1,
          }"
          >
            <a-button  @click="onClose_LiveBroadcast">
              取消
            </a-button>
            <a-button style="margin-left: 10px" type="primary" @click="onSubmit_LiveBroadcast">
              确认
            </a-button>
          </div>
        </a-modal>

      <a-modal
            @cancel="timeOpen"
            @ok="timeOpenOk"
            width="400px"
            v-model="timeOpen_visible"
            title="时长">
          <div style="display: flex">
            <span>开启时长：</span>
            <a-switch
                v-model="timeOpenIsOpen"
                checked-children="是"
                un-checked-children="否"
                @change="timeOpenChange(timeOpenIsOpen ? false:true)"
                default-checked />
          </div>
        <div style="display: flex;margin-top: 10px">
          <span style="width: 90px;text-align: right">备注：</span>
          <a-textarea
              v-model="durationRemark"
              placeholder="请输入"
              :auto-size="{ minRows: 2, maxRows: 3 }"
          />
        </div>
        </a-modal>
  <!---------------设置------------------------>
      <a-drawer
          title="设置"
          :width="500"
          :visible="set_visible"
          :body-style="{ paddingBottom: '80px' }"
          @close="()=>{this.set_visible = false;this.set_form.isNeedPay = 0;this.set_form.isShowApplyBar = 0;this.set_form.isNeedLogin = 0;}"
      >
        <div >
          <div style="margin-left: 28px;margin-top: 10px;color: black">
            <span>是否需要缴费：</span>
            <a-switch
                :checked="set_form.isNeedPay === 1 ? true:false"
                @change="set_payFees_Btn(set_form.isNeedPay === 1 ? 0:1)"
                style="margin-left: 5px"
                checked-children="是"
                un-checked-children="否"
                default-checked />
          </div>
          <div style="margin-top: 10px;color: black">
            <span>是否显示悬浮报名：</span>
            <a-switch
                :checked="set_form.isShowApplyBar === 1 ? true:false"
                @change="set_Suspended_Btn(set_form.isShowApplyBar === 1 ? 0:1)"
                style="margin-left: 5px"
                checked-children="是"
                un-checked-children="否"
                default-checked />
          </div>
          <div style="margin-left: 28px;margin-top: 10px;color: black">
            <span>是否需要登录：</span>
            <a-switch
                :checked="set_form.isNeedLogin === 1 ? true:false"
                @change="set_login_Btn(set_form.isNeedLogin === 1 ? 0:1)"
                style="margin-left: 5px"
                checked-children="是"
                un-checked-children="否"
                default-checked />
          </div>
        </div>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button @click="()=>{this.set_visible = false;this.set_form.isNeedPay = 0;this.set_form.isShowApplyBar = 0;this.set_form.isNeedLogin = 0;}">
            关闭
          </a-button>
        </div>
      </a-drawer>
  <!----------------名单----------------------->
      <!--名单-学分注册-->
      <a-drawer
          title="名单-学分注册"
          :width="1100"
          :visible="List_credit_registration_visible"
          :body-style="{ paddingBottom: '80px' }"
          @close="registrationClose"
      >
        <div>
  <!--        注意-->
          <a-alert type="info" show-icon style="width: 500px;margin-bottom: 15px">
            <template slot="message">
              <span style="cursor: pointer">提示：查看最新数据时请选择计算时长</span>
            </template>
          </a-alert>
          <div style="margin-bottom: 15px">
            <span>计算时长：</span>
            <a-button @click="ViewingData">
              计算时长 <a-spin style="margin-left: 5px" v-show="handMovementLoading" :indicator="indicator" />
            </a-button>
          </div>
          <div style="margin-bottom: 15px">
            <span>上报方式：</span>
            <a-radio-group v-model="radioGroup" button-style="solid"
                           @change="radioGroupChange">
              <a-radio-button value="voluntarily">
                自动上报
              </a-radio-button>
              <a-radio-button value="handMovement">
                手动上报
              </a-radio-button>
            </a-radio-group>
          </div>
        </div>
        <div>
          <span>手机号码</span>
          <a-input
              v-model="List_credit_registration_telephone"
              placeholder="请输入"
              style="width: 250px;margin-left: 10px"/>
          <span style="margin-left: 20px;">姓名</span>
          <a-input
              v-model="List_credit_registration_name"
              placeholder="请输入"
              style="width: 250px;margin-left: 10px"/>
          <a-button
              @click="List_credit_registration_selectBtn"
              style="margin-left: 10px">查询</a-button>
          <a-button
              @click="List_credit_registration_restBtn"
              style="margin-left: 10px">重置</a-button>
          <a-button
              @click="List_credit_registration_deriveBtn"
              style="margin-left: 10px">
            <a-icon style="color: #00CC33" type="file-excel" />
            批量导出
          </a-button>
        </div>
        <div style="margin-top: 20px">
          <a-table
              :scroll="{ x: 2000 }"
              :rowKey='record=>record.id'
              :pagination = false
              :columns="List_credit_registration_columns"
              :data-source="List_credit_registration_data">
          </a-table>
          <div style="width: 100%;height: 50px;margin-top: 10px">
            <div style="float: right;margin-right: 20px">
              <a-pagination
                  show-quick-jumper
                  :total="List_credit_registration_count"
                  @change="List_credit_registration_pagination_Change" />
            </div>
          </div>
        </div>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button @click="registrationClose">
            关闭
          </a-button>
        </div>
      </a-drawer>
      <!--名单-注册缴费-->
      <a-drawer
          title="名单-注册缴费"
          :width="1100"
          :visible="Tuition_fees_register_visible"
          :body-style="{ paddingBottom: '80px' }"
          @close="()=>{this.Tuition_fees_register_visible = false}"
      >
        <div>
          <span>手机号码</span>
          <a-input
              v-model="Tuition_fees_register_telephone"
              placeholder="请输入"
              style="width: 250px;margin-left: 10px"/>
          <span style="margin-left: 20px">姓名</span>
          <a-input
              v-model="Tuition_fees_register_name"
              placeholder="请输入"
              style="width: 250px;margin-left: 10px"/>
          <a-button
              @click="Tuition_fees_register_selectBtn"
              style="margin-left: 10px">查询</a-button>
          <a-button
              @click="Tuition_fees_register_restBtn"
              style="margin-left: 10px">重置</a-button>
          <a-button
              @click="Tuition_fees_register_deriveBtn"
              style="margin-left: 10px">
            <a-icon style="color: #00CC33" type="file-excel" />
            批量导出
          </a-button>
        </div>
        <div style="margin-top: 20px">
          <a-table
              :rowKey='record=>record.id'
              :pagination = false
              :columns="Tuition_fees_register_columns"
              :data-source="Tuition_fees_register_data">
            <span slot="system" slot-scope="text, record">
              <a-popconfirm
                  title="Are you sure delete this task?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="Tuition_fees_register_confirm"
              >
                <a href="#">删除</a>
              </a-popconfirm>
            </span>
          </a-table>
          <div style="width: 100%;height: 50px;">
            <div style="float: right;margin-right: 20px;margin-top: 10px">
              <a-pagination
                  show-quick-jumper
                  :total="Tuition_fees_register_count"
                  @change="Tuition_fees_register_pagination_Change" />
            </div>
          </div>
        </div>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button @click="()=>{this.Tuition_fees_register_visible = false}">
            关闭
          </a-button>
        </div>
      </a-drawer>
  <!---------------内容------------------->
      <!--内容-轮播广告-->
      <a-drawer
          title="内容-轮播广告"
          :width="680"
          :visible="content_ByAdvertising_visible"
          :body-style="{ paddingBottom: '80px' }"
          @close="()=>{this.content_ByAdvertising_visible = false
          this.content_ByAdvertising_form.displayUrl = ''
          this.content_ByAdvertising_form.targetUrl = ''
          }"
      >
        <a-form-model
            ref="content_ByAdvertising_ruleForm_lunbo"
            :model="content_ByAdvertising_form"
            :rules="content_ByAdvertising_rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item prop="displayUrl" label="图片">
            <div style="display: flex;width: 450px;">
              <div class="uploadImg" >
                <input
                    id="upload_lunbo"
                    style="width: 100%;height:100%;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="content_ByAdvertising_image"
                    ref="detailCoverUrl" />
              </div>
              <div
                  class="upload_deyails">
                <img
                    v-if="content_ByAdvertising_form.displayUrl"
                    alt=""
                    style="width: 100%;height:100%"
                    :src="content_ByAdvertising_form.displayUrl"
                />
              </div>
            </div>
            <div style="color: #a1a1a1">注:尺寸640*360，大小不超过2M</div>
          </a-form-model-item>
          <a-form-model-item  label="链接" prop="link">
            <a-input
                v-model="content_ByAdvertising_form.targetUrl"
                placeholder="请输入"/>
          </a-form-model-item>
          <a-form-model-item>
            <div style="text-align: center;width: 600px">
              <a-button
                  @click="content_ByAdvertising_submit"
                  type="primary">提交</a-button>
            </div>
          </a-form-model-item>
          <a-form-model-item>
            <div style="text-align: center;width: 600px">
              <a-table
                  :rowKey='record=>record.id'
                  style="width: 98%;margin: auto"
                  :columns="content_ByAdvertising_columns"
                  :data-source="content_ByAdvertising_data">
                <span slot="system" slot-scope="text, record">

                  <a-popconfirm
                      title="是否确认删除?"
                      ok-text="Yes"
                      cancel-text="No"
                      @confirm="content_ByAdvertising_data_delBtn(record.id)"
                  >
                    <a>删除</a>
                  </a-popconfirm>
                  <a
                      @click="content_ByAdvertising_up(record.id)"
                      style="margin-left: 8px">上移</a>
                  <a
                      @click="content_ByAdvertising_down(record.id)"
                      style="margin-left: 8px">下移</a>
                </span>
                <span slot="displayUrl" slot-scope="text, record">
                  <img
                      style="width: 80px;height: auto"
                      :src="record.displayUrl" alt="">
                </span>
              </a-table>
            </div>
          </a-form-model-item>
        </a-form-model>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button type="primary" @click="()=>{this.content_ByAdvertising_visible = false
          this.content_ByAdvertising_form.displayUrl = ''
          this.content_ByAdvertising_form.targetUrl = ''
          }">
            关闭
          </a-button>
        </div>
      </a-drawer>
      <!--内容-字幕文字通知-->
      <a-drawer
          title="内容-字幕文字通知"
          :width="680"
          :visible="content_alphabetic_visible"
          :body-style="{ paddingBottom: '80px' }"
          @close="()=>{this.content_alphabetic_visible = false}"
      >
        <a-form-model
            ref="ruleForm"
            :model="content_ByAdvertising_form"
            :rules="content_ByAdvertising_rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item  label="字幕内容">
            <a-input
                v-model="content_alphabetic_font"
                placeholder="请输入"/>
          </a-form-model-item>
          <a-form-model-item>
            <div style="text-align: center;width: 600px">
              <a-button
                  @click="content_alphabetic_submit"
                  type="primary">提交</a-button>
            </div>
          </a-form-model-item>
          <a-form-model-item>
            <div style="text-align: center;width: 600px">
              <a-table
                  :rowKey='record=>record.id'
                  style="width: 98%;margin: auto"
                  :columns="content_alphabetic_columns"
                  :data-source="content_alphabetic_data">
                <span slot="system" slot-scope="text, record">

                  <a-popconfirm
                      title="是否确认删除?"
                      ok-text="Yes"
                      cancel-text="No"
                      @confirm="content_alphabetic_delBtn(record.id)"
                      @cancel="()=>{}"
                  >
                    <a>删除</a>
                  </a-popconfirm>
                </span>
              </a-table>
            </div>
          </a-form-model-item>
        </a-form-model>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button type="primary" @click="()=>{this.content_alphabetic_visible = false}">
            关闭
          </a-button>
        </div>
      </a-drawer>
      <!--内容-图片展示  HYC-->
      <a-drawer
          title="内容-图片展示"
          :width="680"
          :visible="picture_visible"
          :body-style="{ paddingBottom: '80px' }"
          @close="()=>{this.picture_visible = false;this.picture_form.picture = []}"
      >
        <a-form-model
            ref="ruleForm"
            :model="picture_form"
            :rules="picture_rules"
            :label-col="labelColImg"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item prop="picture" label="图片">
            <div class="uploadImg" >
              <input
                  id="upload_HYC"
                  style="width: 100%;height:100%;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="picture_image"
                  ref="detailCoverUrl_hyc" />
            </div>
          </a-form-model-item>
          <div style="color: #a1a1a1;margin-left: 2%">注:尺寸 宽640，大小不超过1M</div>
          <a-row :gutter="1" style="margin-top: 20px" >
            <a-col
                style="position: relative"
                class="gutter-row"
                :span="6"
                v-for="(img,index) in picture_form.picture">
              <div class="gutter-box">
                <div class="upload_gutter">
                  <a-icon
                      @click="delPicListBtn(index)"
                      style="color: red;font-size: 25px;position:absolute;top: 15px;right: 20px"
                      type="close-circle" />
                  <img
                      v-if="picture_form.picture[0] !==''"
                      alt=""
                      style="width: 100%;height:100%"
                      :src="picture_form.picture[index]"/>
                </div>
              </div>
            </a-col>
          </a-row>
        </a-form-model>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button  @click="()=>{this.picture_visible = false;this.picture_form.picture = []}">
            取消
          </a-button>
          <a-button style="margin-left: 20px" type="primary" @click="pictureTrueBtn">
            确定
          </a-button>
        </div>
      </a-drawer>
      <!-- 内容-PDF展示   -->
      <a-modal
          @cancel="()=>{
            this.content_pdf_visible = false;this.content_pdf_name = '';this.content_pdf_val = ''
          }"
          width="600px"
          v-model="content_pdf_visible"
          title="内容-PDF展示">
        <div style="width: 450px;">
          <div class="uploadpdf" >
            <input
                style="width: 100%;height:100%;opacity: 0;"
                accept=".pdf"
                type="file"
                @change="content_pdf"
                ref="content_pdfUrl" />
          </div>
          {{content_pdf_name}}:
          <div v-if="percentAge !== 0">
            <a-progress
                :percent="percentAge"
                status="active" />
          </div>
        </div>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button  @click="()=>{this.content_pdf_visible = false;this.content_pdf_name = '';this.content_pdf_val = ''}">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="content_pdf_Ok">
            确认
          </a-button>
        </div>
      </a-modal>
      <!--内容-图文链接展示-->
      <a-drawer
          title="内容-图文链接展示"
          :width="680"
          :visible="content_GraphicLinks_visible"
          :body-style="{ paddingBottom: '80px' }"
          @close="()=>{this.content_GraphicLinks_visible = false}"
      >
        <a-form-model
            ref="content_GraphicLinks_ruleForm"
            :model="content_GraphicLinks_form"
            :rules="content_GraphicLinks_rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item prop="targetUrl" label="图片">
            <div style="display: flex;width: 450px;">
              <div class="uploadImg" >
                <input
                    id="picture_image_linkImg"
                    style="width: 100%;height:100%;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="content_GraphicLinks_image"
                    ref="content_GraphicLinksUrl" />
              </div>
              <div
                  class="upload_deyails">
                <img
                    v-if="content_GraphicLinks_form.displayUrl"
                    alt=""
                    style="width: 100%;height:100%"
                    :src="content_GraphicLinks_form.displayUrl"
                />
              </div>
            </div>
            <div style="color: #a1a1a1">注:尺寸 640*173，大小不超过500kb</div>
          </a-form-model-item>
          <a-form-model-item  label="标题" prop="title">
            <a-input
                v-model="content_GraphicLinks_form.title"
                placeholder="请输入"/>
          </a-form-model-item>
          <a-form-model-item  label="链接" prop="targetUrl">
            <a-input
                v-model="content_GraphicLinks_form.targetUrl"
                placeholder="请输入"/>
          </a-form-model-item>
          <a-form-model-item>
            <div style="text-align: center;width: 600px">
              <a-button
                  @click="content_GraphicLinks_submit"
                  type="primary">提交</a-button>
            </div>
          </a-form-model-item>
          <a-form-model-item>
            <div style="text-align: center;width: 600px">
              <a-table
                  :rowKey='record=>record.id'
                  style="width: 98%;margin: auto"
                  :columns="content_GraphicLinks_columns"
                  :data-source="content_GraphicLinks_data">
                <span slot="system" slot-scope="text, record">

                  <a-popconfirm
                      title="是否确认删除?"
                      ok-text="是"
                      cancel-text="否"
                      @confirm="content_GraphicLinks_data_delBtn(record.id)"
                      @cancel="()=>{}"
                  >
                    <a>删除</a>
                  </a-popconfirm>
                  <a
                      @click="content_GraphicLinks_up(record.id)"
                      style="margin-left: 8px">上移</a>
                  <a
                      @click="content_GraphicLinks_down(record.id)"
                      style="margin-left: 8px">下移</a>
                </span>
                <span slot="displayUrl" slot-scope="text, record">
                  <img
                      style="width: 80px;height: auto"
                      :src="record.displayUrl" alt="">
                </span>
                <span slot="titleData" slot-scope="text, record">
                  <a @click="openLinkUrl(record)">{{record.title}}</a>
                </span>
              </a-table>
            </div>
          </a-form-model-item>
        </a-form-model>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button type="primary" @click="()=>{this.content_GraphicLinks_visible = false}">
            关闭
          </a-button>
        </div>
      </a-drawer>
      <!-- 内容-链接展示   -->
      <a-modal
          width="600px"
          v-model="content_LinkShow_visible"
          title="内容-链接展示"
          @ok="content_LinkShow_Ok">
        <div style="display: flex">
          <span style="width: 50px">链接：</span>
          <a-textarea
              v-model="content_LinkShow_dataVal"
              placeholder="请输入"
              :rows="4" />
        </div>
        <span v-if="content_LinkShow_dataValIsTrue" style="color: red;margin-left: 50px">请输入</span>
      </a-modal>
      <!--内容-直播日历展示---------------->
      <a-drawer
          title="内容-直播日历展示"
          :width="1200"
          :visible="content_LiveBroadcast_visible"
          :body-style="{ paddingBottom: '80px' }"
          @close="()=>{this.content_LiveBroadcast_visible = false}"
      >
        <a-form-model
            ref="content_ByAdvertising_ruleForm_AgendaCalendarDisplay"
            :model="content_LiveBroadcast_form"
            :rules="content_LiveBroadcast_rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item >
            <div style="width: 1000px;display: flex">
              <div style="width: 50%">
                <div style="display: flex">
                  <div style="width:auto">封面图:</div>
                  <div style="margin-left: 15px">
                    <div style="display: flex;width: 400px;">
                      <div class="uploadImg" >
                        <input
                            id="upload_image_liveDate"
                            style="width: 100%;height:100%;opacity: 0;"
                            accept="image/*"
                            type="file"
                            @change="content_LiveBroadcast_image"
                            ref="content_LiveBroadcast_imageUrl" />
                      </div>
                      <div
                          class="upload_deyails">
                        <img
                            v-if="content_LiveBroadcast_form.url"
                            alt=""
                            style="width: 100%;height:100%"
                            :src="content_LiveBroadcast_form.url"
                        />
                      </div>
                    </div>
                    <div style="color: #a1a1a1">注:尺寸640*360，大小不超过500KB</div>
                  </div>
                </div>
                <div style="display: flex">
                  <span style="width: 50px">标题:</span>
                  <a-input
                      style="width: 71%"
                    v-model="content_LiveBroadcast_form.title"
                    placeholder="请输入"/>
                </div>
                <div style="display: flex">
                  <span style="width: auto">直播开始时间:</span>
                  <a-time-picker
                      :default-open-value="moment('00:00:00', 'HH:mm:ss')"
                      v-model="LiveBroadcast_start"
                      :allowClear="false"
                      style="margin-left: 15px"
                      @change="content_LiveBroadcast_startChange" />
                </div>
              </div>
              <div style="width: 50%">
                <div style="display: flex">
                  <div style="">详情海报:</div>
                  <div style="margin-left: 15px">
                    <div style="display: flex;width: 400px;">
                      <div class="uploadImg" >
                        <input
                            id="upload_img_liveDte_url"
                            style="width: 100%;height:100%;opacity: 0;"
                            accept="image/*"
                            type="file"
                            @change="content_LiveBroadcast_urlHaiImage"
                            ref="content_LiveBroadcastUrl" />
                      </div>
                      <div
                          class="upload_LiveBroadcast">
                        <img
                            v-if="content_LiveBroadcast_form.urlHai"
                            alt=""
                            style="width: 100%;height:auto"
                            :src="content_LiveBroadcast_form.urlHai"
                        />
                      </div>
                    </div>
                    <div style="color: #a1a1a1">注:尺寸 宽640，大小不超过1M</div>
                  </div>
                </div>
                <div style="display: flex">
                  <span style="width: auto">直播时间:</span>
                  <a-date-picker
                      v-model="content_LiveBroadcast_form.dateVal"
                      style="margin-left: 15px"
                      :allowClear="false"
                      @change="content_LiveBroadcast_liveTimeChange" />
                </div>
                <div style="display: flex">
                  <span style="width: auto">直播结束时间:</span>
                  <a-time-picker
                      :default-open-value="moment('00:00:00', 'HH:mm:ss')"
                      v-model="LiveBroadcast_end"
                      style="margin-left: 15px"
                      :allowClear="false"
                      @change="content_LiveBroadcast_endChange" />
                </div>
              </div>
            </div>
            <div style="text-align: center;width: 1000px">
              <a-button
                  style="width: 120px;margin-top: 15px"
                  @click="content_LiveBroadcast_submit"
                  type="primary">提交</a-button>
            </div>
          </a-form-model-item>

          <a-form-model-item>
            <div style="text-align: center;width: 1150px">
              <a-table
                  :pagination = false
                  :rowKey='record=>record.id'
                  style="width: 98%;margin: auto"
                  :columns="content_LiveBroadcast_columns"
                  :data-source="content_LiveBroadcast_data">
                <span slot="system" slot-scope="text, record">
                  <a @click="content_LiveBroadcast_rest_Btn(record)">修改</a>
                  <a-popconfirm
                      style="margin-left: 8px"
                      title="是否确认删除?"
                      ok-text="Yes"
                      cancel-text="No"
                      @confirm="content_LiveBroadcast_data_delBtn(record.id)"
                  >
                    <a>删除</a>
                  </a-popconfirm>
                </span>
                <!--时间-->
                <span slot="startTime" slot-scope="text, record">
                  {{(record.startTime).slice(11,19)}}~{{(record.endTime).slice(11,19)}}
                </span>
                <!--查看地址-->
                <span slot="address" slot-scope="text, record">
                  <a @click="address_list_Btn(record)">查看地址</a>
                </span>
                <!--时长-->
                <span slot="timeTime" slot-scope="text, record">
                  <a @click="RCYL_duration_switch(record)"
                      v-if="record.durationIsOpen===1">已开启</a>
                  <a  @click="RCYL_duration_switch(record)"
                      v-else>已关闭</a>
                </span>
                <!--回放-->
                <span slot="replayIsOpen" slot-scope="text, record">
                   <a @click="replay_is_open_switch(record)"
                       v-if="record.replayIsOpen===1">已开启</a>
                  <a @click="replay_is_open_switch(record)" v-else>已关闭</a>
                </span>
                <!--云展厅 -->
                <span slot="CloudHall" slot-scope="text, record">
                  <a-popconfirm
                      style="margin-left: 8px"
                      title="是否确认修改?"
                      ok-text="Yes"
                      cancel-text="No"
                      @confirm="content_CloudHall_data_delBtn(record.id,record.isGallery===1?0:1)"
                  >
                    <a v-if="record.isGallery===1">已开启</a>
                    <a v-else>已关闭</a>
                  </a-popconfirm>
                </span>
                <!--企业专区 -->
                <span slot="EnterpriseZone" slot-scope="text, record">
                   <a @click="Enterprise_zone_Switch(record)"
                       v-if="record.isEnterprise===1">已开启</a>
                  <a @click="Enterprise_zone_Switch(record)"
                      v-else>已关闭</a>
                </span>
                <!--开场图 -->
                <span slot="openingFigure" slot-scope="text, record">
                   <a @click="opening_figure_Switch(record)"
                       v-if="record.isBeginImage===1">已开启</a>
                  <a @click="opening_figure_Switch(record)"
                      v-else>已关闭</a>
                </span>
              </a-table>
              <div style="width: 100%;height: 50px;">
                <div style="float: right;margin-right: 20px;margin-top: 10px">
                  <a-pagination
                      show-quick-jumper
                      :total="content_LiveBroadcast_data_total"
                      @change="Tuition_LiveBroadcast_data_pagination_Change" />
                </div>
              </div>
            </div>
          </a-form-model-item>
        </a-form-model>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button type="primary" @click="()=>{this.content_LiveBroadcast_visible = false}">
            关闭
          </a-button>
        </div>
      </a-drawer>
      <!--  地址  -->
      <a-drawer
          title="地址"
          :width="450"
          :visible="visibleAdress"
          :body-style="{ paddingBottom: '80px' }"
          @close="resetFormAddress"
      >
        <div style="width: 450px">
          <div>
            <div style="margin-left: 15px">
              推流: 服务器
            </div>
            <div style="display: flex">
              <div style="margin-top: 15px;margin-left: 15px">
                <a-textarea
                    style="width: 300px"
                    :disabled="true"
                    v-model="addressForm.address1"
                    :auto-size="{ minRows: 2, maxRows: 6 }"
                />
              </div>
              <div style="color: #1374ce;margin-left: 10px;margin-top: 30px">
                <a-icon @click="copySum(addressForm.address1)" type="copy" />
              </div>
            </div>

          </div>
          <div style="margin-top: 15px">
            <div style="margin-left: 15px">
              推流: 串流密钥
            </div>
            <div style="display: flex">
              <div style="margin-top: 15px;margin-left: 15px">
                <a-textarea
                    style="width: 300px"
                    :disabled="true"
                    v-model="addressForm.address2"
                    :auto-size="{ minRows: 2, maxRows: 6 }"
                />
              </div>
              <div style="color: #1374ce;margin-left: 10px;margin-top: 40px">
                <a-icon @click="copySum(addressForm.address2)" type="copy" />
              </div>
            </div>
          </div>
          <div style="margin-top: 15px">
            <div style="margin-left: 15px">
              拉流：rtmp
            </div>
            <div style="display: flex">
              <div style="margin-top: 15px;margin-left: 15px">
                <a-textarea
                    style="width: 300px"
                    :disabled="true"
                    v-model="addressForm.address3"
                    :auto-size="{ minRows: 2, maxRows: 6 }"
                />
              </div>
              <div style="color: #1374ce;margin-left: 10px;margin-top: 40px">
                <a-icon @click="copySum(addressForm.address3)" type="copy" />
              </div>
            </div>
          </div>
          <div style="margin-top: 15px">
            <div style="margin-left: 15px">
              拉流：flv
            </div>
            <div style="display: flex">
              <div style="margin-top: 15px;margin-left: 15px">
                <a-textarea
                    style="width: 300px"
                    :disabled="true"
                    v-model="addressForm.address4"
                    :auto-size="{ minRows: 2, maxRows: 6 }"
                />
              </div>
              <div style="color: #1374ce;margin-left: 10px;margin-top: 40px">
                <a-icon @click="copySum(addressForm.address4)" type="copy" />
              </div>
            </div>
          </div>
          <div style="margin-top: 15px">
            <div style="margin-left: 15px">
              拉流：m3u8
            </div>
            <div style="display: flex">
              <div style="margin-top: 15px;margin-left: 15px">
                <a-textarea
                    style="width: 300px"
                    :disabled="true"
                    v-model="addressForm.address5"
                    :auto-size="{ minRows: 2, maxRows: 6 }"
                />
              </div>
              <div style="color: #1374ce;margin-left: 10px;margin-top: 50px">
                <a-icon @click="copySum(addressForm.address5)" type="copy" />
              </div>
            </div>
          </div>
        </div>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="resetFormAddress">
            关闭
          </a-button>
        </div>
      </a-drawer>
      <!--时长-->
      <a-modal
          @cancel="()=>{ this.content_RCYL_duration_visible = false;
            this.durationForm.RCYL_duration_switch_isOpen_list = 0
            this.durationForm.duration_remark = ''
          }"
          width="400px"
          v-model="content_RCYL_duration_visible"
          title="时长修改">
        <div>
          <span>状态：</span>
          <a-switch
              v-model="durationForm.RCYL_duration_switch_isOpen_list === 1 ? true:false"
              checked-children="开"
              un-checked-children="关"
              @click="RCYL_duration_change_isOpen(durationForm.RCYL_duration_switch_isOpen_list === 1 ?0:1)"
              default-checked />
        </div>
        <div style="margin-top: 15px">
          <span>备注：</span>
          <a-input
              style="width: 300px"
              placeholder="请输入"
              v-model="durationForm.duration_remark"
          />
        </div>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button  @click="()=>{ this.content_RCYL_duration_visible = false
          this.durationForm.RCYL_duration_switch_isOpen_list = 0
            this.durationForm.duration_remark = ''
          }">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="content_RCYL_duration_Ok">
            确认
          </a-button>
        </div>
      </a-modal>
      <!--企业专区-->
      <a-modal
          @cancel="()=>{ this.content_Enterprise_zone_visible = false
        this.Enterprise_zoneForm.isOpen_list = 0
        this.Enterprise_zoneForm.image = ''}"
          width="600px"
          v-model="content_Enterprise_zone_visible"
          title="企业鸣谢">
        <div>
          <span>状态：</span>
          <a-switch
              v-model="Enterprise_zoneForm.isOpen_list === 1 ? true:false"
              checked-children="开"
              un-checked-children="关"
              @click="RCYL_Enterprise_zone_change_isOpen(Enterprise_zoneForm.isOpen_list === 1 ?0:1)"
              default-checked />
        </div>
        <div style="margin-top: 15px;display: flex">
          <span style="width: 50px">图片：</span>
          <div>
            <div style="display: flex;width: 450px;">
              <div class="uploadImg" >
                <input
                    id="up_img_Enterprise_zoneForm_img"
                    style="width: 100%;height:100%;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="content_RCYL_Enterprise_zone_image"
                    ref="content_RCYL_Enterprise_zone" />
              </div>
              <div
                  class="upload_LiveBroadcast">
                <img
                    v-if="Enterprise_zoneForm.image"
                    alt=""
                    style="width: 100%;height:100%"
                    :src="Enterprise_zoneForm.image"
                />
              </div>
            </div>
            <div style="color: #a1a1a1;margin-top: 5px">注:尺寸 宽640，大小不超过1M</div>
          </div>
        </div>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button  @click="()=>{ this.content_Enterprise_zone_visible = false
        this.Enterprise_zoneForm.isOpen_list = 0
        this.Enterprise_zoneForm.image = ''}">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="content_Enterprise_zone_Ok">
            确认
          </a-button>
        </div>
      </a-modal>
      <!--开场图-->
      <a-modal
          @cancel="()=>{ this.content_opening_figure_visible = false
          this.opening_figureForm.duration = ''
        this.opening_figureForm.isOpen_list = 0
        this.opening_figureForm.image = ''}"
          width="600px"
          v-model="content_opening_figure_visible"
          title="开场图">
        <a-form-model
            ref="content_opening_figure_ruleForm"
            :model="opening_figureForm"
            :rules="content_opening_figure_rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
        <a-form-model-item prop="isOpen_list" label="状态">
          <a-switch
              v-model="opening_figureForm.isOpen_list === 1 ? true:false"
              checked-children="开"
              un-checked-children="关"
              @click="RCYL_opening_figure_change_isOpen(opening_figureForm.isOpen_list === 1 ?0:1)"
              default-checked />
        </a-form-model-item>
        <a-form-model-item prop="image" label="图片">
          <div>
            <div style="display: flex;width: 450px;">
              <div class="uploadImg" >
                <input
                    id="up_img_figure"
                    style="width: 100%;height:100%;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="content_RCYL_opening_figure_image"
                    ref="content_RCYL_opening_figure" />
              </div>
              <div
                  class="upload_LiveBroadcast">
                <img
                    v-if="opening_figureForm.image"
                    alt=""
                    style="width: 100%;height:100%"
                    :src="opening_figureForm.image"
                />
              </div>
            </div>
            <div style="color: #a1a1a1;margin-top: 5px">注:尺寸 宽640，大小不超过1M</div>
          </div>
        </a-form-model-item>


        <a-form-model-item prop="duration" label="时长">
          <a-input v-model="opening_figureForm.duration"/>
        </a-form-model-item>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button  @click="()=>{ this.content_opening_figure_visible = false
        this.opening_figureForm.isOpen_list = 0
        this.opening_figureForm.duration = ''
        this.opening_figureForm.image = ''}">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="content_opening_figure_Ok">
            确认
          </a-button>
        </div>
        </a-form-model>
      </a-modal>
      <!--  回放  -->
      <a-drawer
          title="回放设置"
          :width="680"
          :visible="visiblePlayback"
          :body-style="{ paddingBottom: '80px' }"
          @close="resetFormPlayback"
      >
        <div style="width: 600px">
          <div style="display: flex">
            <div style="color: black;font-size: 16px">状态:</div>
            <div style="margin-left: 15px">
              <a-switch
                  :checked="PlaybackForm.replay_is_open !==1 ? false:true"
                  @click="switchPlaybackChange(PlaybackForm.replay_is_open === 1? 0 : 1)"
                  checked-children="开"
                  un-checked-children="关"  />
            </div>
          </div>
          <div style="margin-top: 10px">
            <a-button type="primary" @click="addVideoBtn">新增</a-button>
          </div>
          <div>
            <a-table
                :pagination = false
                style="margin-top: 15px"
                :columns="columnsPlayback"
                :data-source="PlaybackTableData"
                :rowKey='record=>record.id'>
              <template
                  v-for="col in ['name']"
                  :slot="col"
                  slot-scope="text, record, index"
              >
                <div :key="col">

                   <div v-if="record.addIsShow">
                     <a-select
                         @popupScroll="popupScroll_addVideoBtn"
                         v-if="record.editable"
                         show-search
                         placeholder="输入视频标题关键字，从视频库选择"
                         option-filter-prop="children"
                         style="width: 200px"
                         @search="select_add_search"
                     ><a-select-option
                           v-for="role in videoList"
                           @click="select_add(role.title,role.id)"
                           :value="role.id">
                         {{role.title}}
                       </a-select-option>
                     </a-select>

                     <template  v-if="!record.editable">
                       <a :disabled="editingKey !== ''" @click="() => edit(record.id)">
                         {{ record.name === null || record.name === '' || record.name === undefined ?'未设置':record.name}}
                       </a>
                     </template>
                   </div>

                 <div v-else>
                   <a-input
                       v-if="record.editable"
                       style="margin: -5px 0"
                       :value="text"
                       @change="e => handleChange(e.target.value, record.id, col)"
                   />
                   <template  v-if="!record.editable">
                     <a :disabled="editingKey !== ''" @click="() => edit(record.id)">
                       {{ record.name === null || record.name === '' || record.name === undefined ?'未设置':record.name}}
                     </a>
                   </template>
                 </div>

                </div>
              </template>
              <template slot="operation" slot-scope="text, record, index">
              <div class="editable-row-operations">
              <!--保存取消-->
              <span v-if="record.editable">
                <a @click="() => save(record.id,record)">保存</a>
                <a-popconfirm
                    ok-text="是"
                    cancel-text="否"
                    style="margin-left: 5px"
                    title="是否取消?"
                    @confirm="() => cancel(record.id,record)">
                  <a>取消</a>
                </a-popconfirm>
              </span>
                <!--删除-->
              <span v-if="!record.editable">
                <a-popconfirm
                    title="是否确认删除?"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="confirmDel(record.id)"
                ><a>删除</a>
                </a-popconfirm>
              </span>
                </div>
              </template>
            </a-table>
            <div style="width: 100%;height: 50px;">
              <div style="float: right;margin-right: 20px;margin-top: 10px">
                <a-pagination
                    show-quick-jumper
                    :total="content_PlaybackTableData_data_total"
                    @change="Tuition_PlaybackTableData_pagination_Change" />
              </div>
            </div>
          </div>
        </div>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button type="primary" :style="{ marginRight: '8px' }" @click="resetFormPlayback">
            关闭
          </a-button>
        </div>
      </a-drawer>
      <!--  修改  -->
      <a-drawer
          title="修改"
          :width="680"
          :visible="rest_visiblePlayback"
          :body-style="{ paddingBottom: '80px' }"
          @close="rest_resetFormPlayback_close"
      >
        <a-form-model
            ref="content_rest_visiblePlayback_ruleForm"
            :model="content_rest_visiblePlayback_form"
            :rules="content_rest_visiblePlayback_rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item prop="cover_url" label="封面图">
            <div style="display: flex;width: 450px;">
              <div class="uploadImg" >
                <input
                    id="up_img_rest"
                    style="width: 100%;height:100%;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="content_rest_visiblePlayback_image"
                    ref="content_rest_visiblePlayback_one" />
              </div>
              <div
                  class="upload_deyails">
                <img
                    v-if="content_rest_visiblePlayback_form.cover_url"
                    alt=""
                    style="width: 100%;height:100%"
                    :src="content_rest_visiblePlayback_form.cover_url"
                />
              </div>
            </div>
            <div style="color: #a1a1a1">注:尺寸 640*360，大小不超过500kb</div>
          </a-form-model-item>
          <a-form-model-item prop="desc_image_url" label="详情海报">
            <div style="display: flex;width: 450px;">
              <div class="uploadImg" >
                <input
                    id="up_img_rest_visiblePlayback_two"
                    style="width: 100%;height:100%;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="content_rest_visiblePlayback_two_image"
                    ref="content_rest_visiblePlayback_two" />
              </div>
              <div
                  class="upload_LiveBroadcast">
                <img
                    v-if="content_rest_visiblePlayback_form.desc_image_url"
                    alt=""
                    style="width: 100%;height:100%"
                    :src="content_rest_visiblePlayback_form.desc_image_url"
                />
              </div>
            </div>
            <div style="color: #a1a1a1">注:尺寸 宽640，大小不超过1M</div>
          </a-form-model-item>
          <a-form-model-item  label="标题" prop="title">
            <a-input
                v-model="content_rest_visiblePlayback_form.title"
                placeholder="请输入"/>
          </a-form-model-item>
        </a-form-model>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="rest_resetFormPlayback_close">
            取消
          </a-button>
          <a-button type="primary" :style="{ marginRight: '8px' }" @click="onSubmit_resetFormPlayback">
            确定
          </a-button>
        </div>
      </a-drawer>
  <!----------------------------------------------------------------------------------->
      <!--地点-日程日历-新增/修改-->
      <a-modal
          @cancel="()=>{ this.content_address_visible = false}"
          width="400px"
          v-model="content_address_visible"
          title="地点-日程日历-新增/修改">
        <div>
          <span>地点：</span>
          <a-input
              style="width: 300px"
              placeholder="请输入"
              v-model="content_address_address"
          />
        </div>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button  @click="()=>{ this.content_address_visible = false}">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="content_address_Ok">
            确认
          </a-button>
        </div>
      </a-modal>
  <!--    ==========================================================================================================-->
      <!--内容-日程日历展示   RCYL-->
      <a-drawer
          title="内容-日程日历展示"
          :width="1400"
          :visible="content_CalendarCalendar_visible"
          :body-style="{ paddingBottom: '80px' }"
          @close="closeCalendarCalendar"
      >
        <a-form-model
            ref="content_ByAdvertising_ruleForm_RCYL"
            :model="content_CalendarCalendar_form"
            :rules="content_CalendarCalendar_rules"
            :label-col="labelCol_CalendarCalendars"
            :wrapper-col="wrapperCol"
        >
          <div style="width: 1400px;">
            <div style="display: flex;">
              <a-form-model-item label="会议日期" prop="liveTime">
                <a-date-picker
                    :disabled-date="disabledDate"
                    placeholder="请选择"
                    :allowClear="false"
                    style="width: 250px"
                    v-model="content_CalendarCalendar_form.liveTime"
                    @change="date_picker_Change_CalendarCalendar" />
              </a-form-model-item>
              <a-form-model-item style="margin-left: 150px"  label="开始时间" prop="start_time">
  <!--              <a-time-picker-->
  <!--                  style="width: 250px"-->
  <!--                  v-model="content_CalendarCalendar_form.start_time"-->
  <!--                  format="HH:mm:ss"-->
  <!--                  valueFormat="HH:mm:ss"-->
  <!--                  placeholder="开始时间"-->
  <!--                  @change="changeCalendarCalendar"-->
  <!--                  :default-open-value="moment('00:00:00', 'HH:mm:ss')"-->
  <!--                  :disabledHours="disabledStartHours"-->
  <!--                  :disabledMinutes="disabledStartMinutes"-->
  <!--                  :disabledSeconds="disabledStartSeconds"-->
  <!--                  hideDisabledOptions/>-->
                <a-time-picker
                    style="width: 250px"
                    :value="moment(content_CalendarCalendar_form.start_time, 'HH:mm:ss')"
                    placeholder="开始时间"
                    @change="date_picker_Change_open_Ok"
                    :default-open-value="moment('00:00:00', 'HH:mm:ss')"/>
                <!--              :disabledSeconds="disabledStartSeconds"-->
              </a-form-model-item>
              <a-form-model-item style="margin-left: 150px"  label="结束时间" prop="end_time">
  <!--              <a-time-picker-->
  <!--                  style="width: 250px"-->
  <!--                  v-model="content_CalendarCalendar_form.end_time"-->
  <!--                  format="HH:mm:ss"-->
  <!--                  valueFormat="HH:mm:ss"-->
  <!--                  placeholder="结束时间"-->
  <!--                  :default-open-value="moment('00:00:00', 'HH:mm:ss')"-->
  <!--                  :disabledHours="disabledEndHours"-->
  <!--                  :disabledMinutes="disabledEndMinutes"-->
  <!--                  :disabledSeconds="disabledEndSeconds"-->
  <!--                  hideDisabledOptions/>-->
                <a-time-picker
                    style="width: 250px"
                    :value="moment(content_CalendarCalendar_form.end_time, 'HH:mm:ss')"
                    placeholder="结束时间"
                    @change="date_picker_Change_open_end_time"
                    :default-open-value="moment('00:00:00', 'HH:mm:ss')"/>
                <!--              :disabledSeconds="disabledEndSeconds"-->
              </a-form-model-item>
            </div>
            <div style="display: flex;">
              <a-form-model-item style="margin-left: 10px"  label="地点" prop="address">
                <a-select
                    show-search
                    v-model="content_CalendarCalendar_form.address"
                    placeholder="请选择"
                    option-filter-prop="children"
                    style="width: 250px"
                >
                  <a-select-option
                      v-for="address in CalendarCalendar_AddressList"
                      :key="address.id"
                      v-model="address.id">
                    {{address.address}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item style="margin-left: 5px" label="日程名称" prop="scheduleName">
                  <a-input
                      style="width:735px"
                      v-model="content_CalendarCalendar_form.scheduleName"
                      placeholder="请输入"/>
                </a-form-model-item>
            </div>
  <!--          margin-left: -275px  -->
            <div style="display: flex;">
                   <a-form-model-item label="是否直播" prop="isLive">
                    <div style="width: 250px;display: flex;margin-top: 5px">
                      <a-switch
                          v-model="content_CalendarCalendar_form.isLive === 1 ?true:false"
                          checked-children="开"
                          un-checked-children="关"
                          @click="CalendarCalendar_change_isLive(content_CalendarCalendar_form.isLive === 1 ?0:1)"
                          default-checked />

                      <a-select
                          show-search
                          option-filter-prop="children"
                          v-if="content_CalendarCalendar_form.isLive === 1"
                          @popupScroll="popupScrollMeetingId"
                          @search="selectMeetingId"
                          style="width: 200px;margin-left: 5px"
                          v-model="content_CalendarCalendar_form.liveMeetingId"
                          @change="selectOption"
                          placeholder="请选择">
                        <a-select-option
                            v-for="item in meetList"
                            :value="item.id">
                          {{item.title}}
                        </a-select-option>
                      </a-select>

                    </div>
                   </a-form-model-item>
                   <!-- v-if="content_CalendarCalendar_form.isLive === 1"-->
                   <a-form-model-item style="margin-left: 110px" label="直播间" v-if="content_CalendarCalendar_form.isLive === 1">
                    <div style="display: flex;width: 400px;">
                      <a-select
                          :allowClear="true"
                          show-search
                          v-model="content_CalendarCalendar_form.isLiveSelect"
                          placeholder="请选择"
                          option-filter-prop="children"
                          style="width: 300px;"
                          @change="liveRoomChange"
                      >
                        <a-select-option
                            v-for="liveRoom in live_Room"
                            @click="liveRoomClick(liveRoom.id,liveRoom.liveMettingId,liveRoom.targetUrl)"
                            :value="liveRoom.id">
                          {{liveRoom.id}} - {{liveRoom.title}}
                        </a-select-option>
                      </a-select>
                      <a-checkbox-group
                          style="margin-top:5px;margin-left: 25px;width: 200px;"
                          v-model="content_CalendarCalendar_form.isCheck"
                          name="checkboxgroup"
                          :options="plainOptions"
                          @change="onChange"
                      />
                    </div>
                   </a-form-model-item>
                   <a-form-model-item style="margin-left: 40px" label="直播间地址" prop="liveAddress" v-if="content_CalendarCalendar_form.isLive === 1">
                    <div style="display: flex;width: 340px;">
  <!--                    <div style="width: 85px">直播间地址：</div>-->
                      <a-input
                          :disabled="content_CalendarCalendar_form.isLiveSelect !== undefined"
                          style="width: 250px"
                          placeholder="请输入"
                          v-model="content_CalendarCalendar_form.liveAddress"></a-input>
                    </div>
                   </a-form-model-item>
                 </div>
            <div style="display: flex;">
              <a-form-model-item style="margin-left: 10px" label="主持人" prop="compere">
                <a-select
                    mode="multiple"
                    v-model="content_CalendarCalendar_form.compere"
                    placeholder="请选择"
                    option-filter-prop="children"
                    style="width: 250px;"
                >
                  <a-select-option
                      v-for="(chairman,index) in CalendarCalendar_chairmanList"
                      :key="index + 'compere'"
                      v-model="chairman.expertId">
                    {{chairman.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item style="margin-left: 180px" label="主席" prop="chairman">
                <a-select
                    mode="multiple"
                    v-model="content_CalendarCalendar_form.chairman"
                    placeholder="请选择"
                    option-filter-prop="children"
                    style="width: 250px;"
                >
                  <a-select-option
                      v-for="(chairman,index) in CalendarCalendar_chairmanList"
                      :key="index + 'chairman'"
                      v-model="chairman.expertId">
                    {{chairman.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item style="margin-left: 195px" label="嘉宾" prop="honored">
  <!--              <a-input-->
  <!--                  style="width: 250px"-->
  <!--                  v-model="content_CalendarCalendar_form.honored"-->
  <!--                  placeholder="请输入"/>-->
                <a-select
                    mode="multiple"
                    v-model="content_CalendarCalendar_form.honored"
                    placeholder="请选择"
                    option-filter-prop="children"
                    style="width: 250px;"
                >
                  <a-select-option
                      v-for="(chairman,index) in CalendarCalendar_chairmanList"
                      :key="index + 'honored'"
                      v-model="chairman.expertId">
                    {{chairman.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
          </div>
          <a-form-model-item>
            <div style="text-align: center;width: 1000px">
              <a-button
                  style="width: 120px;margin-top: 15px"
                  @click="content_CalendarCalendar_submit"
                  type="primary">提交</a-button>
            </div>
          </a-form-model-item>

          <a-form-model-item>
           <div style="display: flex;margin-left: -20px">
             <div style="width: 110px;margin-bottom:8px;display: flex;margin-top: 4%">
               <a-radio-group @change="tab_address_callback"
                              style="margin:5px;width: 110px"
                              v-model="firstAdressId">
                 <a-radio-button v-for="addList in CalendarCalendar_AddressList"
                                 :style="radioStyle"
                                 :value="addList.id" >
                   <span class="addressSpan">{{addList.address}}</span>
                 </a-radio-button>
               </a-radio-group>
             </div>

             <div style="width: 1300px;margin-left: 15px">
               <div style="width: 100%;margin-bottom:5px;display: flex">
                 <a-radio-group style="margin:8px;width: 88%" v-model="firstTime"
                                @change="tab_pane_callback">
                   <a-radio-button v-for="time in content_CalendarCalendar_timeList_data"
                                   :value="time.beginDate" >
                     {{time.beginDate}}
                   </a-radio-button>
                 </a-radio-group>
                 <div style="width: 10%">
                   <a-button @click="CalendarCalendarAddChildren" type="primary">新增</a-button>
                 </div>
               </div>
               <div style="text-align: center;width: 100%;display: flex;">
                 <a-table
                     :customRow="rowClick"
                     :rowClassName="rowClassName"
                     bordered
                     style="width: 450px"
                     :columns="content_CalendarCalendar_columns"
                     :data-source="content_CalendarCalendar_data"
                     :pagination="false"
                     :rowKey='record=>record.id'>
                <span slot="time" slot-scope="text, record">
                  <span>{{(record.beginTime).slice(10,19)}} ~
                    {{(record.endTime).slice(10,19)}}</span>
                </span>
                   <span slot="compere" slot-scope="text, record">
                  <div>
                    ————
                  </div>
                </span>
                   <span slot="role" slot-scope="text, record">
                  <div>
                    ————
                  </div>
                </span>
                   <span slot="system" slot-scope="text, record">
                  <a  @click="CalendarCalendarRest(record)"
                      style="margin-left: 8px">修改</a>
                  <a-popconfirm
                      style="margin-left: 8px"
                      title="是否确认删除?"
                      @confirm="content_CalendarCalendar_delBtn(record.id)">
                    <a>删除</a>
                  </a-popconfirm>
                </span>

                 </a-table>
                 <a-table
                     bordered
                     style="width: 800px;margin-left: 20px"
                     :columns="content_CalendarCalendar_columns_two"
                     :data-source="content_CalendarCalendar_data_two"
                     :pagination="false"
                     :rowKey="record => record.id">
                   <template
                       v-for="col in ['name']"
                       slot="name"
                       slot-scope="text, record, index">
                     <div :key="col">
                       <!---->
                       <a-input
                           placeholder="请输入学术任务名称"
                           v-if="record.editable && col === 'name'"
                           style="margin: -5px 0;width: 130px"
                           :value="text"
                           @change="e => handleChange_two(e.target.value, record.id, col)"/>
                       <span v-else>{{ text }}</span>
                     </div>
                   </template>
                   <template
                       v-for="col in ['newtime' ]"
                       slot="newtime"
                       slot-scope="text, record, index">
                     <div :key="col">
                       <div v-if="record.editable && col==='newtime'">
                         <a-time-picker
                             :value="moment(record.beginTime, 'HH:mm:ss')"
                             :open-value="moment('00:00:00', 'HH:mm:ss')"
                             @change="e =>handleChange_start(e,record.id, 'beginTime',record)"/>
                         <a-time-picker
                             :open-value="moment('00:00:00', 'HH:mm:ss')"
                             @change="e =>handleChange_end(e,record.id, 'endTime',record)"
                             :value="moment(record.endTime, 'HH:mm:ss')" />
                       </div>
                       <span v-else>{{ record.newtime }}</span>
                     </div>
                   </template>
                   <template
                       v-for="col in ['role']"
                       slot="role"
                       slot-scope="text, record, index">
                     <div :key="col">
                       <a-select
                           v-if="record.editable && col === 'role'"
                           show-search
                           v-model="record.role"
                           placeholder="Select a person"
                           option-filter-prop="children"
                           style="width: 100px">
                         <a-select-option
                             v-for="role in selectRole"
                             @click="select_left_role(role.name,record.id,col)"
                             :value="role.name">
                           {{role.title}}
                         </a-select-option>
                       </a-select>
                       <span v-else>
                      <span v-if="text === 'COMPERE'">主持人</span>
                      <span v-if="text === 'SPEAKER'">讲者</span>
                      <span v-if="text === 'CHAIRMAN'">主席</span>
                      <span v-if="text === 'GUEST'">嘉宾</span>
                    </span>
                     </div>
                   </template>
                   <template
                       v-for="col in ['expertName' ]"
                       slot="expertName"
                       slot-scope="text, record, index">
                     <div :key="col">
                       <!---->
                       <a-select
                           v-if="record.editable && col === 'expertName'"
                           show-search
                           v-model="record.expertName"
                           placeholder="请选择主持讲者"
                           option-filter-prop="children"
                           style="width: 100px">
                         <a-select-option
                             v-for="role in CalendarCalendar_chairmanList"
                             @click="select_left_expertName(role.id,record.id,col,role.expertId)"
                             v-model="role.id">
                           {{role.name}}
                         </a-select-option>
                       </a-select>
                       <span v-else>{{ text }}</span>
                     </div>
                   </template>
                   <template  slot="option" slot-scope="text, record, index">
                     <div class="editable-row-operations">
                    <span v-if="record.editable">
                      <a @click="() => save_two(record.id,record)">保存</a>
                      <a-popconfirm
                          style="margin-left: 10px"
                          title="是否确认取消?"
                          @confirm="() => cancel_two(record.id,record)">
                        <a>取消</a>
                      </a-popconfirm>
                    </span>
                       <span v-else>
                      <a @click="() => edit_two(record.id,record)">修改</a>
                      <a-popconfirm
                          style="margin-left: 8px"
                          title="是否确认删除?"
                          @confirm="() => del_two(record.id,record)">
                        <a>删除</a>
                      </a-popconfirm>
                    </span>
                     </div>
                   </template>
                 </a-table>

                 <!--            <a-table-->
                 <!--                :columns="content_CalendarCalendar_columns"-->
                 <!--                :data-source="content_CalendarCalendar_data"-->
                 <!--                :pagination="false"-->
                 <!--                :rowKey='record=>record.id'-->
                 <!--                @expand="icon_CalendarCalendar_btn"-->
                 <!--            >-->
                 <!--              <span slot="compere" slot-scope="text, record">-->
                 <!--                <div>-->
                 <!--                  ————-->
                 <!--                </div>-->
                 <!--              </span>-->
                 <!--              <span slot="role" slot-scope="text, record">-->
                 <!--                <div>-->
                 <!--                  ————-->
                 <!--                </div>-->
                 <!--              </span>-->
                 <!--              <span slot="time" slot-scope="text, record">-->
                 <!--                <span>{{(record.beginTime).slice(10,19)}} ~-->
                 <!--                  {{(record.endTime).slice(10,19)}}</span>-->
                 <!--              </span>-->
                 <!--              <span slot="system" slot-scope="text, record">-->
                 <!--                <a @click="CalendarCalendarAddChildren(record)">添加</a>-->
                 <!--                <a  @click="CalendarCalendarRest(record)"-->
                 <!--                    style="margin-left: 8px">修改</a>-->
                 <!--                <a-popconfirm-->
                 <!--                    style="margin-left: 8px"-->
                 <!--                    title="是否确认删除?"-->
                 <!--                    @confirm="content_CalendarCalendar_delBtn(record.id)">-->
                 <!--                  <a>删除</a>-->
                 <!--                </a-popconfirm>-->
                 <!--              </span>-->
                 <!--              <a-table-->
                 <!--                  :pagination="false"-->
                 <!--                  :columns="content_CalendarCalendar_columns_two"-->
                 <!--                  slot-scope="row"-->
                 <!--                  :dataSource="row.childrens"-->
                 <!--                  :showHeader="false"-->
                 <!--                  slot="expandedRowRender"-->
                 <!--                  :rowKey="row => row.id"-->
                 <!--                  :locale="{emptyText: '暂无节点'}">-->
                 <!--                <template-->
                 <!--                    v-for="col in ['name']"-->
                 <!--                    slot="name"-->
                 <!--                    slot-scope="text, record, index"-->
                 <!--                >-->
                 <!--                  <div :key="col">-->
                 <!--                    &lt;!&ndash;&ndash;&gt;-->
                 <!--                    <a-input-->
                 <!--                        placeholder="请输入学术任务名称"-->
                 <!--                        v-if="record.editable && col === 'name'"-->
                 <!--                        style="margin: -5px 0;width: 200px"-->
                 <!--                        :value="text"-->
                 <!--                        @change="e => handleChange_two(e.target.value, record.id, col)"-->
                 <!--                    />-->
                 <!--                    <span v-else>-->
                 <!--                      {{ text }}-->
                 <!--                    </span>-->
                 <!--                  </div>-->
                 <!--                </template>-->
                 <!--                <template-->
                 <!--                    v-for="col in ['newtime' ]"-->
                 <!--                    slot="newtime"-->
                 <!--                    slot-scope="text, record, index"-->
                 <!--                >-->
                 <!--                  <div :key="col">-->
                 <!--                    <div v-if="record.editable && col==='newtime'">-->
                 <!--                      <a-time-picker-->
                 <!--                          :defaultValue="moment(record.beginTime, 'HH:mm:ss')"-->
                 <!--                          :open-value="moment('00:00:00', 'HH:mm:ss')"-->
                 <!--                          @change="e =>handleChange_start(e,record.id, 'beginTime',record)"/>-->
                 <!--                      <a-time-picker-->
                 <!--                          :open-value="moment('00:00:00', 'HH:mm:ss')"-->
                 <!--                          @change="e =>handleChange_end(e,record.id, 'endTime',record)"-->
                 <!--                          :defaultValue="moment(record.endTime, 'HH:mm:ss')" />-->
                 <!--                    </div>-->
                 <!--                    <span v-else>-->
                 <!--              {{ record.newtime }}-->
                 <!--            </span>-->
                 <!--                  </div>-->
                 <!--                </template>-->
                 <!--                <template-->
                 <!--                    v-for="col in ['role']"-->
                 <!--                    slot="role"-->
                 <!--                    slot-scope="text, record, index"-->
                 <!--                >-->
                 <!--                  <div :key="col">-->
                 <!--                    <a-select-->
                 <!--                        v-if="record.editable && col === 'role'"-->
                 <!--                        show-search-->
                 <!--                        v-model="record.role"-->
                 <!--                        placeholder="Select a person"-->
                 <!--                        option-filter-prop="children"-->
                 <!--                        style="width: 150px"-->
                 <!--                    >-->
                 <!--                      <a-select-option-->
                 <!--                          v-for="role in selectRole"-->
                 <!--                          @click="select_left_role(role.name,record.id,col)"-->
                 <!--                          :value="role.name">-->
                 <!--                        {{role.title}}-->
                 <!--                      </a-select-option>-->
                 <!--                    </a-select>-->
                 <!--                    <span v-else>-->
                 <!--                      <span v-if="text === 'COMPERE'">主持人</span>-->
                 <!--                      <span v-if="text === 'SPEAKER'">讲者</span>-->
                 <!--                      <span v-if="text === 'CHAIRMAN'">主席</span>-->
                 <!--                      <span v-if="text === 'GUEST'">嘉宾</span>-->
                 <!--                    </span>-->
                 <!--                  </div>-->
                 <!--                </template>-->
                 <!--                <template-->
                 <!--                    v-for="col in ['expertName' ]"-->
                 <!--                    slot="expertName"-->
                 <!--                    slot-scope="text, record, index"-->
                 <!--                >-->
                 <!--                  <div :key="col">-->
                 <!--                    &lt;!&ndash;&ndash;&gt;-->
                 <!--                    <a-select-->
                 <!--                        v-if="record.editable && col === 'expertName'"-->
                 <!--                        show-search-->
                 <!--                        v-model="record.expertName"-->
                 <!--                        placeholder="请选择主持讲者"-->
                 <!--                        option-filter-prop="children"-->
                 <!--                        style="width: 150px"-->
                 <!--                    >-->
                 <!--                      <a-select-option-->
                 <!--                          v-for="role in CalendarCalendar_chairmanList"-->
                 <!--                          @click="select_left_expertName(role.id,record.id,col,role.expertId)"-->
                 <!--                          v-model="role.id">-->
                 <!--                        {{role.name}}-->
                 <!--                      </a-select-option>-->
                 <!--                    </a-select>-->
                 <!--                    <span v-else>-->
                 <!--              {{ text }}-->
                 <!--            </span>-->
                 <!--                  </div>-->
                 <!--                </template>-->
                 <!--                <template  slot="option" slot-scope="text, record, index">-->
                 <!--                  <div class="editable-row-operations">-->
                 <!--                    <span v-if="record.editable">-->
                 <!--                      <a @click="() => save_two(record.id,record)">保存</a>-->
                 <!--                      <a-popconfirm-->
                 <!--                          style="margin-left: 10px"-->
                 <!--                          title="是否确认取消?"-->
                 <!--                          @confirm="() => cancel_two(record.id,record)">-->
                 <!--                        <a>取消</a>-->
                 <!--                      </a-popconfirm>-->
                 <!--                    </span>-->
                 <!--                    <span v-else>-->
                 <!--                      <a :disabled="editingKey_two !== ''"-->
                 <!--                          @click="() => edit_two(record.id,record)">修改</a>-->
                 <!--                      <a-popconfirm-->
                 <!--                          :disabled="editingKey_two !== ''"-->
                 <!--                          style="margin-left: 8px"-->
                 <!--                          title="是否确认删除?"-->
                 <!--                          @confirm="() => del_two(record.id,record)">-->
                 <!--                        <a>删除</a>-->
                 <!--                      </a-popconfirm>-->

                 <!--                    </span>-->
                 <!--                  </div>-->
                 <!--                </template>-->

                 <!--              </a-table>-->
                 <!--            </a-table>-->
               </div>
             </div>
           </div>
          </a-form-model-item>
        </a-form-model>
  <!--      <div-->
  <!--          :style="{-->
  <!--          position: 'absolute',-->
  <!--          right: 0,-->
  <!--          bottom: 0,-->
  <!--          width: '100%',-->
  <!--          borderTop: '1px solid #e9e9e9',-->
  <!--          padding: '10px 16px',-->
  <!--          background: '#fff',-->
  <!--          textAlign: 'right',-->
  <!--          zIndex: 1,-->
  <!--        }"-->
  <!--      >-->
  <!--        <a-button type="primary" @click="closeCalendarCalendar">-->
  <!--          关闭-->
  <!--        </a-button>-->
  <!--      </div>-->
      </a-drawer>
      <!--内容-主持讲者展示  -ZCJZ-->
      <a-drawer
          title="内容-主持讲者展示"
          :width="900"
          :visible="content_LiveBroadcastCalendar_list_visible"
          :body-style="{ paddingBottom: '80px' }"
          @close="close_listLiveBroadcastCalendar"
      >
        <div>
          <span>姓名</span>
          <a-input
              placeholder="请输入"
              v-model="LiveBroadcastCalendar_list_top_name"
              style="width: 200px;margin-left: 5px"/>
          <a-button
              type="primary"
              style="margin-left: 15px"
              @click="LiveBroadcastCalendar_list_top_btn">查询</a-button>
          <a-button
              @click="()=>{
                this.content_LiveBroadcastCalendar_visible = true;
                this.getXsxlFile()
              }"
              style="float: right;margin-left: 10px;margin-bottom: 10px">新增/导入</a-button>
        </div>
        <a-table
            :rowKey='record=>record.id'
            :pagination="false"
            :columns="LiveBroadcastCalendar_list_columns"
            :data-source="LiveBroadcastCalendar_list_data">
          <span slot="headimg" slot-scope="text, record">
            <img
                style="width: 50px;height: 50px"
                :src="record.headimg" alt="">
          </span>
          <span slot="system" slot-scope="text, record">
             <a-popconfirm
                 title="是否确认删除?"
                 ok-text="Yes"
                 cancel-text="No"
                 @confirm="content_zcjz_delBtn(record.id)"
                 @cancel="()=>{}"
             ><a>删除</a></a-popconfirm>
          </span>
        </a-table>
        <div style="width: 100%;height: 50px;">
          <div style="float: right;margin-right: 20px;margin-top: 10px">
            <a-pagination
                show-quick-jumper
                :total="LiveBroadcastCalendar_list_count"
                @change="LiveBroadcastCalendar_list_pagination_Change" />
          </div>
        </div>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button type="primary" @click="close_listLiveBroadcastCalendar">
            关闭
          </a-button>
        </div>
      </a-drawer>
      <a-drawer
          title="内容-主持讲者展示"
          :width="1300"
          :visible="content_LiveBroadcastCalendar_visible"
          :body-style="{ paddingBottom: '80px' }"
          @close="close_LiveBroadcastCalendar"
      >
        <a-form-model
            ref="content_LiveBroadcastCalendar_ruleForm_preside_over"
            :model="content_LiveBroadcastCalendar_form"
            :rules="content_LiveBroadcastCalendar_rules"
            :label-col="labelCol_LiveBroadcastCalendar"
            :wrapper-col="wrapperCol"
        >
          <div class="content_LiveBroadcastCalendar">
            <div style="display: flex;width: 100%">
              <div class="content_LiveBroadcastCalendar-top">
                <div style="margin-top: 5px;margin-left: 10px">选择文件</div>
                <div style="display: flex">
                  <a-input-search
                      v-model="content_LiveBroadcastCalendar_file"
                      style="width: 260px;margin-left: 15px"
                      placeholder="请上传.xlsx文件"
                      @search="onSearch">
                    <a-button slot="enterButton" style="width: 80px">
                      <div class="add-file">
                        <div style="margin-top: 5px;margin-left: -25px">选择</div>
                        <input
                            id="uploadFile_zcjz"
                            type="file"
                            @change="content_LiveBroadcastCalendar_addFile"
                            ref="content_LiveBroadcastCalendar_addFile_inputFile"
                            class="fileAdd"
                            accept=".xlsx,.xls"
                        />
                      </div>
                    </a-button>
                  </a-input-search>
                  <a-button
                      @click="true_LiveBroadcastCalendar_addFile"
                      style="margin-left: 10px"
                      type="primary">确认导入</a-button>
                </div>
              </div>
              <div class="content_LiveBroadcastCalendar-top">
                <div style="margin-top: 5px;margin-left: 10px">姓名：</div>
                <a-input
                    style="width: 300px;margin-left: 5px;"
                    placeholder="请输入"
                    v-model="name_input"/>
                <a-button
                    @click="look_LiveBroadcastCalendar_data_right(name_input)"
                    style="margin-left: 10px" type="primary">查询</a-button>
              </div>
            </div>
            <div style="margin-bottom: 5px;display: flex">
              <div>
                <a-button @click="DownloadTemplate" style="margin-left: 10px"><a-icon style="color: #00CC33" type="file-excel" />下载模板</a-button>
                <a-popconfirm style="margin-left: 5px" title="是否确认清空全部临时表?" ok-text="Yes" cancel-text="No" @confirm="del_all()"><a-button >清空全部</a-button></a-popconfirm>
              </div>
              <div style="margin-left: 290px">
                <a-button type="primary" @click="addBtn">新增</a-button>
              </div>
            </div>

            <div class="content_LiveBroadcastCalendar-mid">
              <div class="content_LiveBroadcastCalendar-mid-left">
                <a-table
                    style="width: 90%;"
                    :pagination="false"
                    :rowKey='record=>record.id'
                    :columns="LiveBroadcastCalendar_columns"
                    :data-source="LiveBroadcastCalendar_data">
                  <span slot="action" slot-scope="text, record">
                    <a @click="look_LiveBroadcastCalendar_data_right(record.name,record.id)">查询</a>
                    <a-popconfirm
                        title="是否确认删除?"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="look_LiveBroadcastCalendar_data_right_del(record.id)">
                      <a style="margin-left: 8px">删除</a></a-popconfirm>

                  </span>
                </a-table>
                <div style="width: 90%">
                  <div style="float: right">
                    <a-pagination
                        show-quick-jumper
                        :default-current="LiveBroadcastCalendar_pagination_page_no"
                        :total="LiveBroadcastCalendar_pagination_total"
                        @change="LiveBroadcastCalendar_pagination_Change" />
                  </div>
                </div>
              </div>
              <div class="content_LiveBroadcastCalendar-mid-right">
  <!--              <a-button-->
  <!--                  @click="()=>{-->
  <!--                    this.btn_isShow = true-->
  <!--                    this.content_LiveBroadcastCalendar_visible_model = true}"-->
  <!--                  type="primary">新增专家</a-button>-->
                <a-table
                    style="width: 100%;margin: auto"
                    :pagination="false"
                    :rowKey='record=>record.id'
                    :columns="LiveBroadcastCalendar_columns_right"
                    :data-source="LiveBroadcastCalendar_data_right">
                  <span slot="action" slot-scope="text, record">
                    <a @click="look_LiveBroadcastCalendar_one(record.id)">查看/修改</a>
                    <a v-if="!record.isExits" @click="add_exel_list(record.id)" style="margin-left: 10px">选择</a>
                    <a v-else style="margin-left: 10px;color: red">已选择</a>
                  </span>
                  <span slot="headimg" slot-scope="text, record">
                    <img
                        style="width: 50px;height: 50px"
                        :src="record.headimg" alt="">
                  </span>
                </a-table>
                <div style="width: 95%;height: auto;">
                  <div style="float: right">
                    <a-pagination
                        show-quick-jumper
                        :default-current="LiveBroadcastCalendar_pagination_right_page_no"
                        :total="LiveBroadcastCalendar_pagination_total_right"
                        @change="LiveBroadcastCalendar_pagination_Change_right" />
                  </div>
                </div>
                <!-- 表单-->
  <!--              <div style="margin-top:50px;width: 90%;margin-left: 30px">-->
  <!--                <div style="display: flex">-->
  <!--                  <div style="width: 60%">-->
  <!--                    <a-form-model-item prop="headimg" label="头像">-->
  <!--                      <div style="display: flex;width: 450px;">-->
  <!--                        <div class="uploadImg" >-->
  <!--                          <input-->
  <!--                              id="uploadFile_zcjz_img"-->
  <!--                              style="width: 100%;height:100%;opacity: 0;"-->
  <!--                              accept="image/*"-->
  <!--                              type="file"-->
  <!--                              @change="content_LiveBroadcastCalendar__image"-->
  <!--                              ref="content_LiveBroadcastCalendar" />-->
  <!--                        </div>-->
  <!--                        <div-->
  <!--                            class="upload_LiveBroadcast">-->
  <!--                          <img-->
  <!--                              v-if="content_LiveBroadcastCalendar_form.headimg"-->
  <!--                              alt=""-->
  <!--                              style="width: 100%;height:100%"-->
  <!--                              :src="content_LiveBroadcastCalendar_form.headimg"-->
  <!--                          />-->
  <!--                        </div>-->
  <!--                      </div>-->
  <!--                      <div style="color: #a1a1a1">注:尺寸 200*200，大小不超过2M</div>-->
  <!--                    </a-form-model-item>-->
  <!--                    <a-form-model-item  label="科室">-->
  <!--                      <a-input-->
  <!--                          v-model="content_LiveBroadcastCalendar_form.departmentCode"-->
  <!--                          style="width: 200px"-->
  <!--                          placeholder="请输入"/>-->
  <!--                    </a-form-model-item>-->
  <!--                  </div>-->
  <!--                  <div style="width:40%">-->
  <!--                    <a-form-model-item prop="name" label="姓名">-->
  <!--                      <a-input-->
  <!--                          v-model="content_LiveBroadcastCalendar_form.name"-->
  <!--                          style="width: 200px"-->
  <!--                          placeholder="请输入"/>-->
  <!--                    </a-form-model-item>-->
  <!--                    <a-form-model-item prop="hospital" label="医院">-->
  <!--                      <a-input-->
  <!--                          v-model="content_LiveBroadcastCalendar_form.hospital"-->
  <!--                          style="width: 200px"-->
  <!--                          placeholder="请输入"/>-->
  <!--                    </a-form-model-item>-->
  <!--                    <a-form-model-item prop="title" label="职称">-->
  <!--                      <a-input-->
  <!--                          v-model="content_LiveBroadcastCalendar_form.title"-->
  <!--                          style="width: 200px"-->
  <!--                          placeholder="请输入"/>-->
  <!--                    </a-form-model-item>-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--                <div style="display: flex;">-->
  <!--                 <a-form-model-item label="简介" prop="intro">-->
  <!--                   <a-textarea-->
  <!--                       style="width: 800px"-->
  <!--                       v-model="content_LiveBroadcastCalendar_form.intro"-->
  <!--                       placeholder="请输入"-->
  <!--                       :auto-size="{ minRows: 8, maxRows: 10 }"-->
  <!--                   />-->
  <!--                 </a-form-model-item>-->
  <!--                </div>-->
  <!--                <div style="width: 50%;margin: auto">-->
  <!--                  <a-button-->
  <!--                   @click="LiveBroadcastCalendar_pagination_Add"-->
  <!--                  >添加到专家库</a-button>-->
  <!--                  <a-button-->
  <!--                      @click="LiveBroadcastCalendar_pagination_onSubmit"-->
  <!--                      style="margin-left: 15px"-->
  <!--                      type="primary">提交修改</a-button>-->
  <!--                  <a-button-->
  <!--                      @click="LiveBroadcastCalendar_pagination_rest"-->
  <!--                      style="margin-left: 15px"-->
  <!--                      type="primary">重置</a-button>-->
  <!--                </div>-->
  <!--              </div>-->
              </div>
            </div>
          </div>
        </a-form-model>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button type="primary" @click="close_LiveBroadcastCalendar">
            关闭
          </a-button>
        </div>

      </a-drawer>

      <!--内容-企业海报展示   QYHB -->
      <a-drawer
          title="内容-企业海报展示"
          :width="1000"
          :visible="content_EnterprisePosters_list_visible"
          :body-style="{ paddingBottom: '80px' }"
          @close="close_EnterprisePosters_list"
      >
        <a-button
            type="primary"
            @click="EnterprisePosters_list_add" style="margin-left: 8px">新增</a-button>
        <a-table
            :pagination="false"
            :rowKey='record=>record.id'
            :columns="EnterprisePosters_list_columns"
            :data-source="EnterprisePosters_list_data">
          <span slot="thumbnail" slot-scope="text, record">
            <img
                style="width: 50px;height: 50px"
                :src="record.thumbnail" alt="">
          </span>
          <span slot="action" slot-scope="text, record">
            <a @click="EnterprisePosters_list_rest(record)">修改</a>
            <a @click="EnterprisePosters_list_del" style="margin-left: 8px">删除</a>
            <a @click="EnterprisePosters_list_up(record.id)" style="margin-left: 8px">上移</a>
            <a @click="EnterprisePosters_list_down(record.id)" style="margin-left: 8px">下移</a>
          </span>
        </a-table>
        <div style="width: 100%;margin-top: 15px">
          <div style="float: right">
            <a-pagination
                show-quick-jumper
                :default-current="EnterprisePosters_pagination_page_no"
                :total="EnterprisePosters_pagination_total"
                @change="pagination_EnterprisePosters_onChange" />
          </div>
        </div>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button type="primary" @click="close_EnterprisePosters_list">
            关闭
          </a-button>
        </div>
      </a-drawer>
      <a-drawer
          title="企业海报-新增/修改"
          :width="800"
          :visible="content_EnterprisePosters_visible"
          :body-style="{ paddingBottom: '80px' }"
          @close="close_EnterprisePosters"
      >
        <a-form-model
            ref="content_EnterprisePosters_ruleForm"
            :model="content_EnterprisePosters_form"
            :rules="content_EnterprisePosters_rules"
            :label-col="labelCol_LiveBroadcastCalendar_haiBao"
            :wrapper-col="wrapperCol">
          <div style="display: flex">
           <div style="width: 50%">
             <a-form-model-item prop="thumbnail" label="缩略图">
               <div style="display: flex;width: 450px;">
                 <div class="uploadImg" >
                   <input
                       id="up_img_LiveBroadcastCalendar_thumbnail"
                       style="width: 100%;height:100%;opacity: 0;"
                       accept="image/*"
                       type="file"
                       @change="content_LiveBroadcastCalendar_thumbnail_image"
                       ref="content_LiveBroadcastCalendar_thumbnail_image" />
                 </div>
                 <div
                     class="upload_LiveBroadcast">
                   <img
                       v-if="content_EnterprisePosters_form.thumbnail"
                       alt=""
                       style="width: 100%;height:100%"
                       :src="content_EnterprisePosters_form.thumbnail"
                   />
                 </div>
               </div>
               <div style="color: #a1a1a1">注:尺寸 宽640，大小不超过1M</div>
             </a-form-model-item>
           </div>
            <div style="width: 50%">
              <a-form-model-item prop="original" label="原图 ">
                <div style="display: flex;width: 450px;">
                  <div class="uploadImg" >
                    <input
                        id="up_img_LiveBroadcastCalendar_original"
                        style="width: 100%;height:100%;opacity: 0;"
                        accept="image/*"
                        type="file"
                        @change="content_LiveBroadcastCalendar_original_image"
                        ref="content_LiveBroadcastCalendar_original_image" />
                  </div>
                  <div
                      class="upload_LiveBroadcast">
                    <img
                        v-if="content_EnterprisePosters_form.original"
                        alt=""
                        style="width: 100%;height:100%"
                        :src="content_EnterprisePosters_form.original"
                    />
                  </div>
                </div>
                <div style="color: #a1a1a1">注:尺寸 宽640，大小不超过1M</div>
              </a-form-model-item>
            </div>
          </div>
          <div style="display: flex">
            <div style="width: 50%">
              <a-form-model-item prop="livePendingImage" label="直播未开始">
                <div style="display: flex;width: 450px;">
                  <div class="uploadImg" >
                    <input
                        style="width: 100%;height:100%;opacity: 0;"
                        accept="image/*"
                        type="file"
                        @change="content_LiveBroadcastCalendar_start_image"
                        ref="content_livePendingImage" />
                  </div>
                  <div
                      class="upload_LiveBroadcast">
                    <img
                        v-if="content_EnterprisePosters_form.livePendingImage"
                        alt=""
                        style="width: 100%;height:100%"
                        :src="content_EnterprisePosters_form.livePendingImage"
                    />
                  </div>
                </div>
                <div style="color: #a1a1a1">注:尺寸 宽640，大小不超过1M</div>
              </a-form-model-item>
            </div>
            <div style="width: 50%">
              <a-form-model-item prop="liveEndImage" label="直播已结束">
                <div style="display: flex;width: 450px;">
                  <div class="uploadImg" >
                    <input
                        style="width: 100%;height:100%;opacity: 0;"
                        accept="image/*"
                        type="file"
                        @change="content_LiveBroadcastCalendar_end_image"
                        ref="content_liveEndImage" />
                  </div>
                  <div
                      class="upload_LiveBroadcast">
                    <img
                        v-if="content_EnterprisePosters_form.liveEndImage"
                        alt=""
                        style="width: 100%;height:100%"
                        :src="content_EnterprisePosters_form.liveEndImage"
                    />
                  </div>
                </div>
                <div style="color: #a1a1a1">注:尺寸 宽640，大小不超过1M</div>
              </a-form-model-item>
            </div>
          </div>
          <div>
            <a-form-model-item label="会议日期" prop="meetDate">
              <a-date-picker
                  :disabled-date="disabledDate"
                  placeholder="请选择"
                  style="width: 300px"
                  :allowClear="false"
                  v-model="content_EnterprisePosters_form.meetDate"
                  @change="EnterprisePosters_onChange" />
            </a-form-model-item>
          </div>
          <div>
            <a-form-model-item label="企业名称" prop="enterpriseName">
              <a-input
                  placeholder="请输入"
                  style="width: 300px"
                  v-model="content_EnterprisePosters_form.enterpriseName"/>
            </a-form-model-item>
            <a-form-model-item label="关联日程" prop="scheduleId">
              <a-select
                  show-search
                  placeholder="请选择"
                  option-filter-prop="children"
                  style="width: 300px"
                  v-model="content_EnterprisePosters_form.scheduleName"
              >
                <a-select-option
                    v-for="live in EnterprisePosters_relevance"
                    @click="EnterprisePosters_handleChange(live.id,live.title)"
                    v-model="live.title">
                  {{ live.title }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
        </a-form-model>

        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button  @click="close_EnterprisePosters">
            取消
          </a-button>
          <a-button
              style="margin-left: 10px"
              type="primary" @click="onsubmit_EnterprisePosters">
            确定
          </a-button>
        </div>
      </a-drawer>
      <!-- 点播视频-DBHF   -->
      <a-drawer
          title="内容-点播视频展示"
          :width="1300"
          :visible="content_video_demand_visible"
          :body-style="{ paddingBottom: '80px' }"
          @close="close_video_demand"
      >
        <div style="width: 95%;margin: auto">
          <a-button
              @click="upVideo_btn"
              type="primary"
              style="margin-bottom: 10px">上传视频</a-button>
        </div>
        <a-table
            style="width: 95%;margin: auto"
            :pagination="false"
            :rowKey='record=>record.id'
            :columns="video_demand_columns"
            :data-source="video_demand_data">
          <span slot="action" slot-scope="text, record">
            <a @click="upDown_video_demand('up',record.id)">上移</a>
            <a @click="upDown_video_demand('down',record.id)" style="margin-left: 8px">下移</a>
            <a @click="rest_video_demand(record)" style="margin-left: 8px">修改</a>
            <a-popconfirm
                style="margin-left: 8px"
                title="是否确认删除?"
                @confirm="content_video_delBtn(record.id)">
              <a>删除</a>
            </a-popconfirm>
          </span>
          <span slot="name" slot-scope="text, record">
            <span>{{record.name}} - {{record.hospital}}</span>
          </span>
          <span slot="duration" slot-scope="text, record">
                <span>{{ current_times(text) }}</span>
          </span>
        </a-table>
        <div style="width: 95%;margin-bottom: 20px">
          <div style="float: right;">
            <a-pagination
                show-quick-jumper
                :default-current="video_demand_pagination_page_no"
                :total="video_demand_pagination_total"
                @change="video_demand_pagination_Change" />
          </div>
        </div>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button  @click="close_video_demand">
            关闭
          </a-button>
        </div>
      </a-drawer>
      <a-drawer
            title="上传视频"
            :width="700"
            :visible="content_video_demand_visible_upVideo"
            :body-style="{ paddingBottom: '80px' }"
            @close="close_video_demand_upVideo"
        >
          <a-form-model
              ref="content_video_ruleForm"
              :model="content_video_demand_form"
              :rules="content_video_demand_rules"
              :label-col="labelCol_video"
              :wrapper-col="wrapperCol">
                <a-form-model-item prop="surface_plot" label="封面图 ">
                  <div style="display: flex;width: 450px;">
                    <div class="uploadImg" >
                      <input
                          id="up_img_surface_plot"
                          style="width: 100%;height:100%;opacity: 0;"
                          accept="image/*"
                          type="file"
                          @change="content_surface_plot_image"
                          ref="content_surface_plot_original_image" />
                    </div>
                    <div
                        class="upload_LiveBroadcast">
                      <img
                          v-if="content_video_demand_form.surface_plot"
                          alt=""
                          style="width: 100%;height:100%"
                          :src="content_video_demand_form.surface_plot"
                      />
                    </div>
                  </div>
                  <div style="color: #a1a1a1">注:尺寸 宽640，大小不超过1M</div>
                </a-form-model-item>
                <a-form-model-item prop="video" label="视频 ">
                  <div style="display: flex;width: 450px;">
                    <div class="uploadImg" >
                      <input
                          id="up_video_surface_plot"
                          style="width: 100%;height:100%;opacity: 0;"
                          type="file" accept=".mp4"
                          @change="content_surface_plot_original_video($event)"/>
                    </div>
                    <div
                        @click="openVideoUrl(content_video_demand_form.video)"
                        class="upload_LiveBroadcast">
                      <video
                          style="width: 100%;height:100%"
                          :src="content_video_demand_form.video"></video>
                    </div>
                  </div>
  <!--                <div style="color: #a1a1a1">注:尺寸 宽640，大小不超过1M</div>-->
                </a-form-model-item>
                <a-form-model-item prop="title" label="标题 ">
                  <a-input
                      v-model="content_video_demand_form.title"
                      style="width: 300px"
                      placeholder="请输入"
                  />
                </a-form-model-item>
                <a-form-model-item  prop="specialist" label="专家 ">
                  <a-select
                      id="select_specialist"
                      @popupScroll="popupScroll_live"
                      @search="select_live"
                      v-model="content_video_demand_form.specialist"
                      show-search
                      placeholder="请选择"
                      option-filter-prop="children"
                      style="width: 300px"
                  >
                    <a-select-option
                        v-for="expert in video_experts_list"
                        @click="video_demand_handleChange(expert.id,expert.name)"
                        :value="expert.id">
                      {{expert.name}}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item  prop="include" label="介绍 ">
                  <div ref="editor"></div>
                </a-form-model-item>
                <a-form-model-item label="片头">
                  <a-select
                  v-model="content_video_demand_form.titleStart"
                  show-search
                  placeholder="请选择"
                  option-filter-prop="children"
                  style="width: 340px"
              >
                <a-select-option
                    v-for="(Starts,index) in startList"
                    :key="index"
                    :value="Starts.shortId">
                  {{Starts.title}}
                </a-select-option>
              </a-select>
                </a-form-model-item>
                <a-form-model-item label="片尾">
                  <a-select
                  v-model="content_video_demand_form.titleEnd"
                  show-search
                  placeholder="请选择"
                  option-filter-prop="children"
                  style="width: 340px"
              >
                <a-select-option
                    v-for="(ends,index) in endList"
                    :key="index"
                    :value="ends.shortId">
                  {{ends.title}}
                </a-select-option>
              </a-select>
                </a-form-model-item>
          </a-form-model>
          <div
              :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
          >
            <a-button  @click="close_video_demand_upVideo">
              取消
            </a-button>
            <a-button
                style="margin-left: 10px"
                type="primary" @click="onsubmit_video_demand_upVideo">
              确定
            </a-button>
          </div>
        </a-drawer>
      <!--   地点-日程日历展示   RCYL -->
      <a-drawer
          title="地点-日程日历展示"
          :width="600"
          :visible="content_place_visible"
          :body-style="{ paddingBottom: '80px' }"
          @close="close_place_demand"
      >
  <!--      <a-button-->
  <!--          type="primary"-->
  <!--          style="margin-bottom: 15px"-->
  <!--          @click="place_list_add">新增</a-button>-->
        <div style="display: flex;">
          <span style="margin-top: 5px;width: 150px;text-align: right"><span style="color: red;margin-left: 5px">*</span>地点：</span>
          <a-input
              style="width: 300px"
              placeholder="请输入"
              v-model="content_place_modal_address"
          />
        </div>
        <div style="display: flex;margin-top: 10px">
          <span style="margin-top: 5px;width: 150px;text-align: right"><span style="color: red;margin-left: 5px">*</span>会议机动人员：</span>
          <a-select
              style="width: 300px"
              show-search
              option-filter-prop="children"
              v-model="mobileId"
              placeholder="请选择">
            <a-select-option
                v-for="select_o in PerList"
                :value="select_o.id">
              {{select_o.userName}}
            </a-select-option>
          </a-select>
        </div>

        <div style="width: 100%;text-align: center">
  <!--        <div><span style="color: red" v-if="content_place_modal_addressTrue">请输入</span></div>-->
          <a-button type="primary" style="margin-bottom: 10px;margin-top: 10px" @click="content_place_modal_Ok">提交</a-button>
        </div>
        <a-table
            :pagination="false"
            :rowKey='record=>record.id'
            :columns="place_list_columns"
            :data-source="place_list_data">
          <span slot="action" slot-scope="text, record">
            <a @click="place_list_rest(record)">修改</a>
            <a-popconfirm
                style="margin-left: 8px"
                title="是否确认删除?"
                @confirm="place_list_del(record.id)">
                    <a>删除</a>
                  </a-popconfirm>
            <a @click="moveAddress(record.id,'up')" style="margin-left: 8px">上移</a>
            <a @click="moveAddress(record.id,'down')" style="margin-left: 8px">下移</a>
          </span>
        </a-table>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button type="primary" @click="close_place_demand">
            关闭
          </a-button>
        </div>
      </a-drawer>
        <!--      内容-企业海报展示-->
        <a-drawer
            title="内容-企业海报展示"
            :width="800"
            :visible="enterprisePosters"
            :body-style="{ paddingBottom: '80px' }"
            @close="closeEnterprisePosters"
        >
  <!--        <a-alert type="info" show-icon >-->
  <!--          <template slot="message">-->
  <!--            <p>1.头图自动显示关联此会议的Banner，请在Banner管理模块设置</p>-->
  <!--            <p>2.建议封面尺寸为1080px*1527px</p>-->
  <!--          </template>-->
  <!--        </a-alert>-->
          <a-alert
              type="info"
              show-icon
          >
            <template slot="description">
              <div>1、头图自动显示关联此会议的Banner，请在Banner管理模块设置</div>
              <div>2、建议封面尺寸为1080px*1527px</div>
            </template>
          </a-alert>
          <div style="width: 100%;display: flex;justify-content: center;margin-top: 10px;margin-bottom: 10px">
            <span style="margin-top: 5px">企业：</span>
            <a-select
                show-search
                option-filter-prop="children"
                @popupScroll="popupScrollMeetingId"
                @search="selectMeetingId"
                style="width: 300px;margin-right: 10px"
                v-model="enterprisePosters_meetingId"
                placeholder="请选择直播会议">
              <a-select-option
                  v-for="item in meetList"
                  :value="item.id">
                {{item.title}}
              </a-select-option>
            </a-select>
            <a-button @click="addMeetBtn" type="primary">添加</a-button>
          </div>
          <a-table
              :pagination="false"
              :rowKey='record=>record.id'
              :columns="EnterprisePostersColumns"
              :data-source="EnterprisePostersData">
          <span slot="descImageUrl" slot-scope="text, record">
            <img
                style="width: 100px;height: auto"
                :src="record.descImageUrl" alt="">
          </span>
            <span slot="action" slot-scope="text, record">
              <a-popconfirm
                  style="margin-left: 8px"
                  title="是否确认删除?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="EnterprisePostersDel(record.id)"
              ><a href="#">删除</a></a-popconfirm>
            <a @click="EnterprisePostersUp(record.id,'up')" style="margin-left: 8px">上移</a>
            <a @click="EnterprisePostersDown(record.id,'down')" style="margin-left: 8px">下移</a>
          </span>
          </a-table>
        </a-drawer>

        <!-- 人员名单-->
        <a-drawer
            :width="1200"
            :visible="ExcelMember"
            title="人员名单"
            :body-style="{ paddingBottom: '80px' }"
            @close="excelClose">
          <div style="width: 100%">
            <div style="display: flex;justify-content: center;height: 35px;line-height: 35px">
              <span>选择文件 </span>
              <a-input-search
                  v-model="changeFile"
                  style="width: 260px;margin-left: 5px"
                  placeholder="请上传.xlsx文件">
                <a-button slot="enterButton" style="width: 80px">
                  <div class="add-file">
                    <!--                    <div style="margin-top: 5px;margin-left: -25px"  @click="toImport">选择</div>-->
                    <div style="margin-top: 5px;margin-left: -25px">选择</div>
                    <input
                        id="uploadFile"
                        type="file"
                        @change="changeFileElsx"
                        ref="uploadFile_inputFile"
                        class="fileAdd"
                        style="position: absolute;top: 1px;left: -12px;width: 80px;opacity:0"
                        accept=".xlsx,.xls"
                    />
                  </div>
                </a-button>
              </a-input-search>
              <a-button
                  @click="importAddFile"
                  style="margin-left: 10px"
                  type="primary">确认导入</a-button>
              <a-button
                  @click="DownloadTemplates"
                  style="margin-left: 10px"
              >下载模板</a-button>
            </div>
            <div style="display: flex;height: 35px;line-height: 35px;margin-top: 20px;justify-content: space-between">
              <div>
                <span>手机号：</span>
                <a-input allowClear
                         placeholder="请输入"
                         style="width: 200px;"
                         @pressEnter="inquireBtn"
                         v-model="listTel"/>
                <a-button
                    @click="inquireBtn"
                    style="margin-left: 10px"
                    type="primary">查询</a-button>
              </div>
              <a-button style="margin-top: 5px" type="primary"  @click="addPerson">新增</a-button>
            </div>
            <div style="margin-top: 20px">
              <a-table :columns="columnsList"
                       :pagination="false"
                       :rowKey='record=>record.id'
                       :data-source="dataLists">
                <template slot="action" slot-scope="text, record">
                  <a-popconfirm
                      title="是否确认删除?"
                      ok-text="是"
                      cancel-text="否"
                      @confirm="confirmDelList(record.id)"
                  ><a>删除</a>
                  </a-popconfirm>
                </template>
                <template slot="serialNumber" slot-scope="text, record,index">
                  <span>{{(listPageNo-1)*10+index+1}}</span>
                </template>
                <template slot="type" slot-scope="text, record">
                  <span v-if="text === '1001'">医生</span>
                  <span v-if="text === '1002'">护士</span>
                  <span v-if="text === '1003'">医技</span>
                  <span v-if="text === '1004'">药师</span>
                  <span v-if="text === '1005'">学生</span>
                  <span v-if="text === '1006'">其他医务从业者</span>
                  <span v-if="text === '1007'">非医务从业者</span>
                </template>
              </a-table>
              <div style="width: 100%;display: flex;justify-content: right;margin-top: 5px">
                <a-pagination
                    show-quick-jumper
                    show-size-changer
                    :current="listPageNo"
                    :pageSize="listpageSize"
                    :total="listCount"
                    @showSizeChange="templateChangeSize"
                    @change="templateChange" />
              </div>
            </div>
          </div>
        </a-drawer>
        <!-- 人员名单新增-->
        <a-drawer
            :width="500"
            :visible="addPersonVisile"
            title="人员名单新增"
            :body-style="{ paddingBottom: '80px' }"
            @close="addPersonClose">
          <a-form-model
              ref="PersonnelListRef"
              :model="personnelListForm"
              :rules="personnelListRules"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
          >
            <a-form-model-item label="手机号" prop="tel">
              <a-input-search
                  v-model="personnelListForm.tel"
                  style="width: 350px"
                  placeholder="请输入"
                  enter-button="搜索"
                  size="large"
                  @search="onSearchs"
              />
            </a-form-model-item>
            <a-form-model-item label="身份">
              <a-select
                  :disabled="addPersonDisabled"
                  show-search
                  option-filter-prop="children"
                  @change="selectNumberType"
                  placeholder="请选择"
                  v-model="personnelListForm.type"
                  style="width: 300px">
                <a-select-option value="1001">
                  医生
                </a-select-option>
                <a-select-option value="1002">
                  护士
                </a-select-option>
                <a-select-option value="1003">
                  医技
                </a-select-option>
                <a-select-option value="1004">
                  药师
                </a-select-option>
                <a-select-option value="1005">
                  学生
                </a-select-option>
                <a-select-option value="1006">
                  其他医务从业者
                </a-select-option>
                <a-select-option value="1007">
                  非医务从业者
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="姓名" prop="name">
              <a-input id="name" @change="nameChange" :disabled="addPersonDisabled" style="width: 300px;" v-model="personnelListForm.name" placeholder="请输入" />
            </a-form-model-item>
            <a-form-model-item label="省份/城市">
              <a-cascader
                  :disabled="addPersonDisabled"
                  style="width: 300px"
                  v-model="personnelListForm.province"
                  :options="provincePtions"
                  placeholder="请选择"
                  @change="provinceChange"
              />
            </a-form-model-item>
            <!-------------------------------1001 ~ 1004---------------------------------->
            <div v-if="personnelListForm.type === '1001' || personnelListForm.type === '1002' || personnelListForm.type === '1003' ||personnelListForm.type === '1004'">
              <a-form-model-item label="医院">
                <a-select
                    show-search
                    option-filter-prop="children"
                    @popupScroll="popupScrollHos"
                    @search="selectHos"
                    :disabled="addPersonDisabled"
                    style="width: 300px"
                    v-model="personnelListForm.hospitalName"
                    placeholder="请选择">
                  <a-select-option
                      v-for="item in hospitalNameList"
                      :value="item.name">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="科室">
                <a-cascader
                    :disabled="addPersonDisabled"
                    style="width: 300px"
                    v-model="personnelListForm.hospitalOffices"
                    :options="hospitalOfficesList"
                    placeholder="请选择"/>
              </a-form-model-item>
              <a-form-model-item label="职称">
                <a-select
                    :disabled="addPersonDisabled"
                    style="width: 300px"
                    v-model="personnelListForm.jobTitle"
                    placeholder="请选择">
                  <a-select-option
                      v-for="item in jobTitleList"
                      :value="item.jobTitle">
                    {{item.jobTitle}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
            <!-------------------------------1005------------------------------------------->
            <div v-if="personnelListForm.type === '1005'">
              <a-form-model-item label="学院" >
                <a-select
                    show-search
                    option-filter-prop="children"
                    @popupScroll="popupScrollSchool"
                    @search="selectSchool"
                    :disabled="addPersonDisabled"
                    style="width: 300px"
                    v-model="personnelListForm.school"
                    placeholder="请选择">
                  <a-select-option
                      v-for="item in schoolListData"
                      :value="item.name">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="学历">
                <a-select :disabled="addPersonDisabled" style="width: 300px" placeholder="请选择" v-model="personnelListForm.education">
                  <a-select-option
                      v-for="item in educationList"
                      :value="item.education">
                    {{item.education}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="专业">
                <a-input :disabled="addPersonDisabled" style="width: 300px" v-model="personnelListForm.specialty" placeholder="请输入" />
              </a-form-model-item>
            </div>
            <!-------------------------------1006  ~  1007------------------------------------------->
            <div v-if="personnelListForm.type === '1006' || personnelListForm.type === '1007'">
              <a-form-model-item label="单位" >
                <a-input :disabled="addPersonDisabled" style="width: 300px" v-model="personnelListForm.workUnit" placeholder="请输入" />
              </a-form-model-item>
              <a-form-model-item label="职务" >
                <a-input :disabled="addPersonDisabled" style="width: 300px" v-model="personnelListForm.post" placeholder="请输入" />
              </a-form-model-item>
            </div>
            <!--      新增时共有的        -->
            <a-form-model-item label="性别">
              <a-select :disabled="addPersonDisabled" style="width: 300px" placeholder="请选择" v-model="personnelListForm.sex">
                <a-select-option
                    v-for="item in sexList"
                    :value="item.value">
                  {{item.sex}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="邮箱">
              <a-input :disabled="addPersonDisabled" style="width: 300px" v-model="personnelListForm.email" placeholder="请输入" />
            </a-form-model-item>
            <a-form-model-item label="生日">
              <a-date-picker :disabled="addPersonDisabled" placeholder="请选择" style="width: 300px" v-model="personnelListForm.birthday" />
            </a-form-model-item>
            <a-form-model-item label="认证状态">
              <!--            <a-input :disabled="true" style="width: 300px" v-model="personnelListForm.certStatus" placeholder="请输入" />-->
              <a-select
                  :disabled="true"
                  style="width: 300px"
                  v-model="personnelListForm.certStatus"
                  placeholder="请选择">
                <a-select-option
                    v-for="item in certStatus"
                    :value="item.value">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="注册渠道">
              <a-input :disabled="true" style="width: 300px;margin-bottom: 20px" v-model="personnelListForm.registerChannel" placeholder="请输入" />
            </a-form-model-item>
          </a-form-model>
          <div style="display: flex;margin-left: 320px">
            <a-button v-show="addPersonDisabled" @click="editPerBtn" type="primary">编辑</a-button>
            <div style="display: flex" v-show="!addPersonDisabled">
              <a-button @click="()=>{this.addPersonDisabled = true}">取消</a-button>
              <a-button @click="saveEditPer" type="primary" style="margin-left: 10px">保存</a-button>
            </div>
          </div>
          <div
              :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
          >
            <a-button :style="{ marginRight: '1px' }" @click="addPersonClose">取消</a-button>
            <a-button type="primary" @click="addPersonOnSubmit">确认</a-button>
          </div>
        </a-drawer>
      </a-spin>
      <AddExpertModel :expertForm="expertForm"  />
    </div>
  </template>

  <script>
  import AddExpertModel from "@/utils/AddExpertModel";
  import TcVod from 'vod-js-sdk-v6'
  import E from 'wangeditor'
  import moment from 'moment';
  import {live_link} from "../../../utils/liveLink";
  import {current_times,current_times_min} from '@/utils/current_time'
  import {live_room_link_case} from "../../../utils/liveLink";
  import {live_link_id} from "../../../utils/liveLink";
  import {
    batch_export_exal,
    batch_export_exal_register,
    CaseLiveFeature,
    CaseLiveFeatureOne,
    CaseLiveOneList,
    del_CalendarCalendar_AddressList,
    del_CalendarCalendar_timeList_listTable,
    del_CalendarCalendar_timeList_listTable_child,
    del_card,
    del_content_alphabetic_list,
    del_content_GraphicLinks_list,
    del_list_person,
    del_list_person_all,
    del_zcjz,
    delByAdvertising,
    Download_the_template_exal,
    EnterprisePosterMovement,
    function_upDown,
    get_CalendarCalendar_AddressList,
    get_CalendarCalendar_chairmanList,
    get_CalendarCalendar_timeList,
    get_CalendarCalendar_timeList_listTable,
    get_CalendarCalendar_timeList_listTable_child,
    get_content_alphabetic_list,
    get_content_ByAdvertising,
    get_content_GraphicLinks_list,
    get_credit_registration_list,
    get_credit_registration_lists,
    countLiveSumRecord,
    get_experts_xsxl,
    get_experts_xsxl_one,
    get_importExpertTemporaries_xsxl,
    get_per_OfThe_one_list,
    get_Playback_list,
    get_Tuition_fees_register_list,
    get_video_list,
    getEnterprisePosters,
    getLiveBroadcast,
    getLiveBroadcastLists,
    getOneDate,
    getPlaceList,
    updVodIsOpenDuration,
    importExpertTemporaries_xsxl,
    post_CalendarCalendar_timeList_listTable,
    post_CalendarCalendar_timeList_listTable_child,
    post_content_alphabetic_list,
    post_content_ByAdvertising,
    post_content_card,
    post_content_GraphicLinks_list,
    post_experts_xsxl,
    post_experts_xsxl_one,
    post_video_list,
    postEnterprisePosters,
    postPlaceList,
    put_CalendarCalendar_timeList_listTable,
    put_CalendarCalendar_timeList_listTable_child,
    put_content_ByAdvertising_upDown,
    put_content_GraphicLinks_move,
    put_content_picture,
    put_experts_xsxl_one,
    put_per_OfThe_one_list_ofNumber,
    put_Playback_list,
    put_RCYL_isOpen,
    putEnterprisePosters,
    putLiveBroadcastLists,
    putPlaceList,
    upDown,
    UpOrDown_AddressList,
    selDurationZD,
    putUpdXFZC,
    CaseLiveNew,
    corporatePoster,
    postCorporatePoster, putCorporatePoster, delCorporatePoster, postUploadFile, getImportExcelMemberTemplate, delMember
  } from "../../../service/MedicalConference";
  import {update, update_File} from "../../../utils/update";
  import axios from "axios";
  import Vue from "vue";
  import {postCaseLive} from "../../../service/live_api";
  import {putTitle} from "../../../service/liveMeet_api2";
  import {
    delSetLiveList, getCommunictionList,
    getLiveMeetList,
    getOneLiveList,
    getSetLiveList_feature,
    insCreditRecord
  } from "../../../service/liveMeet_api";
  import {geTypeList} from "../../../service/medicalConference_api";
  import {
    del_videoList,
    demand_playback_list_upDown,
    get_demand_playback_list,
    get_demand_playback_one_list,
    get_experts_id,
    get_signature,
    get_startEnd,
    get_videoLink,
    getMemberList,
    getVideoList,
    postMember,
    postVideoManage,
    put_video, putMember,
    putVideoManage
  } from "../../../service/MedicalConference_y";
  import {exportExcel} from "@/utils/excelConfig";
  import {educationList, sexDate} from "@/utils/dateReturn";
  import City from "@/assets/city.json";
  import {getHospital} from "@/service/hospital_api";
  import {getSchool} from "@/service/school_api";
  import {getTitle} from "@/service/dataDictionary_api";
  import {getLoginDepartment} from "@/service/dataCleaned_api";
  const content_CalendarCalendar_data = [];
  const content_CalendarCalendar_data_two = [];
  const columnsPlayback = [
    {
      title: '视频Id',
      dataIndex: 'id',
      width: '15%',
      scopedSlots: { customRender: 'id' },
    },
    {
      title: '标题',
      dataIndex: 'name',
      width: '25%',
      scopedSlots: { customRender: 'name' },
    },
    {
      title: '时长',
      dataIndex: 'duration',
      width: '25%',
      scopedSlots: { customRender: 'duration' },
    },
    {
      title: '操作',
      dataIndex: 'operation',
      scopedSlots: { customRender: 'operation' },
    },
  ];
  const PlaybackTableData =[];
  const plainOptions = ['游客'];
  const plainOption = ['保留大会直播界面'];
  export default {
    components: {AddExpertModel},
    data(){
      funcId:null,
      // this.cacheData = content_CalendarCalendar_data.map(item => ({ ...item }));
      this.cacheData = content_CalendarCalendar_data_two.map(item => ({ ...item }));
      return{
        current_times,
        radioStyle: {
          width:'110px',
          display: 'block',
          height: '35px',
          lineHeight: '35px',
          marginTop:'10px',
        },
        plainOptions,
        plainOption,
        videoList_id:'',
        file_id_list_rest:'',
        video_experts_list:[],
        experts_list_page_no:1,
        selectTitle_experts_list:'',
        expertIdNew:'',
        name_input:'',
        selectTitle:'',
        live_Room:[],
        expertROLE_id:'',
        case_cardId:'',
        farther_id_addressId:'',
        ruleOneOrTwo:true,
        content_addCard_visible:false,
        code_selectData:[],
        content_addCard_form:{
          conventionId:'',
          contentType:" ",
          funcCode:undefined,
          title:"",
          icon:"",
          intro:'',
         // 是否显示悬浮报名
          isShowApplyBar:0,
         // 是否需要付费
          isNeedPay:0,
         // 是否需要登录
          isNeedLogin:0
        },
        content_addCard_rules:{},
        content_addCard_rules1:{
          icon: [{ required: true, message: '请选择', trigger: 'blur' }],
          intro: [{ required: true, message: '请输入', trigger: 'blur' }],
          funcCode: [{ required: true, message: '请选择', trigger: 'change' }],
        },
        content_addCard_rules2:{
          funcCode: [{ required: true, message: '请选择', trigger: 'change' }],
        },


        ModifyLevel_id:'',
        farther_id:'',
        expertName_id:'',
        LiveBroadcast_list_page_no:1,
        HTML_id:'',
        linkVal:'',
        titleTop:'',
        btnShow:'',
        dataList:[],
        cacheDataTwo:[],
        spinning:false,
        labelCol_CalendarCalendar: { span:6},
        labelCol_CalendarCalendars: { span:8},
        labelCol: { span: 4},
        labelColImg: { span: 2},
        labelCol_addCard: { span: 8},
        labelCol_configuration: { span: 5},
        labelCol_LiveBroadcastCalendar_haiBao: { span: 6 },
        labelCol_LiveBroadcastCalendar: { span: 4},
        labelCol_video: { span: 3},
        wrapperCol: { span: 14 },
        card_list_content:[],
        //设置-----------------------------------------
        set_visible:false,
        payFees:0,
        set_form:{
          isNeedPay:0,
          isShowApplyBar:0,
          isNeedLogin:0,
        },
        //人数-------------------------------------------
        per_OfThe_visible:false,
        per_OfThe_input:'',
        //名单-学分注册-------------------------------------
        List_credit_registration_visible:false,
        ViewingDurationCount:null,
        ViewingDurationDate:[],
        ViewingDurationDates:[],
        List_credit_registration_columns: [
          {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            scopedSlots: { customRender: 'name' },
          },
          {
            title: '手机号',
            dataIndex: 'telephone',
            key: 'telephone',
            fixed: 'left',
            scopedSlots: { customRender: 'telephone' },
          },
          {
            title: '省份/城市',
            dataIndex: 'province',
            key: 'province',
            scopedSlots: { customRender: 'province' },
          },
          {
            title: '医院',
            dataIndex: 'hospitalName',
            key: 'hospitalName',
            scopedSlots: { customRender: 'hospitalName' },
          },
          {
            title: '科室',
            dataIndex: 'hospitalOffices',
            key: 'hospitalOffices',
            scopedSlots: { customRender: 'hospitalOffices' },
          },
          {
            title: '职称',
            dataIndex: 'jobTitle',
            key: 'jobTitle',
            scopedSlots: { customRender: 'jobTitle' },
          },
          {
            title: '注册时间',
            dataIndex: 'memberCreatedTime',
            key: 'memberCreatedTime',
            scopedSlots: { customRender: 'memberCreatedTime' },
          },
        ],
        List_credit_registration_data:[],
        List_credit_registration_count:null,
        List_credit_registration_page_no:'',
        List_credit_registration_telephone:'',
        List_credit_registration_name:'',
        //名单-注册缴费----------------------------------------
        Tuition_fees_register_visible:false,
        Tuition_fees_register_telephone:'',
        Tuition_fees_register_name:'',
        Tuition_fees_register_columns: [
          {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            scopedSlots: { customRender: 'name' },
          },
          {
            title: '手机号',
            dataIndex: 'mobilePhone',
            key: 'mobilePhone',
            scopedSlots: { customRender: 'mobilePhone' },
          },
          {
            title: '省份/城市',
            dataIndex: 'city',
            key: 'city',
            scopedSlots: { customRender: 'city' },
          },
          {
            title: '医院',
            dataIndex: 'hospitalName',
            key: 'hospitalName',
            scopedSlots: { customRender: 'hospitalName' },
          },
          {
            title: '科室',
            dataIndex: 'hospitalOffice',
            key: 'hospitalOffice',
            scopedSlots: { customRender: 'hospitalOffice' },
          },
          {
            title: '职称',
            dataIndex: 'jobTitle',
            key: 'jobTitle',
            scopedSlots: { customRender: 'jobTitle' },
          },
          {
            title: '导入时间',
            dataIndex: 'createdTime',
            key: 'createdTime',
            scopedSlots: { customRender: 'createdTime' },
          },
          {
            title: '操作',
            dataIndex: 'system',
            key: 'system',
            scopedSlots: { customRender: 'system' },
          },
        ],
        Tuition_fees_register_data:[],
        Tuition_fees_register_count:null,
        List_Tuition_fees_register_page_no:'',

        //内容——轮播广告---------------------------------
        content_ByAdvertising_visible:false,
        content_ByAdvertising_form:{
          displayUrl:'',
          targetUrl:'',
        },
        content_ByAdvertising_rules: {
          displayUrl: [{ required: true, message: '请选择', trigger: 'blur' }],
        },
        content_ByAdvertising_columns:[
          {
            title: '图片',
            dataIndex: 'displayUrl',
            key: 'displayUrl',
            width:'25%',
            scopedSlots: { customRender: 'displayUrl' },
          },
          {
            title: '链接',
            dataIndex: 'targetUrl',
            key: 'targetUrl',
            width:'45%',
            scopedSlots: { customRender: 'targetUrl' },
          },
          {
            title: '操作',
            dataIndex: 'system',
            key: 'system',
            width:'30%',
            scopedSlots: { customRender: 'system' },
          },
        ],
        content_ByAdvertising_data:[],
        //内容--字幕文字通知----------------------------
        content_alphabetic_visible:false,
        content_alphabetic_font:'',
        content_alphabetic_columns:[
          {
            title: '内容',
            dataIndex: 'content',
            key: 'content',
            width:'25%',
          },
          {
            title: '操作',
            dataIndex: 'system',
            key: 'system',
            width:'25%',
            scopedSlots: { customRender: 'system' },
          },
        ],
        content_alphabetic_data:[],
        //内容-图片展示----------------------------------
        picture_visible:false,
        picture_form:{
          picture:[],
        },
        picture_rules: {
          picture: [{ required: true, message: '请选择', trigger: 'blur' }],
        },
        //内容-PDF展示-----------------------------------
        content_pdf_visible:false,
       content_pdf_val:'',
        percentAge:0,
        content_pdf_name:'',
        //内容-图文链接展示--------------------------------
        content_GraphicLinks_visible:false,
        content_GraphicLinks_form:{
          targetUrl:'',
          title:'',
          displayUrl:'',
        },
        content_GraphicLinks_rules:{
          displayUrl: [{ required: true, message: '请上传', trigger: 'blur' }],
          title: [{ required: true, message: '请输入', trigger: 'blur' }],
          targetUrl: [{ required: true, message: '请输入', trigger: 'blur' }],
        },
        content_GraphicLinks_columns:[
          {
            title: '图片',
            dataIndex: 'displayUrl',
            key: 'displayUrl',
            width:'25%',
            scopedSlots: { customRender: 'displayUrl' },
          },
          {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
            width:'45%',
            scopedSlots: { customRender: 'titleData' },
          },
          {
            title: '操作',
            dataIndex: 'system',
            key: 'system',
            width:'30%',
            scopedSlots: { customRender: 'system' },
          },
        ],
        content_GraphicLinks_data:[],
        //内容-链接展示------------------------------------
        content_LinkShow_visible:false,
        content_LinkShow_dataVal:'',
        content_LinkShow_dataValIsTrue:false,
        //内容-直播日历展示---------------------------------
        LiveBroadcast_end:null,
        LiveBroadcast_start:null,

        visibleAdress:false,
        addressForm:{
          address1:"",
          address2:"",
          address3:"",
          address4:"",
          address5:"",
        },
        content_address_visible:false,
        content_LiveBroadcast_visible:false,
        content_address_address:"",
        content_LiveBroadcast_form:{
          url:'',
          title:'',
          dateStart:'',
          urlHai:'',
          dateVal:'',
          dateEnd:'',
        },
        content_LiveBroadcast_rules:{
          title: [{ required: true, message: '请输入', trigger: 'blur' }],
        },
        content_LiveBroadcast_columns:[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width:'5%',
          },{
            title: '日程名称',
            dataIndex: 'title',
            key: 'title',
            width:'13%',
            ellipsis: true,
          },
          {
            title: '日期',
            dataIndex: 'liveDate',
            key: 'liveDate',
            width:'10%',
          },
          {
            title: '直播时间',
            dataIndex: 'startTime',
            key: 'startTime',
            width:'13%',
            scopedSlots: { customRender: 'startTime' },
          },
          {
            title: '地址',
            scopedSlots: { customRender: 'address' },
          },
          {
            title: '时长',
            dataIndex: 'durationIsOpen',
            key: 'durationIsOpen',
            scopedSlots: { customRender: 'timeTime' },
          },
          {
            title: '回放',
            dataIndex: 'replayIsOpen',
            key: 'replayIsOpen',
            scopedSlots: { customRender: 'replayIsOpen' },
          },
          {
            title: '云展厅',
            dataIndex: 'isGallery',
            scopedSlots: { customRender: 'CloudHall' },
          },
          {
            title: '企业鸣谢',
            dataIndex: 'isEnterprise',
            scopedSlots: { customRender: 'EnterpriseZone' },
          },
          {
            title: '开场图',
            dataIndex: 'isBeginImage',
            scopedSlots: { customRender: 'openingFigure' },
          },
          {
            title: '操作',
            dataIndex: 'system',
            key: 'system',
            width:'10%',
            scopedSlots: { customRender: 'system' },
          },


        ],
        content_LiveBroadcast_data:[],
        content_LiveBroadcast_data_one:[],
        content_LiveBroadcast_data_total:0,
        picture_id:'',
        HYTZ_id:'',
        set_id:'',
        //时长
        content_RCYL_duration_visible:false,
        content_RCYL_duration_Bz:'',
        durationForm:{
          RCYL_duration_switch_isOpen_list:0,
          duration_remark:'',
        },
        RCYL_duration_switch_isOpen_id:'',
        //企业专区
        content_Enterprise_zone_visible:false,
        Enterprise_zoneForm:{
          isOpen_list:0,
          image:'',
        },
        RCYL_Enterprise_zone_isOpen_id:'',
        //开场图
        content_opening_figure_visible:false,
        opening_figureForm:{
          isOpen_list:0,
          image:"",
          duration:"",
        },
        content_opening_figure_rules:{
          isOpen_list: [{ required: true, message: '请选择', trigger: 'blur' }],
          image: [{ required: true, message: '请上传', trigger: 'blur' }],
          duration: [{ required: true, message: '请输入', trigger: 'blur' }],
        },
        opening_figure_id:'',
        //回放
        visiblePlayback:false,
        PlaybackTableData,
        PlaybackForm:{
          replay_is_open:0,
        },
        columnsPlayback,
        replay_is_open_switch_id:'',
        editingKey:'',
        content_PlaybackTableData_data_total:0,
        content_PlaybackTableData_data_page_no:0,
        content_PlaybackTableData_list_id:"",
        rest_visiblePlayback:false,
        content_rest_visiblePlayback_form:{
          title:'',
          desc_image_url:'',
          cover_url:'',
        },
        content_rest_visiblePlayback_rules:{
          desc_image_url: [{ required: true, message: '请上传', trigger: 'blur' }],
          cover_url: [{ required: true, message: '请上传', trigger: 'blur' }],
          title: [{ required: true, message: '请输入', trigger: 'blur' }],
        },
        content_LiveBroadcast_rest_Btn_id:'',



        //内容-日程日历展示---------------------------------------------
        time_tabs:'',
        selectIndex:null,
        fartherList:{},
        content_CalendarCalendar_visible:false,
        content_CalendarCalendar_form:{
          liveTime:null,
          start_time:'00:00:00',
          end_time:'00:00:00',
          // start_time:null,
          // end_time:null,
          scheduleName:'',
          address:undefined,
          //是否直播
          isLive:0,
          isCheck:[],
          //开启直播开关后选择直播间
          isLiveSelect:undefined,
          liveMeetingId:undefined,

          chairman:[],
          compere:[],
          honored:[],
          liveAddress:'',
        },
        liveUrl:'',
        memberIdUrl:'',
        roomId_CalendarCalendar:'',
        targetUrl_CalendarCalendar:'',
        liveMeetingId_CalendarCalendar:'',
        content_CalendarCalendar_rules:{
          liveTime: [{ required: true, message: '请输入', trigger: 'change' }],
          start_time: [{ required: true, message: '请输入', trigger: 'change' }],
          end_time: [{ required: true, message: '请输入', trigger: 'change' }],
          scheduleName: [{ required: true, message: '请输入', trigger: 'blur' }],
          address: [{ required: true, message: '请输入', trigger: 'change' }],
          isLive: [{ required: true, message: '请输入', trigger: 'blur' }],
        },
        open: false,
        open2: false,
        tab_pane_timeList:[],
        content_CalendarCalendar_timeList_data:[],
        firstTime:'',
        firstAdressId:'',
        CalendarCalendar_AddressList:[],
        CalendarCalendar_chairmanList:[],
        chairmanList_page_no:1,
        addVideoBtn_select_page_no:1,
        //内容-主持讲者展示---------------------------------------------
        btn_isShow:false,
        exel_list_id:'',
        LiveBroadcastCalendar_list_count:0,
        content_LiveBroadcastCalendar_list_visible:false,
        LiveBroadcastCalendar_list_top_name:"",
        LiveBroadcastCalendar_list_page_no:1,
        LiveBroadcastCalendar_list_page_size:10,
        LiveBroadcastCalendar_list_columns:[
          {
            title: '头像',
            dataIndex: 'headimg',
            key: 'headimg',
            scopedSlots: { customRender: 'headimg' },
          },
          {
            title: '作者',
            dataIndex: 'name',
          },
          {
            title: '医院',
            dataIndex: 'hospital',
            key: 'hospital',
          },
          {
            title: '科室',
            key: 'departmentCode',
            dataIndex: 'departmentCode',
          },
          {
            title: '职称',
            dataIndex: 'title',
            key: 'title',
          },{
            title: '操作',
            key: 'system',
            scopedSlots: { customRender: 'system' },
          },
        ],
        LiveBroadcastCalendar_list_data:[],
        fileFormData:[],
        content_LiveBroadcastCalendar_file:'',
        content_LiveBroadcastCalendar_visible:false,
        content_LiveBroadcastCalendar_form:{
          intro:'',
          departmentCode:'',
          name:'',
          hospital:'',
          title:'',
          headimg:'',
        },
        content_LiveBroadcastCalendar_rules:{
          intro: [{ required: true, message: '请输入', trigger: 'blur' }],
          // departmentCode: [{ required: true, message: '请输入', trigger: 'blur' }],
          name: [{ required: true, message: '请输入', trigger: 'blur' }],
          hospital: [{ required: true, message: '请输入', trigger: 'blur' }],
          title: [{ required: true, message: '请输入', trigger: 'blur' }],
          headimg: [{ required: true, message: '请上传', trigger: 'blur' }],
        },
        LiveBroadcastCalendar_columns:[
          {
            title: '作者',
            dataIndex: 'name',
            key: 'name',
            scopedSlots: { customRender: 'name' },
          },
          {
            title: '医院',
            dataIndex: 'hospital',
            key: 'hospital',
          },
          {
            title: '操作',
            key: 'action',
            scopedSlots: { customRender: 'action' },
          },
        ],
        LiveBroadcastCalendar_data:[],
        LiveBroadcastCalendar_columns_right:[
          {
            title: '作者',
            dataIndex: 'name',
            key: 'name',
            scopedSlots: { customRender: 'name' },
          },
          {
            title: '头像',
            dataIndex: 'headimg',
            key: 'headimg',
            scopedSlots: { customRender: 'headimg' },
          },
          {
            title: '医院',
            dataIndex: 'hospital',
            key: 'hospital',
          },
          {
            title: '操作',
            key: 'action',
            scopedSlots: { customRender: 'action' },
          },
        ],
        LiveBroadcastCalendar_data_right:[],
        LiveBroadcastCalendar_pagination_total:0,
        LiveBroadcastCalendar_pagination_page_no:1,
        LiveBroadcastCalendar_pagination_page_size:10,

        LiveBroadcastCalendar_pagination_total_right:0,
        LiveBroadcastCalendar_pagination_right_page_no:1,
        LiveBroadcastCalendar_pagination_right_page_size:10,
        LiveBroadcastCalendar_pagination_onSubmit_id:'',
        LiveBroadcastCalendar_pagination_onSubmit_name:'',

        content_CalendarCalendar_columns:[
          {
            title: '日程名称',
            dataIndex: 'title',
            key: 'title',
            scopedSlots: { customRender: 'scheduleName' },
          },
          {
            title: '时间',
            dataIndex: 'beginTime',
            key: 'beginTime',
            scopedSlots: { customRender: 'time' },
          },
          // {
          //   title: '二级角色',
          //   dataIndex: '',
          //   scopedSlots: { customRender: 'role' },
          // },
          // {
          //   title: '二级主持讲者',
          //   dataIndex: 'expertName',
          //   scopedSlots: { customRender: 'compere' },
          // },
          {
            title: '操作',
            dataIndex: 'system',
            key: 'system',
            scopedSlots: { customRender: 'system' },
          },
        ],
        content_CalendarCalendar_columns_two:[
          {
            title: '日程名称',
            dataIndex: 'name',
            key: 'name',
            scopedSlots: { customRender: 'name' },
          },
          {
            title: '时间',
            dataIndex: 'newtime',
            key: 'newtime',
            scopedSlots: { customRender: 'newtime' },
          },
          {
            title: '角色',
            dataIndex: 'role',
            scopedSlots: { customRender: 'role' },
          },
          {
            title: '主持讲者',
            dataIndex: 'expertName',
            scopedSlots: { customRender: 'expertName' },
          },
          {
            title: '操作',
            dataIndex: 'option',
            key: 'option',
            width:'15%',
            scopedSlots: { customRender: 'option' },
          },
        ],
        content_CalendarCalendar_data,
        content_CalendarCalendar_data_two,
        cacheData:[],
        startTime:'',
        endTime:'',
        selectRole:[
          {
            id:'1',
            title:'主席',
            name:'CHAIRMAN'
          },
          {
            id:'2',
            title:'主持',
            name:'COMPERE'
          },
          {
            id:'3',
            title:'嘉宾',
            name:'GUEST'
          },
          {
            id:'4',
            title:'讲者',
            name:'SPEAKER'
          },
        ],

      //  企业海报展示------------------------------------------------------------------------------
        content_EnterprisePosters_visible:false,
        content_EnterprisePosters_list_visible:false,
        enterprisePosters:false,
        meetPageNo:1,
        meettitle:'',
        meetList:[],
        meetYear:null,
        enterprisePosters_meetingId:undefined,
        content_LiveBroadcastCalendar_visible_model:false,
        content_EnterprisePosters_form:{
          scheduleId:'',
          scheduleName:undefined,
          livePendingImage:'',
          liveEndImage:'',
          conventionId:'',
          thumbnail:'',
          original:'',
          meetDate:'',
          enterpriseName:'',
        },
        content_EnterprisePosters_rules:{
          thumbnail: [{ required: true, message: '请上传', trigger: 'blur' }],
          original: [{ required: true, message: '请上传', trigger: 'blur' }],
          livePendingImage: [{ required: true, message: '请上传', trigger: 'blur' }],
          liveEndImage: [{ required: true, message: '请上传', trigger: 'blur' }],
          meetDate: [{ required: true, message: '请选择', trigger: 'change' }],
          scheduleId: [{ required: true, message: '请选择', trigger: 'change' }],
          enterpriseName: [{ required: true, message: '请输入', trigger: 'change' }],
        },
        EnterprisePosters_list_columns:[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
          },
          {
            title: '缩略图',
            dataIndex: 'thumbnail',
            key: 'thumbnail',
            scopedSlots: { customRender: 'thumbnail' },
          },
          {
            title: '企业名称',
            dataIndex: 'enterpriseName',
            key: 'enterpriseName',
          },
          {
            title: '创建日期',
            key: 'createdTime',
            dataIndex: 'createdTime',
            scopedSlots: { customRender: 'createdTime' },
          },
          {
            title: '操作',
            key: 'action',
            scopedSlots: { customRender: 'action' },
          },
        ],
        EnterprisePosters_list_data:[],
        EnterprisePosters_relevance:[],
        cardId:'',
        EnterprisePosters_pagination_page_no:1,
        EnterprisePosters_pagination_total:0,
        EnterprisePosters_pagination_page_size:10,
        List_Corporate_posters_id:'',
        EnterprisePostersData:[],
        EnterprisePostersColumns:[
          {
            title: '缩略图',
            dataIndex: 'descImageUrl',
            key: 'descImageUrl',
            scopedSlots: { customRender: 'descImageUrl' },
          },{
            title: '企业名称',
            dataIndex: 'title',
            key: 'title',
            // scopedSlots: { customRender: 'title' },
          },{
            title: '操作',
            scopedSlots: { customRender: 'action' },
          },
        ],
      //  内容-点播视频展示----------------------------------------------------------------------
        content_video_demand_visible:false,
        content_video_demand_visible_upVideo:false,
        content_video_demand_form:{
          surface_plot:'',
          video:'',
          title:'',
          specialist:undefined,
          titleStart:undefined,
          titleEnd:undefined,
          include:'',
        },
        content_video_demand_rules:{
          surface_plot: [{ required: true, message: '请上传', trigger: 'blur' }],
          video: [{ required: true, message: '请上传', trigger: 'blur' }],
          specialist: [{ required: true, message: '请选择', trigger: 'blur' }],
          include: [{ required: true, message: '请输入', trigger: 'blur' }],
          title: [{ required: true, message: '请输入', trigger: 'blur' }],
          // titleStart: [{ required: true, message: '请选择', trigger: 'blur' }],
          // titleEnd: [{ required: true, message: '请选择', trigger: 'blur' }],
        },
        editor:null,
        test:null,
        editor_menus:[
          'head', // 标题
          'bold', // 粗体
          'fontSize', // 字号
          'fontName', // 字体
          'italic', // 斜体
          'underline', // 下划线
          'strikeThrough', // 删除线
          'foreColor', // 文字颜色
          'backColor', // 背景颜色
          'link', // 插入链接
          'list', // 列表
          'justify', // 对齐方式
          'quote', // 引用
          'emoticon', // 表情
          'image', // 插入图片
          'table', // 表格
          'video', // 插入视频
          'code', // 插入代码
          'undo', // 撤销
          'redo', // 重复
          'fullscreen' // 全屏
        ],
        video_demand_columns:[
          {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
            ellipsis: true,
          },
          {
            title: '专家',
            dataIndex: 'name',
            key: 'name',
            scopedSlots: { customRender: 'name' },
          },
          {
            title: '时长',
            dataIndex: 'duration',
            key: 'duration',
            scopedSlots: { customRender: 'duration' },
            // width:'15%',
            // ellipsis: true,
          },
          {
            title: '上传时间',
            dataIndex: 'created_time',
            key: 'created_time',
            width:'15%',
            ellipsis: true,
          },{
            title: '上传人',
            dataIndex: 'person',
            key: 'person',
            // width:'15%',
            // ellipsis: true,
          },
          {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width:'15%',
            scopedSlots: { customRender: 'action' },
          }
        ],
        video_demand_data:[],
        video_demand_pagination_page_no:1,
        video_demand_pagination_total:0,
        //上传视频
        fileId: '', // 文件id
        vFile: {}, // 视频文件File对象
        vIsFileExist: false, // File对象是否存在
        vUploader: {}, // 腾讯云上传的初始化对象
        signature:'',
        yunVideo_fileId:'',
        //地点-日程日历展示------------------------------------------------------------------
        content_place_visible:false,
        place_list_columns:[
          {
            title: '地点名称',
            dataIndex: 'address',
            key: 'address',
          },
          {
            title: '会议机动人员',
            dataIndex: 'mobileName',
            key: 'mobileName',
          },
          {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            scopedSlots: { customRender: 'action' },
          }
        ],
        place_list_data:[],
        content_place_modal_visible:false,
        content_place_modal_address:'',
        mobileId:undefined,
        place_list_rest_id:'',
        place_list_routerId:'',
        dataListOne:[],
        addIsShow:false,
        videoList:[],
        videoListId:'',
        startList:[],
        endList:[],
      //  大会直播
        conferenceLiveBroadcastVisible:false,
        conferenceLiveBroadcast:[],
        conferenceLiveBroadcast_pageNo:1,
        conferenceLiveBroadcast_title:'',
        LiveBroadcastForm:{
          conferenceLiveBroadcastValue:undefined,
          isCheck:[],
        },
        LiveBroadcastRules:{
          conferenceLiveBroadcastValue: [{ required: true, message: '请选择', trigger: 'change' }],
        },

        timeOpen_visible:false,

        timeOpenList:null,

        timeOpenIsOpen:true,

        durationRemark:'',

      // //  直播链接
      //   inputLiveUrl_visible:false,
      //   inputLiveUrl:'',

      //  学分配置
        configuration_visible:false,
        listId:null,
        configuration_form:{
          remark:'',
          credit:null,
          limitEnrollCount:0,
        },
        configurationRules:{
          remark: [{ required: true, message: '请输入', trigger: 'blur' }],
          credit: [{ required: true, message: '请输入', trigger: 'blur' }],
          limitEnrollCount: [{ required: true, message: '请输入', trigger: 'blur' },{
            pattern: /^-?\d+$/,
            message: "请输入整数",
            trigger: "blur",
          },],
        },
        radioGroup:'voluntarily',
        indicator: <a-icon type="loading" style="font-size: 18px;color:#45a5e6" spin />,
        handMovementLoading:false,

        expertForm:{
          formShow:false,
          formType:undefined,
        },
        liveMeetId:null,

        //人员名单
        ExcelMember:false,
        changeFile:'',
        listTel:null,
        columnsList:[
          {
            title: '序号',
            scopedSlots: { customRender: 'serialNumber' },
          }, {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: '手机号',
            dataIndex: 'telephone',
            key: 'telephone',
          },
          {
            title: '身份',
            dataIndex: 'type',
            key: 'type',
            scopedSlots: { customRender: 'type' },
          },
          {
            title: '医院',
            dataIndex: 'hospitalName',
            key: 'hospitalName',
          },
          {
            title: '科室',
            dataIndex: 'hospitalOffices',
            key: 'hospitalOffices',
          },
          {
            title: '职称',
            dataIndex: 'jobTitle',
            key: 'jobTitle',
          },
          {
            title: '导入时间',
            dataIndex: 'createdTime',
            key: 'createdTime',
            scopedSlots: { customRender: 'createdTime' },
          },{
            title: '操作',
            scopedSlots: { customRender: 'action' },
          },],
        dataLists:[],
        listCount:0,
        listPageNo:1,
        listpageSize:10,

        addPersonVisile:false,
        personnelListForm:{
          type:'1001',
          province:undefined,
          hospitalName:undefined,
          school:undefined,
          certStatus:"UNCERTIFIED",
          registerChannel:"后台添加",
        },
        personnelListRules:{
          tel:[
            {required: true, message: '请输入', trigger: 'blur'},
            { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
          ],
          name:[{required: true, message: '请输入', trigger: 'blur'}],
        },

        sexList:sexDate(),
        educationList:educationList(),
        City:City,
        jobTitleList:[],
        provincePtions:[],

        hosNamePageNo:1,
        hosName:'',
        hospitalNameList:[], //医院列表
        certStatus:[
          {
            value:'UNCERTIFIED',
            name:'未认证'
          },{
            value:'IN_REVIEW',
            name:'审核中'
          },
          {
            value:'CERTIFIED',
            name:'已认证'
          }
        ],
        schoolNamePageNo:1,
        schoolName:'',
        schoolListData:[], //学校列表

        hospitalOfficesList:[],
        PerList:[],
        addPersonDisabled:true,//初始状态下默认为不能编辑状态
      }
    },
    created() {
      this.getFeatureList()
      this.getCaseOneList()
      this.getPCH()
      this.getPerList()
      this.getHospitalOfficesList()
      this.getJobTitleList(this.personnelListForm.type)
    },
    computed: {
      newArr : function() {
        return this.content_addCard_form.title
      },
    },

    watch:{
      newArr(newVal){
        this.content_addCard_form.intro = newVal
      },
    },
    methods:{
      async getPerList(departmentById) {
        const response = await getCommunictionList(1,9999,1,'',departmentById)
        if(response.code === 0){
          this.PerList = response.data.rows
        }
      },
      async getLogData(name) {
        if(name){
          this.LiveBroadcastCalendar_pagination_onSubmit_name = name
        }
        let data = {
          name:this.LiveBroadcastCalendar_pagination_onSubmit_name,
          page_no:this.LiveBroadcastCalendar_pagination_right_page_no,
          conventionId:this.$route.query.id
        }
        // const response = await get_experts_xsxl(name,page_no,page_size,this.$route.query.id)
        const response = await get_experts_xsxl(data)
        if (response.code === 0) {
          this.LiveBroadcastCalendar_data_right = response.data.rows
          this.LiveBroadcastCalendar_pagination_total_right = response.data.count
        } else {
          this.$message.warning(response.message)
        }
      },
      radioGroupChange(value){
        let radio = value.target.value
      //  计算观看数据
        this.get_List_credit_registration_list(this.List_credit_registration_page_no)
      },
      //名单-学分注册  计算观看数据
      async ViewingData() {
        if(!this.handMovementLoading){
          this.handMovementLoading = true
          let data = {
            "conventionId":this.$route.query.id,
            //"手动传handMovement    自动传voluntarily"
            "type":this.radioGroup
          }
          const response = await countLiveSumRecord(data)
          if(response.code === 200){
            this.$message.success("计算成功~")
            //  获取数据
            await this.get_List_credit_registration_list(this.List_credit_registration_page_no)
            this.handMovementLoading = false
          }else {
            this.$message.warning(response.message)
          }
        }
      },
      moment,
      //不可选的日期
      disabledDate(current){
        let fartherLive = JSON.parse(localStorage.getItem('convertionImg'))
        return current < moment(fartherLive.beginDate).startOf('day') || current > moment(fartherLive.endDate).endOf('day');
      },

  //获取不可选择的小时
      disabledStartHours(){
        if(this.content_CalendarCalendar_form.end_time){
          let hours = [];
          let hour = Number.parseInt(this.content_CalendarCalendar_form.end_time.substring(0, 2));
          for (let i = hour+1; i < 24; i++) {
            hours.push(i);
          }
          return hours
        }
      },
  //获取不可选择的分钟
  disabledStartMinutes(selectedHour){
    if(this.content_CalendarCalendar_form.end_time){
      let minutes = [];
      let hour = Number.parseInt(this.content_CalendarCalendar_form.end_time.substring(0, 2));
      let minute = Number.parseInt(this.content_CalendarCalendar_form.end_time.substring(3, 5));
      if(selectedHour>=hour){
        for (let i = minute+1; i < 60; i++) {
          minutes.push(i);
        }
      }
      return minutes
    }
  },
  // 获取不可选择的秒
  disabledStartSeconds(selectedHour, selectedMinute){
    if(this.content_CalendarCalendar_form.end_time){
      let seconds = [];
      let hour = Number.parseInt(this.content_CalendarCalendar_form.end_time.substring(0, 2));
      let minute = Number.parseInt(this.content_CalendarCalendar_form.end_time.substring(3, 5));
      let second = Number.parseInt(this.content_CalendarCalendar_form.end_time.slice(-2));
      if(selectedHour >= hour && selectedMinute >= minute){
        for (let i = second+1; i < 60; i++) {
          seconds.push(i);
        }
      }
      return seconds
    }
  },
  //获取不可选择的小时
  disabledEndHours(){
    if(this.content_CalendarCalendar_form.start_time){
      let hours = [];
      let hour = Number.parseInt(this.content_CalendarCalendar_form.start_time.substring(0, 2));
      for (let i = 0; i < hour; i++) {
        hours.push(i);
      }
      return hours
    }
  },
  //获取不可选择的分钟
  disabledEndMinutes(selectedHour){
    if(this.content_CalendarCalendar_form.start_time){
      let minutes = [];
      let hour = Number.parseInt(this.content_CalendarCalendar_form.start_time.substring(0, 2));
      let minute = Number.parseInt(this.content_CalendarCalendar_form.start_time.substring(3,5));
      if(selectedHour<=hour){
        for (let i = 0; i < minute; i++) {
          minutes.push(i);
        }
      }
      return minutes
    }
  },
  //获取不可选择的秒
  disabledEndSeconds(selectedHour, selectedMinute){
    if(this.content_CalendarCalendar_form.start_time){
      let seconds = [];
      let hour = Number.parseInt(this.content_CalendarCalendar_form.start_time.substring(0, 2));
      let minute = Number.parseInt(this.content_CalendarCalendar_form.start_time.substring(3,5));
      let second = Number.parseInt(this.content_CalendarCalendar_form.start_time.slice(-2));
      if(selectedHour <= hour && selectedMinute <= minute){
        for (let i = 0; i < second; i++) {
          seconds.push(i);
        }
      }
      return seconds
    }
  },


      //新增
      addCard(){
        this.content_addCard_form.conventionId = this.$route.query.id
        this.content_addCard_rules = this.content_addCard_rules1
        this.ruleOneOrTwo = true
        this.content_addCard_visible = true
        this.get_caseLive_typeList()
      },
      restBtn(row){
        this.get_caseLive_typeList()
        if(row.funcCode === 'LBGG' || row.funcCode === 'ZMTZ'){
          //选择轮播广告和文字字幕时，无需填写其他内容（其他内容隐藏）
          this.content_addCard_rules = this.content_addCard_rules2
          this.ruleOneOrTwo = false
        }else {
          this.content_addCard_rules = this.content_addCard_rules1
          this.ruleOneOrTwo = true
        }
        this.content_addCard_visible = true
        this.case_cardId = row.id
        let form = this.content_addCard_form
        form.title = row.title
        form.icon = row.icon
        form.funcCode = row.funcCode
        form.intro = row.intro

      },
      //获取会议列表类型列表
      async get_caseLive_typeList() {
        const response = await geTypeList()
        if(response.code === 0){
          this.code_selectData = response.data.types
        }else {
          this.$message.warning("失败"+response.message)
        }
      },
      content_addCard_form_handleChange(funcCode){
        if(funcCode === 'LBGG' || funcCode === 'ZMTZ'){
          //选择轮播广告和文字字幕时，无需填写其他内容（其他内容隐藏）
          this.content_addCard_rules = this.content_addCard_rules2
          this.ruleOneOrTwo = false
        }else {
          this.content_addCard_rules = this.content_addCard_rules1
          this.ruleOneOrTwo = true
        }
        this.content_addCard_form.funcCode = funcCode
      },
      async content_add_image() {
        let inputDOM = this.$refs.detailCoverUrl_addICON.files[0];
        //判断图片大小
        if (inputDOM.size / 1024 / 1024 < 1) {
          const image = await update(inputDOM)
          if (image.code === 0) {
            this.content_addCard_form.icon = image.data.url
            this.$message.success("上传成功")

          } else {
            this.$message.warning("上传失败")
          }
        } else {
          this.$message.warning("请上传小于1M的图片！")
        }
        document.getElementById('uploadFile_add').value = null;
      },
      addSubmit(){
        this.$refs.content_add_ruleForm.validate(async valid => {
          if (valid) {
            let form = this.content_addCard_form
            if(form.conventionId !== ''){
              //新增
              const response = await post_content_card(form)
              if (response.code === 0) {
                this.$message.success("新增成功！")
                //  关闭
                this.content_addCard_visible = false
                //  刷新列表
                await this.getFeatureList()
              } else {
                this.$message.warning("失败"+response.message)
              }
            }else {
            //  修改
              const response = await put_content_picture(this.case_cardId,form)
              if (response.code === 0) {
                this.$message.success("修改成功！")
                //  关闭
                this.content_addCard_visible = false
                //  刷新列表
                await this.getFeatureList()
              } else {
                this.$message.warning("失败"+response.message)
              }
            }
            //  清空
            this.closeAddOrRest()
          } else {
            return false;
          }
        });
      },
      closeAddOrRest(){
        this.content_addCard_visible = false;
        this.$refs.content_add_ruleForm.resetFields();
        this.content_addCard_form.title = ''
        this.content_addCard_form.conventionId = ''
        this.content_addCard_form.funcCode = undefined
        this.content_addCard_form.icon = ''
        this.content_addCard_form.intro = ''
      },
      //获取医学会议标题和地址
      async getCaseOneList() {
        const response = await CaseLiveOneList(this.$route.query.id)
        if(response.code === 0){
          this.titleTop = response.data.title
          this.linkVal = response.data.tempUrl
        }else {
          this.$message.warning("失败!"+response.message)
        }
      },
      //获取列表
      async getFeatureList() {
        this.spinning = true
        const response = await CaseLiveFeature(this.$route.query.id)
        if(response.code === 0){
          this.dataList = response.data
        }
        this.spinning = false
      },
      //移动公用
      async movement(id,data) {
        let action = data
        const response = await function_upDown(id,action)
        if (response.code === 0) {
          this.$message.success("操作成功！")
        //  刷新列表
          await this.getFeatureList()
        } else {
          this.$message.warning("失败!" + response.message)
        }
      },
      //上移
      moveUp(id){
        let action = 'up'
        this.movement(id,action)
      },
      //下移
      shiftDown(id){
        let action = 'down'
        this.movement(id,action)
      },
      //获取单个功能列表内容
      async getFeatureListOne(id) {
        this.spinning = true
        const response = await CaseLiveFeatureOne(id)
        if(response.code === 0){
          this.dataListOne = response.data
        }else {
          this.$message.warning("失败!"+response.message)
        }
        this.spinning = false
      },
      async functionModuleSwitch(row,isOpen) {
        let data = {
          "isPublished":isOpen
        }
        const response = await put_content_picture(row.id,data)
        if (response.code === 0) {
        //  刷新列表
          await this.getFeatureList()
        } else {
          this.$message.warning("失败!" + response.message)
        }
      },
      //删除会议功能
      async delCard(row) {
        const response = await del_card(row.id)
        if (response.code === 0) {
          this.$message.success("删除成功！")
          //  刷新列表
          await this.getFeatureList()
        } else {
          this.$message.warning("失败!" + response.message)
        }
      },
      //设置----------------------------------------------------------------------
      async set_sum_api(data) {
        const response = await put_content_picture(this.set_id,data)
        if (response.code === 0) {
          this.$message.success("操作成功")
        //  刷新列表
          await this.getFeatureList()
        } else {
          this.$message.warning("内容-轮播广告获取失败"+response.message)
        }
      },
      set_payFees_Btn(isNeedPay) {
        this.set_form.isNeedPay = isNeedPay
        let data = {
          "isNeedPay":isNeedPay
        }
        this.set_sum_api(data)
      },
      set_Suspended_Btn(isShowApplyBar) {
        this.set_form.isShowApplyBar = isShowApplyBar
        let data = {
          "isShowApplyBar":isShowApplyBar
        }
        this.set_sum_api(data)
      },
      set_login_Btn(isNeedLogin) {
        this.set_form.isNeedLogin = isNeedLogin
        let data = {
          "isNeedLogin":isNeedLogin
        }
        this.set_sum_api(data)
      },
      //内容——轮播广告上传图片-------------------------------------------------------
      async content_ByAdvertising_image() {
        let inputDOM = this.$refs.detailCoverUrl.files[0];
        //判断图片大小
        if (inputDOM.size < 2097152) {
          const image = await update(inputDOM)
          if (image.code === 0) {
            this.content_ByAdvertising_form.displayUrl = image.data.url
            this.$message.success("上传成功")

          } else {
            this.$message.warning("上传失败")
          }
        } else {
          this.$message.warning("请上传小于2M的图片！")
        }
        document.getElementById('upload_lunbo').value = null;
      },
      async content_ByAdvertising_submit() {
        this.$refs.content_ByAdvertising_ruleForm_lunbo.validate(async valid => {
          if (valid) {
        let form = this.content_ByAdvertising_form
        let data = {
          "conventionId": this.$route.query.id,
          "displayUrl": form.displayUrl,
          "targetUrl": form.targetUrl
        }
        const response = await post_content_ByAdvertising(data)
        if (response.code === 0) {
          //  刷新列表
          await this.getLBGGList()
          //清空表单
          this.$refs.content_ByAdvertising_ruleForm_lunbo.resetFields();
          form.targetUrl = ''
        } else {
          this.$message.warning("失败"+ response.message)
        }
        } else {
            this.$message.warning("有空~~")
          return false;
        }
        });
      },
      async content_ByAdvertising_data_delBtn(id) {
        const response = await delByAdvertising(id)
        if (response.code === 0) {
          this.$message.success("删除成功！")
          await this.getLBGGList()
        } else {
          this.$message.warning("失败"+response.message)
        }
      },
      //公用上移下移
      async content_ByAdvertising_upDown(id,action) {
        const response = await put_content_ByAdvertising_upDown(id,action)
        if (response.code === 0) {
          //刷新列表
          await this.getLBGGList()
        } else {
          this.$message.warning("失败!"+response.message)
        }
      },
      content_ByAdvertising_up(id){
        let action = 'up'
        this.content_ByAdvertising_upDown(id,action)
      },
      content_ByAdvertising_down(id){
        let action = 'down'
        this.content_ByAdvertising_upDown(id,action)
      },

      //内容-字幕文字通知---------------------------------------------------------------
      async content_alphabetic_delBtn(id) {
        const response = await del_content_alphabetic_list(id)
        if (response.code === 0) {
          this.$message.success("删除成功！")
          //  刷新
          await this.getZMTZList()
        } else {
          this.$message.warning("失败"+ response.message)
        }
      },
      async content_alphabetic_submit() {
        if (this.content_alphabetic_font !== '') {
          let data = {
            "conventionId":this.$route.query.id,
            "icon":"https://yunicu-1252497858.cos.ap-guangzhou.myqcloud.com/images/yunicu/1636956351919_1628996234437_%E5%AD%97%E5%B9%95%E9%80%9A%E7%9F%A5.png",
            "content":this.content_alphabetic_font
          }
          const response = await post_content_alphabetic_list(data)
          if (response.code === 0) {
            this.$message.success("添加成功")
          //  刷新
            await this.getZMTZList()
            this.content_alphabetic_font = ''
          } else {
            this.$message.warning("失败"+response.message)
          }
        } else {
          this.$message.warning("有空")
        }
      },


      //内容-图片展示-------------------------------------------------------------------
      async picture_image() {
        let inputDOM = this.$refs.detailCoverUrl_hyc.files[0];
        //判断图片大小
        if (inputDOM.size / 1024 /1024 < 1) {
          const image = await update(inputDOM)
          if (image.code === 0) {
            this.picture_form.picture.push(image.data.url)
            this.$message.success("上传成功")
          } else {
            this.$message.warning("上传失败")
          }
        } else {
          this.$message.warning("请上传小于1M的图片！")
        }
        document.getElementById('upload_HYC').value = null;
      },
      async pictureTrueBtn() {
        let data = {
          "content":{
            "value": this.picture_form.picture
          },
        }
        const response = await put_content_picture(this.picture_id,data)
        if (response.code === 0) {
          this.$message.success("操作成功")
          //清空
          this.picture_form.picture = []
          this.picture_visible = false;
        } else {
          this.$message.warning("失败"+ response.message)
        }
      },

      //内容-PDF展示--------------------------------------------------------------
      async content_pdf() {
        let inputDOM = this.$refs.content_pdfUrl.files[0];
          const fileVal = await update_File(inputDOM)
          if (fileVal.code === 0) {
            this.content_pdf_name = fileVal.data.url
            this.content_pdf_val = fileVal.data.url
            this.$message.success("上传成功")
            this.percentAge = 100
          } else {
            this.$message.warning("上传失败")
          }
        document.getElementById('content_pdfUrl').value = null;
      },
      async content_pdf_Ok() {

        let data = {
          "content": {
            "value": this.content_pdf_val
          },
        }
        const response = await put_content_picture(this.HYTZ_id, data)
        if (response.code === 0) {
          this.$message.success("操作成功")
          //清空
          this.content_pdf_visible = false
          //  清空文件名和文件进度条
          this.content_pdf_name = ''
          this.percentAge = 0
        } else {
          this.$message.warning("失败"+response.message)
        }

      },

      //内容-图文链接展示-----------------------------------------------------------------
      async content_GraphicLinks_image() {
        let inputDOM = this.$refs.content_GraphicLinksUrl.files[0];
        //判断图片大小
        if (inputDOM.size < 512000) {
          const image = await update(inputDOM)
          if (image.code === 0) {
            this.content_GraphicLinks_form.displayUrl = image.data.url
            this.$message.success("上传成功")

          } else {
            this.$message.warning("上传失败")
          }
        } else {
          this.$message.warning("请上传小于500KB的图片！")
        }
        document.getElementById('picture_image_linkImg').value = null;
      },
      content_GraphicLinks_submit(){
        this.$refs.content_GraphicLinks_ruleForm.validate(async valid => {
          if (valid) {
            let form = this.content_GraphicLinks_form
            let data = {
              "title": form.title,
              "conventionId": this.$route.query.id,
              "displayUrl": form.displayUrl,
              "targetUrl": form.targetUrl,
              funcId:this.funcId
            }
            const response = await post_content_GraphicLinks_list(data)
            if(response.code === 0){
              this.$message.success('添加成功！')
              await this.getYZTList()
            //  清空
              this.$refs.content_GraphicLinks_ruleForm.resetFields();
              this.content_GraphicLinks_form.displayUrl = ''
            }else {
              this.$message.warning('失败',response.message)
            }
          } else {
            this.$message.warning("有空~~")
            return false;
          }
        });
      },
      async content_GraphicLinks_data_delBtn(id) {
        const response = await del_content_GraphicLinks_list(id)
        if (response.code === 0) {
          this.$message.success('删除成功！')
          await this.getYZTList()
        } else {
          this.$message.warning('失败', response.message)
        }
      },
      //上移下移公用
      async content_GraphicLinks_upDown(id,action) {
        const response = await put_content_GraphicLinks_move(id,action)
        if (response.code === 0) {
          await this.getYZTList()
        } else {
          this.$message.warning('失败', response.message)
        }
      },
      //上移下移
      content_GraphicLinks_up(id){
        let action = 'up'
        this.content_GraphicLinks_upDown(id,action)
      },
      content_GraphicLinks_down(id){
        let action = 'down'
        this.content_GraphicLinks_upDown(id,action)
      },

      //内容-链接展示--------------------------------------------------------------------
      async content_LinkShow_Ok() {
        if(this.content_LinkShow_dataVal){
          this.content_LinkShow_dataValIsTrue = true
          //判断url有没有？，没有就拼一下
          let data = {
            "url":this.content_LinkShow_dataVal
          }
          const response = await put_content_picture(this.HTML_id, data)
          if (response.code === 0) {
            this.$message.success("操作成功")
            //清空
            this.content_LinkShow_dataVal = ''
            this.content_LinkShow_visible = false
          } else {
            this.$message.warning( response.message)
          }
        }else {
          this.content_LinkShow_dataValIsTrue = true
        }
      },

      //内容-直播日历展示-----------------------------------------------------------------
      content_LiveBroadcast_startChange(time, timeString){
        this.content_LiveBroadcast_form.dateStart = timeString
      },
      async content_LiveBroadcast_image() {
        let inputDOM = this.$refs.content_LiveBroadcast_imageUrl.files[0];
        //判断图片大小
        if (inputDOM.size < 512000) {
          const image = await update(inputDOM)
          if (image.code === 0) {
            this.content_LiveBroadcast_form.url = image.data.url
            this.$message.success("上传成功")
          } else {
            this.$message.warning("上传失败")
          }
        } else {
          this.$message.warning("请上传小于500KB的图片！")
        }
        document.getElementById('upload_image_liveDate').value = null;
      },
      async content_LiveBroadcast_urlHaiImage() {
        let inputDOM = this.$refs.content_LiveBroadcastUrl.files[0];
        //判断图片大小
        if (inputDOM.size < 512000) {
          const image = await update(inputDOM)
          if (image.code === 0) {
            this.content_LiveBroadcast_form.urlHai = image.data.url
            this.$message.success("上传成功")

          } else {
            this.$message.warning("上传失败")
          }
        } else {
          this.$message.warning("请上传小于500KB的图片！")
        }
        document.getElementById('upload_img_liveDte_url').value = null;
      },
      content_LiveBroadcast_liveTimeChange(time, timeString){
        this.content_LiveBroadcast_form.dateVal = timeString
      },
      content_LiveBroadcast_endChange(time, timeString){
        this.content_LiveBroadcast_form.dateEnd = timeString
      },
      async content_LiveBroadcast_submit() {
        let form = this.content_LiveBroadcast_form
        let data = {
          convention_id: this.$route.query.id,
          title: form.title,
          cover_url: form.url,
          desc_image_url: form.urlHai,
          live_date: form.dateVal,
          start_time: form.dateVal + ' ' + form.dateStart,
          end_time: form.dateVal + ' ' + form.dateEnd,
        }
        const response = await postCaseLive(data)
        if(response.code === 0){
          this.$message.success("添加成功！")
        //  清空列表
          this.content_LiveBroadcast_form = {}
          this.content_LiveBroadcast_form.dateVal = ''
          this.content_LiveBroadcast_form.dateEnd = ''
          this.content_LiveBroadcast_form.dateStart  = ''
          this.LiveBroadcast_end = null
          this.LiveBroadcast_start = null
        //  刷新列表
          await this.getLiveBroadcast_list()

        }else {
          this.$message.warning("失败！"+response.message)
        }
      },
      async content_LiveBroadcast_data_delBtn(id) {
        const response = await delSetLiveList(id)
        if (response.code === 0) {
          this.$message.success("删除成功！")
          //  刷新列表
          await this.getLiveBroadcast_list(this.LiveBroadcast_list_page_no)
        } else {
          this.$message.warning("失败！" + response.message)
        }
      },
      resetFormAddress(){
        this.visibleAdress = false
      },
      copySum (item) {
        //创建input标签
        var input = document.createElement('input')
        //将input的值设置为需要复制的内容
        input.value = item;
        //添加input标签
        document.body.appendChild(input)
        //选中input标签
        input.select()
        //执行复制
        document.execCommand('copy')
        //成功提示信息
        this.$message.success('success!')
        //移除input标签
        document.body.removeChild(input)
      },
      async address_list_Btn(list) {
        await this.getLiveBroadcast_list_one(list.id)
        let row = this.content_LiveBroadcast_data_one
        this.visibleAdress = true
        let form = this.addressForm
        form.address1 = row.obsPushIp
        form.address2 = row.obsPushName
        form.address3 = row.pullRtmpIp
        form.address4 = row.pullFlvIp
        form.address5 = row.pullM3u8Ip
      },
      content_address_Ok(){
        this.content_address_visible = false
      },
      RCYL_duration_switch(row){
        //接口中的是否打开
        this.durationForm.RCYL_duration_switch_isOpen_list = parseInt(row.durationIsOpen)
        this.durationForm.duration_remark = row.durationRemark
        this.RCYL_duration_switch_isOpen_id = row.id
        this.content_RCYL_duration_visible = true

      },
      //共用修改接口
      async rest_RCYL_isOpen(id,data) {
        const response = await put_RCYL_isOpen(id,data)
        if(response.code === 0){
          this.$message.success("修改成功！")
        //  刷新列表
          await this.getLiveBroadcast_list()
        }else {
          this.$message.warning("失败！"+response.message)
        }

      },
      //时长
      RCYL_duration_change_isOpen(isOpen){
        this.durationForm.RCYL_duration_switch_isOpen_list = isOpen
      },
      content_RCYL_duration_Ok(){
        let form = this.durationForm
        let data = {
          "durationIsOpen":form.RCYL_duration_switch_isOpen_list,
          "durationRemark":form.duration_remark
        }
        let id = this.RCYL_duration_switch_isOpen_id
        this.rest_RCYL_isOpen(id,data)
        this.content_RCYL_duration_visible = false
        this.durationForm.RCYL_duration_switch_isOpen_list = 0
        this.durationForm.duration_remark = ''
      },
      //企业专区
      async Enterprise_zone_Switch(row) {
        await this.getLiveBroadcast_list_one(row.id)
        let list = this.content_LiveBroadcast_data_one
        this.content_Enterprise_zone_visible = true
        this.RCYL_Enterprise_zone_isOpen_id = row.id
        let form = this.Enterprise_zoneForm
        form.isOpen_list = row.isEnterprise
        form.image = list.enterpriseImage
      },
      RCYL_Enterprise_zone_change_isOpen(isOpen){
        if(this.Enterprise_zoneForm.image !== '' && this.Enterprise_zoneForm.image !== null && this.Enterprise_zoneForm.image !== undefined){
          this.Enterprise_zoneForm.isOpen_list = isOpen
        }else {
          this.$message.warning("请先上传图片！")
        }
      },
      async content_RCYL_Enterprise_zone_image() {
        let inputDOM = this.$refs.content_RCYL_Enterprise_zone.files[0];
        //判断图片大小
        if (inputDOM.size < 1048576) {
          const image = await update(inputDOM)
          if (image.code === 0) {
            this.Enterprise_zoneForm.image = image.data.url
            this.$message.success("上传成功")

          } else {
            this.$message.warning("上传失败")
          }
        } else {
          this.$message.warning("请上传小于1M的图片！")
        }
        document.getElementById('up_img_Enterprise_zoneForm_img').value = null;
      },
      content_Enterprise_zone_Ok(){
        let form = this.Enterprise_zoneForm
        let data = {
          "isEnterprise":form.isOpen_list,
          "enterpriseImage":[form.image]
        }
        let id = this.RCYL_Enterprise_zone_isOpen_id
        this.rest_RCYL_isOpen(id,data)
        this.content_Enterprise_zone_visible = false
        this.Enterprise_zoneForm.isOpen_list = 0
        this.Enterprise_zoneForm.image = ''
      },
      //开场图
      async content_RCYL_opening_figure_image() {
        let inputDOM = this.$refs.content_RCYL_opening_figure.files[0];
        //判断图片大小
        if (inputDOM.size < 1048576) {
          const image = await update(inputDOM)
          if (image.code === 0) {
            this.opening_figureForm.image = image.data.url
            this.$message.success("上传成功")

          } else {
            this.$message.warning("上传失败")
          }
        } else {
          this.$message.warning("请上传小于1M的图片！")
        }
        document.getElementById('up_img_figure').value = null;
      },
      RCYL_opening_figure_change_isOpen(isOpen){
        if(this.opening_figureForm.image !== '' &&
            this.opening_figureForm.image !== null &&
            this.opening_figureForm.image !== undefined &&
           this.opening_figureForm.duration !== '' &&
            this.opening_figureForm.duration !== null &&
            this.opening_figureForm.duration !== undefined){
           this.opening_figureForm.isOpen_list = isOpen
        }else {
          this.$message.warning("请先上传图片并填写时长！")
        }

      },
      content_opening_figure_Ok(){
        this.$refs.content_opening_figure_ruleForm.validate(valid => {
          if (valid) {
            let form = this.opening_figureForm
            let data = {
              isBeginImage:form.isOpen_list,
              beginImageTime:form.duration,
              beginImageDetail:[form.image]
            }
            let id = this.opening_figure_id
            //修改
            this.rest_RCYL_isOpen(id,data)
            //清空
            this.opening_figureForm.isOpen_list = 0
            this.opening_figureForm.duration = ''
            this.opening_figureForm.image = ''
            this.content_opening_figure_visible = false
          } else {
            this.$message.warning("有空~~")
            return false;
          }
        });
      },
      async opening_figure_Switch(row) {
        await this.getLiveBroadcast_list_one(row.id)
        let list = this.content_LiveBroadcast_data_one
        //赋值
        let form = this.opening_figureForm
        form.isOpen_list = row.isBeginImage
        form.duration = list.beginImageTime
        form.image = list.beginImageDetail

        this.opening_figure_id = row.id


        //  打开对话框
        this.content_opening_figure_visible = true
      },
      Tuition_LiveBroadcast_data_pagination_Change(page_no,page_size){
        this.LiveBroadcast_list_page_no = page_no
        this.getLiveBroadcast_list(page_no)
      },
      //回放
      resetFormPlayback(){
        this.visiblePlayback=false
        this.PlaybackTableData = []
        this.videoList = []
      },
      switchPlaybackChange(is_open){
        this.PlaybackForm.replay_is_open = is_open
        let data = {
          replayIsOpen:is_open
        }
        let id = this.replay_is_open_switch_id
        this.rest_RCYL_isOpen(id,data)
      },
      onSubmitPlayback(){

      },
      //获取列表
      async get_content_PlaybackTableData_list(id,page_no) {
        this.content_PlaybackTableData_list_id = id
        //获取列表
        // const response = await get_Playback_list(id,page_no)
        const response = await get_Playback_list(id,page_no)
        if (response.code === 0) {
          let list = response.data.rows
          this.content_PlaybackTableData_data_total = response.data.count
          //每次请求前清空
          this.PlaybackTableData = []
          for (let i = 0; i < list.length; i++) {
            this.PlaybackTableData.push({
              id: list[i].id,
              name: list[i].title,
              duration: list[i].duration,
              addIsShow:0,
            })
          }
          this.cacheDataTwo = this.PlaybackTableData.map(item => ({...item}));
        } else {
          this.$message.warning("出错" + response.message)
        }
      },
      //点击回放对话框
      replay_is_open_switch(row) {
        this.PlaybackForm.replay_is_open = row.replayIsOpen
        this.replay_is_open_switch_id = row.id
        this.get_content_PlaybackTableData_list(row.id)
        this.visiblePlayback = true

      },
      async addVideoList(page_no,value) {
        let data = {
          page_no:page_no,
          title:value,
        }
          const response = await get_video_list(data)
          if (response.code === 0) {
            let list  = response.data.rows
            for (let i=0;i<list.length;i++){
              this.videoList.push({
                duration: list[i].duration,
                id: list[i].id,
                remark: list[i].remark,
                title: list[i].title,
              })
            }
          } else {
            this.$message.warning("失败"+response.message)
          }
      },
      //滚动条监听
      popupScroll_addVideoBtn(e){
        const {target} = e
        const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
        const clientHeight = target.clientHeight //滑动最低高度
        if(scrollHeight < clientHeight + 2){
          this.addVideoBtn_select_page_no++
          this.addVideoList(this.addVideoBtn_select_page_no,this.selectTitle)
        }
      },
      async addVideoBtn() {
        //获取视频库列表
        await this.addVideoList()
        this.PlaybackTableData.push({
          name: '',
          duration: '',
          editable: 1,
          addIsShow:1
        })
        // this.addIsShow = true
      },
      select_add_search(value) {
        //先清空，防止鼠标滑动时添加的数据输入时添加的数据重复
        this.videoList = []
        this.selectTitle = value
        this.addVideoList('',this.selectTitle)
      },
      select_add(title,id){
        this.videoListId = id
      },
      saveAdd(){

      },
      cancelAdd(){

      },
      handleChange(value, id, column) {
        const newData = [...this.PlaybackTableData];
        const target = newData.find(item => id === item.id);
        if (target) {
          target[column] = value;
          this.PlaybackTableData = newData;
        }
      },
      edit(id) {
        const newData = [...this.PlaybackTableData];
        const target = newData.find(item => id === item.id);
        this.editingKey = id;
        if (target) {
          target.editable = true;
          this.PlaybackTableData = newData;
        }
      },
      async putLive(id, data) {
        const response = await put_Playback_list(id, data)
        if (response.code === 0) {
          this.$message.success("操作成功")
        } else {
          this.$message.warning("出错"+ response.message)
        }
      },
      //保存
      async save(id, row) {
        if (!row.addIsShow) {
          const newData = [...this.PlaybackTableData];
          const newCacheData = [...this.cacheDataTwo];
          const target = newData.find(item => id === item.id);
          const targetCache = newCacheData.find(item => id === item.id);
          if (target && targetCache) {
            delete target.editable;
            this.PlaybackTableData = newData;
            Object.assign(targetCache, target);
            this.cacheDataTwo = newCacheData;
          }
          this.editingKey = '';
          //修改标题
          const PlaybackTableData = {
            title: row.name
          }
          this.putLive(id, PlaybackTableData)
        } else {
          //选择的视频库id,和列表id
          let videoManegeId = this.videoListId
          let streamId = this.replay_is_open_switch_id
          await this.postVideo(streamId, videoManegeId)
          row.editable = 0
          row.addIsShow = 0
          this.editingKey = '';
          //  刷新列表
          await this.get_content_PlaybackTableData_list(streamId)
        }
      },
      //添加视频库
      async postVideo(streamId,videoManegeId) {
        const response = await post_video_list(streamId,videoManegeId)
        if (response.code === 0) {
          this.$message.success("操作成功")
        } else {
          this.$message.warning("出错" + response.message)
        }
      },
      //取消
      cancel(id,row) {
        if(!row.addIsShow){
          const newData = [...this.PlaybackTableData];
          const target = newData.find(item => id === item.id);
          this.editingKey = '';
          if (target) {
            Object.assign(target, this.cacheDataTwo.find(item => id === item.id));
            delete target.editable;
            this.PlaybackTableData = newData;
          }
        }else {
          this.PlaybackTableData.pop()
        }
      },
      async confirmDel(id) {
        this.is_delete = 1
        const data = {
          is_delete: this.is_delete
        }
        const response = await putTitle(id,data)
        if(response.code === 0){
          this.$message.success("删除成功！")
          let streamId = this.replay_is_open_switch_id
          //  刷新列表
          await this.get_content_PlaybackTableData_list(streamId)
        }else {
          this.$message.warning("失败"+response.message)
        }
      },
      Tuition_PlaybackTableData_pagination_Change(page_no){
        this.content_PlaybackTableData_data_page_no = page_no
        this.get_content_PlaybackTableData_list(this.replay_is_open_switch_id,
            page_no)
      },
      rest_resetFormPlayback_close(){
        this.rest_visiblePlayback = false
      },
      onSubmit_resetFormPlayback(){
          this.$refs.content_rest_visiblePlayback_ruleForm.validate(valid => {
            if (valid) {
              let form = this.content_rest_visiblePlayback_form
              let id = this.content_LiveBroadcast_rest_Btn_id
              let data = {
                title:form.title,
                descImageUrl:form.desc_image_url,
                coverUrl:form.cover_url,
              }
              this.rest_RCYL_isOpen(id,data)
              this.rest_visiblePlayback = false
            //  清空
              this.$refs.content_rest_visiblePlayback_ruleForm.resetFields();
            } else {
              this.$message.warning("有空~~")
              return false;
            }
          })
      },
      async content_LiveBroadcast_rest_Btn(row) {
        await this.getLiveBroadcast_list_one(row.id)
        let list = this.content_LiveBroadcast_data_one
        this.content_LiveBroadcast_rest_Btn_id = row.id
        //赋值
        let form = this.content_rest_visiblePlayback_form
        form.title = row.title
        form.desc_image_url = list.descImageUrl
        form.cover_url = list.coverUrl
        this.rest_visiblePlayback = true
      },
      async content_rest_visiblePlayback_image() {
        let inputDOM = this.$refs.content_rest_visiblePlayback_one.files[0];
        //判断图片大小
        if (inputDOM.size < 512000) {
          const image = await update(inputDOM)
          if (image.code === 0) {
            this.content_rest_visiblePlayback_form.cover_url = image.data.url
            this.$message.success("上传成功")

          } else {
            this.$message.warning("上传失败")
          }
        } else {
          this.$message.warning("请上传小于500kb的图片！")
        }
        document.getElementById('up_img_rest').value = null;
      },
      async content_rest_visiblePlayback_two_image() {
        let inputDOM = this.$refs.content_rest_visiblePlayback_two.files[0];
        //判断图片大小
        if (inputDOM.size < 1048576) {
          const image = await update(inputDOM)
          if (image.code === 0) {
            this.content_rest_visiblePlayback_form.desc_image_url = image.data.url
            this.$message.success("上传成功")

          } else {
            this.$message.warning("上传失败")
          }
        } else {
          this.$message.warning("请上传小于1M的图片！")
        }
        document.getElementById('up_img_rest_visiblePlayback_two').value = null;
      },
      content_CloudHall_data_delBtn(id,isOpen){
        let data = {
          "isGallery":isOpen
        }
        this.rest_RCYL_isOpen(id,data)
      },


      //内容-日程日历展示----------------------------------------------------
      async content_CalendarCalendar_delBtn(id) {
        const response = await del_CalendarCalendar_timeList_listTable(id)
        if(response.code === 0){
          this.$message.success("删除成功！")
        //  刷新
        //   if(this.time_tabs === '' || this.time_tabs === undefined || this.time_tabs  === null){
        //     this.time_tabs = this.firstTime
        //   }
          // await this.getAssociated_schedule(this.time_tabs)
          // await this.getCalendarCalendar_list(this.time_tabs)
          //  刷新时间tabs
          await this.getCalendarCalendar()
          await this.getCalendarCalendar_list(this.firstTime)
        }else {
          this.$message.warning("失败！"+response.message)
        }
      },

      content_CalendarCalendar_submit(){
        this.$refs.content_ByAdvertising_ruleForm_RCYL.validate(async valid => {
          if (valid) {
            let form = this.content_CalendarCalendar_form

            //判断按钮点开时必须选择是否直播
            if(!form.isLive){
              form.isLiveSelect = undefined
              form.isCheck = []
              form.liveAddress = ''
            }
              if(form.isLive && form.liveAddress !== '' || !form.isLive && form.liveAddress === ''){
              if(this.ModifyLevel_id === '') {
                let liveUrlLink;
                //通过是否直播判断liveUrl传值问题
                if(form.isLive){
                //  打开状态
                  //  通过选择的直播间获取target_url传给live_link   herder
                    liveUrlLink = form.liveAddress
                }else {
                //  未打开状态
                  liveUrlLink = null;
                }
                //新增
                let data = {
                  "conventionId": this.$route.query.id,
                  "title": form.scheduleName,
                  "beginTime": (form.liveTime).replace(/\s*/g, '') + ' ' + (form.start_time).replace(/\s*/g, ''),
                  "endTime": (form.liveTime).replace(/\s*/g, '') + ' ' + (form.end_time).replace(/\s*/g, ''),
                  //主持人
                  "expertIdList": form.compere,
                  //主席
                  "chairmanIdList": form.chairman,
                  //嘉宾
                  "guestIdList": form.honored,
                  //角色
                  "role": "COMPERE",
                  //地点
                  "siteId": form.address,
                  //  是否直播
                  "liveEnabled": form.isLive,
                  //直播链接
                  "liveUrl": liveUrlLink,

                  "liveStreamId": form.isLiveSelect === undefined ? null : form.isLiveSelect,
                  // "liveId": form.isLiveSelect,
                  //  功能id
                  "funcId":this.funcId
                }
                const response = await post_CalendarCalendar_timeList_listTable(data)
                if (response.code === 0) {
                  //获取新增成功后一级日程的id
                  //添加成功后根据一级日程选择的主持人生成二级日程（几个主持人就生成几条二级日程）
                  let dataArr = {
                    "conventionId":this.$route.query.id,
                    "title":"主持任务",
                    "siteId":form.address,
                    "beginTime": (form.liveTime).replace(/\s*/g, '') + ' ' + (form.start_time).replace(/\s*/g, ''),
                    "endTime": (form.liveTime).replace(/\s*/g, '') + ' ' + (form.end_time).replace(/\s*/g, ''),
                    "role":"COMPERE",
                    "scheduleId":response.data,
                    "expertId":null
                  }
                  //判断添加以及日程时选择了多少个主持人
                  for (let i in form.compere){
                    dataArr.expertId = form.compere[i]
                    this.CalendarCalendar_child_add(dataArr)
                  }

                  this.$message.success("添加成功!")
                  //  刷新时间tabs
                  await this.getCalendarCalendar()
                  //直接跳转到指定日期
                  this.firstTime = form.liveTime
                  await this.getCalendarCalendar_list(this.firstTime)
                } else {
                  this.$message.warning(response.message)
                }
                this.content_CalendarCalendar_form.start_time = '00:00:00'
                this.content_CalendarCalendar_form.end_time = '00:00:00'
                this.content_CalendarCalendar_form.address = undefined
                this.content_CalendarCalendar_form.liveTime = null
                this.content_CalendarCalendar_form.compere = undefined
                this.content_CalendarCalendar_form.chairman = undefined
                this.content_CalendarCalendar_form.honored = undefined
                this.content_CalendarCalendar_form.isLive = 0
                this.content_CalendarCalendar_form.isLiveSelect = undefined
                this.content_CalendarCalendar_form.liveMeetingId = undefined
                this.content_CalendarCalendar_form.isCheck = []
                this.liveUrl = ''
                this.content_CalendarCalendar_form.scheduleName = ''
                this.content_CalendarCalendar_form.liveAddress = ''
              }else {

                let liveUrlLinks;
                //通过是否直播判断liveUrl传值问题
                if(form.isLive){
                  //  打开状态
                    //  通过选择的直播间获取target_url传给live_link   herder
                    // liveUrlLinks = live_link(this.roomId_CalendarCalendar, this.liveMeetingId_CalendarCalendar, this.$route.query.id, this.targetUrl_CalendarCalendar) + this.memberIdUrl
                    liveUrlLinks = form.liveAddress
                }else {
                  //  未打开状态
                  liveUrlLinks = null;
                }

                //  修改
                let data = {
                  "conventionId": this.$route.query.id,
                  "title": form.scheduleName,
                  "beginTime": (form.liveTime).replace(/\s*/g,'')+' '+(form.start_time).replace(/\s*/g,''),
                  "endTime": (form.liveTime).replace(/\s*/g,'')+' '+(form.end_time).replace(/\s*/g,''),
                  //主持人
                  "expertIdList": form.compere,
                  //主席
                  "chairmanIdList": form.chairman,
                  //嘉宾
                  "guestIdList": form.honored,
                  //角色
                  "role": "COMPERE",
                  //地点
                  "siteId": form.address,
                  //  是否直播
                  "liveEnabled": form.isLive,

                  "liveUrl": liveUrlLinks,

                  "liveStreamId": form.isLiveSelect === undefined ? null : form.isLiveSelect,
                  // "liveId": form.isLiveSelect,
                }
                const response = await put_CalendarCalendar_timeList_listTable(this.ModifyLevel_id,data)
                if (response.code === 0) {
                  //清空修改是获取的id
                  this.ModifyLevel_id = ''
                  this.$message.success("修改成功!")
                  //  刷新列表
                  // let time = this.time_tabs
                  // if(time === '' || time === undefined || time === null){
                  //   this.time_tabs = this.firstTime
                  // }
                  // await this.getCalendarCalendar_list(this.time_tabs)
                  //  刷新时间tabs
                  await this.getCalendarCalendar()
                  //直接跳转到指定日期
                  this.firstTime = form.liveTime
                  await this.getCalendarCalendar_list(this.firstTime)

                } else {
                  this.$message.warning(response.message)
                }
                this.$refs.content_ByAdvertising_ruleForm_RCYL.resetFields();
                this.content_CalendarCalendar_form.start_time = '00:00:00'
                this.content_CalendarCalendar_form.end_time = '00:00:00'
                this.content_CalendarCalendar_form.isLiveSelect = undefined
                this.content_CalendarCalendar_form.liveMeetingId = undefined
                this.content_CalendarCalendar_form.liveAddress = ''
                this.meettitle = ''
                this.meetPageNo = 1
                this.meetList = []
              }
            }else {
              this.$message.warning("直播按钮打开的情况下，直播间地址不能为空！")
            }
          } else {
            this.$message.warning("有空~~")
            return false;
          }
        });
      },
      closeCalendarCalendar(){
        //清空修改是获取的id
        this.ModifyLevel_id = ''
        this.content_CalendarCalendar_visible = false;
        this.content_CalendarCalendar_data = []
        this.content_CalendarCalendar_data_two = []
        this.$refs.content_ByAdvertising_ruleForm_RCYL.resetFields();
        this.content_CalendarCalendar_form.start_time = '00:00:00'
        this.content_CalendarCalendar_form.end_time = '00:00:00'
        this.content_CalendarCalendar_form.address = undefined
        this.content_CalendarCalendar_form.isLiveSelect = undefined
        this.content_CalendarCalendar_form.liveAddress = ''
        this.content_CalendarCalendar_form.isCheck = []
        this.liveUrl = ''

        this.meettitle = ''
        this.meetPageNo = 1
        this.meetList = []
      },
      date_picker_Change_CalendarCalendar(date, dateString){
        this.content_CalendarCalendar_form.liveTime = dateString
        this.meetList = [];
        this.getMeetList() //获取直播会议
        this.content_CalendarCalendar_form.isLiveSelect = undefined
        this.content_CalendarCalendar_form.liveMeetingId = undefined
        this.content_CalendarCalendar_form.start_time = '00:00:00'
        this.content_CalendarCalendar_form.end_time = '00:00:00'
      },
      date_picker_Change_open_Ok(time, timeString){
        this.content_CalendarCalendar_form.start_time = timeString
        this.content_CalendarCalendar_form.end_time = this.content_CalendarCalendar_form.start_time
      },
      date_picker_Change_open_end_time(time, timeString){
        this.content_CalendarCalendar_form.end_time = timeString
      },
      scheduleNameChange(){},
      onChange() {
        if(this.content_CalendarCalendar_form.isLiveSelect){
          let isCheck = this.content_CalendarCalendar_form.isCheck
          if(isCheck.length){
            // console.log('选中')
            this.memberIdUrl = '&memberId=78ce3cd0-e42b-11ea-8c91-23f1f183e1f9'
          }else {
            // console.log('未选中')
            this.memberIdUrl = ''
          }
          this.content_CalendarCalendar_form.liveAddress = live_link(this.roomId_CalendarCalendar,this.liveMeetingId_CalendarCalendar,this.$route.query.id,this.targetUrl_CalendarCalendar)+this.memberIdUrl
        }else {
          this.$message.warning("请先选择直播间再进行操作~")
          this.content_CalendarCalendar_form.isCheck = []
        }
      },
      LiveBroadcastCheckChange(){
        let isCheck = this.LiveBroadcastForm.isCheck
        if(isCheck.length){
          // console.log('选中')

        }else {
          // console.log('未选中')
        }
      },
      // //滚动条监听
      // popupScroll(e){
      //   const {target} = e
      //   const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      //   const clientHeight = target.clientHeight //滑动最低高度
      //   if(scrollHeight < clientHeight + 2){
      //     this.chairmanList_page_no++
      //     this.getCalendarCalendar_chairmanList(this.chairmanList_page_no)
      //   }
      // },
      CalendarCalendar_change_isLive(isOpen){
        this.content_CalendarCalendar_form.isLive = isOpen
        if(!isOpen){
          this.content_CalendarCalendar_form.isLiveSelect = undefined
          this.content_CalendarCalendar_form.isCheck = []
          this.content_CalendarCalendar_form.liveAddress = ''
        }

      },
      liveRoomChange(value){
        if(value === undefined){
          this.content_CalendarCalendar_form.liveAddress = ''
          this.content_CalendarCalendar_form.isCheck = []
        }
      },
      liveRoomClick(roomId,liveMeetingId,targetUrl){
          this.roomId_CalendarCalendar = roomId
          this.targetUrl_CalendarCalendar = targetUrl
          this.liveMeetingId_CalendarCalendar = liveMeetingId
          let isCheck = this.content_CalendarCalendar_form.isCheck
          if(isCheck.length){
            // console.log('选中')
            this.memberIdUrl = '&memberId=78ce3cd0-e42b-11ea-8c91-23f1f183e1f9'
          }else {
            // console.log('未选中')
            this.memberIdUrl = ''
          }
        this.content_CalendarCalendar_form.liveAddress = live_link(this.roomId_CalendarCalendar,this.liveMeetingId_CalendarCalendar,this.$route.query.id,this.targetUrl_CalendarCalendar)+this.memberIdUrl
      },
      async tab_pane_callback(key){
        this.content_CalendarCalendar_data_two = []
        //清空
        this.$refs.content_ByAdvertising_ruleForm_RCYL.resetFields();
        this.content_CalendarCalendar_form.start_time = '00:00:00'
        this.content_CalendarCalendar_form.end_time = '00:00:00'
        this.content_CalendarCalendar_form.isLiveSelect = undefined
        //赋值
        // this.time_tabs = key.target.value
        this.firstTime = key.target.value
        //选择时间标签后，会议日期同步
        this.content_CalendarCalendar_form.liveTime = key.target.value
        await this.getCalendarCalendar_list(key.target.value)
        // let list = this.content_CalendarCalendar_data
        if(this.content_CalendarCalendar_data.length){
          // console.log('time')
          await this.get_two_list(this.content_CalendarCalendar_data[0].id)
        }
      },
      async tab_address_callback(key){
        this.content_CalendarCalendar_data_two = []
        //清空
        this.$refs.content_ByAdvertising_ruleForm_RCYL.resetFields();
        this.content_CalendarCalendar_form.start_time = '00:00:00'
        this.content_CalendarCalendar_form.end_time = '00:00:00'
        this.content_CalendarCalendar_form.isLiveSelect = undefined
        //赋值
        this.firstAdressId = key.target.value
        await this.getCalendarCalendar_list(this.firstTime)
        if(this.content_CalendarCalendar_data.length){
          // console.log('address')
          await this.get_two_list(this.content_CalendarCalendar_data[0].id)
        }
      },
      CalendarCalendarRest(row){
        // console.log(row.liveUrl)
        this.meetList = [];
        this.live_Room = [];
        this.getMeetList();
        // this.getLiveRoom(row.beginDate)
        //修改，获取列表id
        this.ModifyLevel_id = row.id
        let form = this.content_CalendarCalendar_form
        form.scheduleName = row.title
        form.liveTime = row.beginDate

        form.isLive = row.liveEnabled
        form.liveAddress = row.liveUrl
        //获取直播间id
        if(row.liveUrl){
          // form.isLiveSelect = parseInt(live_link_id(row.liveUrl).room)
          form.isLiveSelect = row.liveStreamId === null ? undefined : row.liveStreamId
          form.liveMeetingId = row.liveMeetingId === null ? undefined:row.liveMeetingId
          //判断有没有memberId
          if(parseInt(live_link_id(row.liveUrl).memberId)){
            form.isCheck = ['游客']
          }else {
            form.isCheck = []
          }
          // this.liveMeetingId_CalendarCalendar = parseInt(live_link_id(row.liveUrl).live_meeting_id)
          this.liveMeetingId_CalendarCalendar = this.$route.query.liveMeetingId
        }

        form.address = row.siteId
        if(row.chairmanIdList === null){
          row.chairmanIdList = []
        }
        form.chairman = row.chairmanIdList

        if(row.expertIdList === null){
          row.expertIdList = []
        }
        form.compere = row.expertIdList

        if(row.guestIdList === null){
          row.guestIdList = []
        }
        form.honored = row.guestIdList

        form.start_time = row.beginTime.slice(11,19)
        form.end_time = row.endTime.slice(11,19)
      },
      CalendarCalendarAddChildren(){
        // let row = this.content_CalendarCalendar_data_two
        let fList = this.content_CalendarCalendar_data
        if(fList.length){
          if(this.selectIndex){
            let row = this.fartherList

            // this.farther_id_listId = this.selectIndex

            let newStr = {}
            newStr.name = ''
            newStr.beginTime = row.beginTime.slice(10,19)
            newStr.endTime = row.endTime.slice(10,19)
            newStr.newtime = ''
            newStr.role = 'SPEAKER'
            newStr.expertName = undefined
            newStr.expertId = ''
            Vue.set(newStr, "editable", 1)

            this.content_CalendarCalendar_data_two.push(newStr)
          }
        }else {
          // console.log(111)
          this.$message.warning("请先添加一级日程~")
        }
      },
      //获取二级列表
      async get_two_list(id) {
        this.selectIndex = id
        this.spinning = true
        const response = await get_CalendarCalendar_timeList_listTable_child(id)
        if (response.code === 0) {
          // let form = this.content_CalendarCalendar_data
          let form = response.data
          form.forEach((list)=>{
            list.beginTime = list.beginTime.slice(10, 19)
            list.endTime = list.endTime.slice(10, 19)
            list.newtime = list.beginTime + '~' + list.endTime
            list.name = list.title

            Vue.set(list, "editable", 0)
          })
          this.content_CalendarCalendar_data_two = form
          this.cacheData = this.content_CalendarCalendar_data_two.map(item => ({...item}));

          // for (let i = 0; i < form.length; i++) {
          //   //判断id相同
          //   if (id === form[i].id) {
          //     //获取数据将title换成name
          //     let childs = []
          //     for (let j = 0; j < response.data.length; j++) {
          //       let newStr = {}
          //       newStr.name = response.data[j].title
          //       newStr.id = response.data[j].id
          //
          //       newStr.beginTime = response.data[j].beginTime.slice(10, 19)
          //       newStr.endTime = response.data[j].endTime.slice(10, 19)
          //
          //       newStr.newtime = newStr.beginTime + '~' + newStr.endTime
          //
          //       newStr.role = response.data[j].role
          //       newStr.expertName = response.data[j].expertName
          //       newStr.expertId = response.data[j].expertId
          //       childs.push(newStr)
          //     }
          //     //响应式添加数据
          //     // form[i].childrens = response.data
          //     form[i].childrens = childs
          //     // 添加editable
          //     for (let j = 0; j < form[i].childrens.length; j++) {
          //       Vue.set(form[i].childrens[j], "editable", 0)
          //       this.cacheData = form[i].childrens.map(item => ({...item}));
          //     }
          //   }
          // }
        }
        this.spinning = false
      },
      CalendarCalendar_childrensChange(value, key, column) {
        const newData = [...this.content_CalendarCalendar_data];
        const target = newData.find(item => key === item.key)[0];
        if (target) {
          target[column] = value;
          this.content_CalendarCalendar_data = newData;
        }
      },



      //input输入框
      handleChange_two(value, id, column) {
        let form = this.content_CalendarCalendar_data_two
        for(let i=0;i<form.length;i++){
          const newData = [...form];
          const target = newData.find(item => id === item.id);
          if (target) {
            target[column] = value;
            form = newData;
          }
        }
      },
      handleChange_start(value, id, column,row) {
        value = moment(value).format('HH:mm:ss')
        let form = this.content_CalendarCalendar_data_two
        for(let i=0;i<form.length;i++){
          const newData = [...form];
          const target = newData.find(item => id === item.id);
          if (target) {
            target[column] = value;
            form = newData;
            row.endTime = value
            row.newtime = row.beginTime+'~'+row.endTime
          }
        }
      },
      handleChange_end(value, id, column,row) {
        value = moment(value).format('HH:mm:ss')
        let form = this.content_CalendarCalendar_data_two
        for(let i=0;i<form.length;i++){
          const newData = [...form];
          const target = newData.find(item => id === item.id);
          if (target) {
            target[column] = value;
            form = newData;
            row.newtime = row.beginTime+'~'+row.endTime
          }
        }
      },
      select_left_role(value, id, column){
        let form = this.content_CalendarCalendar_data_two
        for(let i=0;i<form.length;i++){
          const newData = [...form];
          const target = newData.find(item => id === item.id);
          if (target) {
            target[column] = value;
            form = newData;
          }
        }
        this.expertROLE_id = value
      },
      select_left_expertName(value, id, column,expertId){
        let form = this.content_CalendarCalendar_data_two
        for(let i=0;i<form.length;i++){
          const newData = [...form];
          const target = newData.find(item => id === item.id);
          if (target) {
            target[column] = value;
            form = newData;
          }
        }
        this.expertName_id = value
        this.expertIdNew = expertId
      },
      //保存
      async save_two(id, row) {
        let form = this.content_CalendarCalendar_data_two
        for (let i = 0; i < form.length; i++) {
          // this.cacheData = form[i].childrens.map(item => ({ ...item }));
          const newData = [...form];
          const newCacheData = [...this.cacheData];
          const target = newData.find(item => id === item.id);
          const targetCache = newCacheData.find(item => id === item.id);
          if (target && targetCache) {
            delete target.editable;
            form = newData;
            Object.assign(targetCache, target);
            this.cacheData = newCacheData;
          }
        }
        // console.log('新数据',row)
        // this.expertIdNew = row.expertId
        // if(this.expertName_id === ''){
        //   this.expertName_id = row.expertId
        // }
        // if(this.expertROLE_id === ''){
        //   this.expertROLE_id = row.role
        // }
        if(id !== null && id !== undefined && id !== ''){
          let data = {
            "title": row.name,
            "beginTime": (this.farther_id).replace(/\s*/g,'')+' '+(row.beginTime).replace(/\s*/g,''),
            "endTime": (this.farther_id).replace(/\s*/g,'')+' '+(row.endTime).replace(/\s*/g,''),
            "expertId": this.expertIdNew,
            // "expertId": row.expertId,
            // "role":this.expertROLE_id
            // "expertId": row.expertId,
            "role": row.role
          }
          //修改
          const response = await put_CalendarCalendar_timeList_listTable_child(row.id,data)
          if (response.code === 0) {
            //刷新列表
            await this.get_two_list(this.selectIndex)
            this.$message.success("修改成功！")
            this.expertName_id = ''
          }else {
            this.$message.warning("失败"+response.message)
          }
        }else {
          let data = {
            "conventionId":this.$route.query.id,
            "scheduleId":this.selectIndex,
            "title": row.name,
            "siteId":this.farther_id_addressId,
            "beginTime": (this.farther_id).replace(/\s*/g,'')+' '+(row.beginTime).replace(/\s*/g,''),
            "endTime": (this.farther_id).replace(/\s*/g,'')+' '+(row.endTime).replace(/\s*/g,''),

            "expertId": this.expertIdNew,
            // "role":this.expertROLE_id
            // "expertId": row.expertId,
            "role": row.role
          }
        //  新增
          if(row.name !== '' && data.beginTime !== '' && data.endTime !== '' && data.role !== '' &&
              row.name !== undefined && data.beginTime !== undefined && data.endTime !== undefined && data.role !== undefined &&
              row.name !== null && data.beginTime !== null && data.endTime !== null && data.role !== null
          ){
            const response = await post_CalendarCalendar_timeList_listTable_child(data)
            if (response.code === 0) {
              //刷新列表
              await this.get_two_list(this.selectIndex)
              this.$message.success("新增成功！")
              this.expertName_id = ''
              this.expertIdNew = ''
            }else {
              this.$message.warning("失败"+response.message)
            }
          }else {
            this.$message.warning("有空~~")
          }
        }
      },
      //添加二级日程接口
      async CalendarCalendar_child_add(data) {
        const response = await post_CalendarCalendar_timeList_listTable_child(data)
        if (response.code === 0) {

        } else {
          this.$message.warning("失败" + response.message)
        }
      },
      //取消
      cancel_two(id,row) {
        if(id !== '' && id !== null && id !== undefined){
          let form = this.content_CalendarCalendar_data_two
          for(let i=0;i<form.length;i++){
            const newData = [...form];
            const target = newData.find(item => id === item.id);
            if (target) {
              Object.assign(target, this.cacheData.find(item => id === item.id));
              delete target.editable;
              form = newData;
            }
          }
        }else {
        //  取消新增操作，删除这一行
          let form = this.content_CalendarCalendar_data_two
          for(let i=0;i<form.length;i++){
            // if(this.farther_id_listId === form[i].id){
            if(this.selectIndex === form.id){
              form.pop()
            }
          }
          //  刷新
          this.get_two_list(this.selectIndex)
        }
      },

      //修改按钮
      edit_two(id,row) {

        let form = this.content_CalendarCalendar_data_two
        for(let i=0;i<form.length;i++){
          // console.log(id,row)
          const newData = [...form];
          const target = newData.find(item => id === item.id);
          if (target) {
            target.editable = true;
            // this.startTime = row.beginTime
            // this.endTime = row.endTime
            form = newData;

            //  expertId 赋值
            if(row.id === id){
              this.expertIdNew = row.expertId
            }

          }
        }
      },

      //删除
      async del_two(id) {
        const response = await del_CalendarCalendar_timeList_listTable_child(id)
        if (response.code === 0) {
          this.$message.success("删除成功！")
          //刷新
          await this.get_two_list(this.selectIndex)
        } else {
          this.$message.warning("失败！" + response.message)
        }
      },

      rowClick: function(record, index) {
        return {
          on: {
            click: () => {
              this.selectIndex = record.id
              this.fartherList = record
              //获取父级的id--日期
              this.farther_id = record.beginDate
              //  父级会议地点
              this.farther_id_addressId = record.siteId

              this.content_CalendarCalendar_data_two = []
              this.get_two_list(record.id)
            },

            // dblclick: () => {
            //   console.log('双击了我')
            // },
          }
        }
      },

      rowClassName(record, index){
        // console.log(record.id === this.selectIndex)
        return record.id === this.selectIndex ? "changeBjys" : ""
      },



      //内容-主持讲者展示展示---------------------------------------------------------------
      close_listLiveBroadcastCalendar(){
        this.content_LiveBroadcastCalendar_list_visible = false
      //  刷新列表
        this.getLiveBroadcastCalendar_list_list()
      },
      LiveBroadcastCalendar_list_pagination_Change(page_no,page_size){
        this.LiveBroadcastCalendar_list_page_no = page_no
        this.LiveBroadcastCalendar_list_page_size = page_size
        this.getLiveBroadcastCalendar_list_list(page_no,this.LiveBroadcastCalendar_list_top_name)
      },
      LiveBroadcastCalendar_list_top_btn() {
        this.getLiveBroadcastCalendar_list_list(
            this.LiveBroadcastCalendar_list_page_no,
            this.LiveBroadcastCalendar_list_top_name)
      },
      //获取主持讲者列表
      async getLiveBroadcastCalendar_list_list(page_no,name){
        this.spinning = true
        let data = {
          conventionId:this.$route.query.id,
          page_no:page_no,
          name:name,
          funcId:this.funcId
        }
        const response = await get_CalendarCalendar_chairmanList(data)
        if(response.code === 0){
          this.LiveBroadcastCalendar_list_data = response.data.rows
          this.LiveBroadcastCalendar_list_count = response.data.count
        }else {
          this.$message.warning("失败"+response.message)
        }
        this.spinning = false
      },
      onSearch(value) {

      },
      LiveBroadcastCalendar_pagination_Change(page_no,page_size){
        this.LiveBroadcastCalendar_pagination_page_no = page_no
        this.LiveBroadcastCalendar_pagination_page_size = page_size
        this.getXsxlFile(page_no)
      },
      LiveBroadcastCalendar_pagination_Change_right(page_no,page_size){
        this.LiveBroadcastCalendar_pagination_right_page_no = page_no
        this.LiveBroadcastCalendar_pagination_right_page_size = page_size
        this.getLogData()
      },
      async content_LiveBroadcastCalendar__image() {
        let inputDOM = this.$refs.content_LiveBroadcastCalendar.files[0];
        //判断图片大小
        if (inputDOM.size < 2097152) {
          const image = await update(inputDOM)
          if (image.code === 0) {
            this.content_LiveBroadcastCalendar_form.headimg = image.data.url
            this.$message.success("上传成功")
            document.getElementById('uploadFile_zcjz_img').value = null;
          } else {
            this.$message.warning("上传失败")
          }
        } else {
          this.$message.warning("请上传小于2M的图片！")
        }
      },
      async DownloadTemplate() {
        let data = {
          "ExelName":"模板"
        }
        const response = await Download_the_template_exal(data)
      },
      //上传xsxl文件
      async content_LiveBroadcastCalendar_addFile() {
        let file = this.$refs.content_LiveBroadcastCalendar_addFile_inputFile.files[0];
        let formData = new FormData()
        formData.append('file', file)
        this.content_LiveBroadcastCalendar_file = file.name
        this.fileFormData = formData
        document.getElementById('content_LiveBroadcastCalendar_addFile_inputFile').value = null;
      },
      //上传的临时表
      async true_LiveBroadcastCalendar_addFile() {
        const response = await get_importExpertTemporaries_xsxl(this.fileFormData)
        if (response.code === 0) {
          this.$message.success(response.message)
          //上传成功后将value制空防止上传一样名称文件时不触发change
          document.getElementById('uploadFile_zcjz').value = null;
          await this.getXsxlFile()
        } else {
          this.$message.warning(response.message)
        }
      },
      //获取导入的临时列表
      async getXsxlFile(page_no) {
        this.spinning = true
        const response = await importExpertTemporaries_xsxl(page_no)
        if (response.code === 0) {
          this.LiveBroadcastCalendar_data = response.data.rows
          this.LiveBroadcastCalendar_pagination_total = response.data.count
        } else {
          this.$message.warning(response.message)
        }
        this.spinning = false
      },
      look_LiveBroadcastCalendar_data_right(name,id) {
        // this.name_input = ''
        this.LiveBroadcastCalendar_pagination_onSubmit_name = name
        this.getLogData()
        this.exel_list_id = id
      },
      async look_LiveBroadcastCalendar_data_right_del(id) {
        const response = await del_list_person(id)
        if (response.code === 0) {
          this.$message.success("删除成功！")
          await this.getXsxlFile()
        } else {
          this.$message.warning(response.message)
        }
      },
      async del_all() {
        const response = await del_list_person_all()
        if (response.code === 0) {
          this.$message.success("删除成功！")
          await this.getXsxlFile()
        } else {
          this.$message.warning(response.message)
        }
      },
      //名字----获取专家库
      // async getLogData(name,page_no,page_size) {


      async look_LiveBroadcastCalendar_one(id) {
        this.expertForm={
          editId: id,
          formType:'edit',
          formShow:true,
        }
        // this.btn_isShow = false
        // //打开对话框
        // this.content_LiveBroadcastCalendar_visible_model = true
        // this.LiveBroadcastCalendar_pagination_onSubmit_id = id
        // const response = await get_experts_xsxl_one(id)
        // if (response.code === 0) {
        //   //赋值
        //   let form = this.content_LiveBroadcastCalendar_form
        //   form.intro = response.data.intro
        //   form.departmentCode = response.data.departmentCode
        //   form.name = response.data.name
        //   form.hospital = response.data.hospital
        //   form.title = response.data.title
        //   form.headimg = response.data.headimg
        //
        // } else {
        //   this.$message.warning(response.message)
        // }
      },
      LiveBroadcastCalendar_pagination_onSubmit(){
        this.$refs.content_LiveBroadcastCalendar_ruleForm_preside_over.validate(async valid => {
          if (valid) {
            let form = this.content_LiveBroadcastCalendar_form
            const response = await put_experts_xsxl_one(
                this.LiveBroadcastCalendar_pagination_onSubmit_id,form)
            if (response.code === 0) {
              this.$message.success(response.message)
              //关闭对话框
              this.content_LiveBroadcastCalendar_visible_model = false
              //刷新右侧列表
              await this.getLogData()
            //  清空
              this.$refs.content_LiveBroadcastCalendar_ruleForm_preside_over.resetFields();
            } else {
              this.$message.warning(response.message)
            }
          } else {
            this.$message.warning("有空~~")
            return false;
          }
        });
      },
      LiveBroadcastCalendar_pagination_rest(){
        //  清空
        this.$refs.content_LiveBroadcastCalendar_ruleForm.resetFields();
      },
      LiveBroadcastCalendar_pagination_Add(){
        this.$refs.content_LiveBroadcastCalendar_ruleForm_preside_over.validate(async valid => {
          if (valid) {
            let form = this.content_LiveBroadcastCalendar_form
            const response = await post_experts_xsxl_one(form)
            if (response.code === 0) {
              this.$message.success(response.message)
              //关闭对话框
              this.content_LiveBroadcastCalendar_visible_model = false
              //刷新右侧列表
              await this.getLogData()
              //  清空
              this.$refs.content_LiveBroadcastCalendar_ruleForm_preside_over.resetFields();
            } else {
              this.$message.warning(response.message)
            }
          } else {
            this.$message.warning("有空~~")
            return false;
          }
        });
      },
      close_LiveBroadcastCalendar(){
        this.content_LiveBroadcastCalendar_visible = false;
        // //  清空
        // this.$refs.content_LiveBroadcastCalendar_ruleForm.resetFields();
        this.content_LiveBroadcastCalendar_file = ''
        this.name_input = ''
        this.LiveBroadcastCalendar_pagination_total = 0
        this.LiveBroadcastCalendar_pagination_total_right = 0
        this.LiveBroadcastCalendar_pagination_page_no = 1
        this.LiveBroadcastCalendar_pagination_right_page_no = 1
        this.LiveBroadcastCalendar_data_right = []
        this.LiveBroadcastCalendar_data = []
        //  清空
        this.$refs.content_LiveBroadcastCalendar_ruleForm_preside_over.resetFields();
      //  刷新列表
        this.getLiveBroadcastCalendar_list_list()
      },
      //选择-----添加专家
      async add_exel_list(id) {
        let data = {
          "conventionId":this.$route.query.id,
          "expertId":id,
          expertTemporaryId:this.exel_list_id,
          funcId:this.funcId
        }
        const response = await post_experts_xsxl(data)
        if (response.code === 0) {
          this.$message.success(response.message)
          //清除
          this.exel_list_id = ''
        //  刷新左侧列表
          await this.getXsxlFile(this.LiveBroadcastCalendar_pagination_page_no)
        //  刷新右侧列表
          await this.getLogData()
        } else {
          this.$message.warning(response.message)
        }
      },

      //-企业海报展示--------------------------------------------------------------------------------------
      close_EnterprisePosters(){
        this.$refs.content_EnterprisePosters_ruleForm.resetFields()
        this.content_EnterprisePosters_visible = false
        this.EnterprisePosters_relevance = []
        this.content_EnterprisePosters_form.scheduleName = undefined
      //  清空id
        this.List_Corporate_posters_id = ''
      },
      async content_LiveBroadcastCalendar_thumbnail_image() {
        let inputDOM = this.$refs.content_LiveBroadcastCalendar_thumbnail_image.files[0];
        //判断图片大小
        if (inputDOM.size < 1048576) {
          const image = await update(inputDOM)
          if (image.code === 0) {
            this.content_EnterprisePosters_form.thumbnail = image.data.url
            this.$message.success("上传成功")
          } else {
            this.$message.warning("上传失败")
          }
        } else {
          this.$message.warning("请上传小于1M的图片！")
        }
        document.getElementById('up_img_LiveBroadcastCalendar_thumbnail').value = null;
      },
      async content_LiveBroadcastCalendar_original_image() {
        let inputDOM = this.$refs.content_LiveBroadcastCalendar_original_image.files[0];
        //判断图片大小
        if (inputDOM.size < 1048576) {
          const image = await update(inputDOM)
          if (image.code === 0) {
            this.content_EnterprisePosters_form.original = image.data.url
            this.$message.success("上传成功")

          } else {
            this.$message.warning("上传失败")
          }
        } else {
          this.$message.warning("请上传小于1M的图片！")
        }
        document.getElementById('up_img_LiveBroadcastCalendar_original').value = null;
      },
      async content_LiveBroadcastCalendar_start_image() {
        let inputDOM = this.$refs.content_livePendingImage.files[0];
        //判断图片大小
        if (inputDOM.size < 1048576) {
          const image = await update(inputDOM)
          if (image.code === 0) {
            this.content_EnterprisePosters_form.livePendingImage = image.data.url
            this.$message.success("上传成功")
          } else {
            this.$message.warning("上传失败")
          }
        } else {
          this.$message.warning("请上传小于1M的图片！")
        }
        document.getElementById('content_livePendingImage').value = null;
      },
      close_EnterprisePosters_list(){
        this.content_EnterprisePosters_list_visible  = false

      },
      async content_LiveBroadcastCalendar_end_image() {
        let inputDOM = this.$refs.content_liveEndImage.files[0];
        //判断图片大小
        if (inputDOM.size < 1048576) {
          const image = await update(inputDOM)
          if (image.code === 0) {
            this.content_EnterprisePosters_form.liveEndImage = image.data.url
            this.$message.success("上传成功")
          } else {
            this.$message.warning("上传失败")
          }
        } else {
          this.$message.warning("请上传小于1M的图片！")
        }
        document.getElementById('content_liveEndImage').value = null;
      },
      // content_EnterprisePosters_time_focus(){
      // //  获取焦点时，清空
      // },
      EnterprisePosters_onChange(date, dateString) {
        this.content_EnterprisePosters_form.meetDate = dateString
        this.getAssociated_schedule(dateString)
      },
      async getAssociated_schedule(dateString) {
        //通过选择的时间获取关联日程
        const response = await get_CalendarCalendar_timeList_listTable(this.$route.query.id, dateString)
        if (response.code === 0) {
          this.EnterprisePosters_relevance = response.data
        } else {
          this.$message.warning("失败" + response.message)
        }
      },
      EnterprisePosters_handleChange(id,name){
        this.content_EnterprisePosters_form.scheduleId = id
        this.content_EnterprisePosters_form.scheduleName = name
      },
      onsubmit_EnterprisePosters(){
        this.$refs.content_EnterprisePosters_ruleForm.validate(async valid => {
          if (valid) {
            let form = this.content_EnterprisePosters_form
            let id = this.List_Corporate_posters_id
            if(id === '' || id === undefined || id === null){
              //新增企业海报功能
              let data = {
                thumbnail:form.thumbnail,
                scheduleId:form.scheduleId,
                livePendingImage:form.livePendingImage,
                liveEndImage:form.liveEndImage,
                conventionId:this.$route.query.id,
                original:form.original,
                enterpriseName:form.enterpriseName,
                funcId:this.funcId
              }
              const response = await postEnterprisePosters(data)
              if (response.code === 0) {
                this.$message.success("新增成功")
                this.content_EnterprisePosters_visible = false
                this.$refs.content_EnterprisePosters_ruleForm.resetFields()
                //清空选项
                this.EnterprisePosters_relevance = []
                this.content_EnterprisePosters_form.scheduleName = undefined
                //刷新企业海报列表
                await this.getEnterprisePosters_list(
                    this.EnterprisePosters_pagination_page_no,
                    this.EnterprisePosters_pagination_page_size)
              } else {
                this.$message.warning("失败"+ response.message)
              }
            }else {
            //  修改
              let data = {
                thumbnail:form.thumbnail,
                scheduleId:form.scheduleId,
                original:form.original,
                livePendingImage:form.livePendingImage,
                liveEndImage:form.liveEndImage,
                enterpriseName:form.enterpriseName
              }
              const response_rest = await putEnterprisePosters(id,data)
              if(response_rest.code === 0){
                this.content_EnterprisePosters_form.original = ''
                this.content_EnterprisePosters_form.thumbnail = ''
                this.content_EnterprisePosters_form.liveEndImage = ''
                this.content_EnterprisePosters_form.livePendingImage = ''
                this.$refs.content_EnterprisePosters_ruleForm.resetFields()
                //清空选项
                this.EnterprisePosters_relevance = []
                this.content_EnterprisePosters_form.scheduleName = undefined
                this.content_EnterprisePosters_visible = false
                //  清空id
                this.List_Corporate_posters_id = ''
                //刷新企业海报列表
                await this.getEnterprisePosters_list(
                    this.EnterprisePosters_pagination_page_no,
                    this.EnterprisePosters_pagination_page_size)
                this.$message.success("修改成功")
              }else {
                this.$message.warning("失败"+ response_rest.message)
              }
            }
          } else {
            this.$message.warning("有空~~")
            return false;
          }
        });
      },
      //分页
      pagination_EnterprisePosters_onChange(page_no,page_size){
        this.EnterprisePosters_pagination_page_no = page_no
        this.EnterprisePosters_pagination_page_size = page_size
        this.getEnterprisePosters_list(page_no,page_size)
      },
      async EnterprisePosters_list_rest(row) {
        //获取修改企业海报的id
        this.List_Corporate_posters_id = row.id

        this.content_EnterprisePosters_visible = true
        let form = this.content_EnterprisePosters_form
        form.enterpriseName = row.enterpriseName
        form.original = row.original
        form.thumbnail = row.thumbnail
        form.livePendingImage = row.livePendingImage
        form.liveEndImage = row.liveEndImage
        //获取日期---日程id-name
        const response =  await getOneDate(row.scheduleId)
        if(response.code === 0){
          form.meetDate = response.data.beginDate
          form.scheduleName = response.data.title
          form.scheduleId = response.data.id
        //  通过时间获取关联日程
          await this.getAssociated_schedule(form.meetDate)
        }else {
          this.$message.warning(response.message)
        }
      },
      EnterprisePosters_list_add(){
        this.content_EnterprisePosters_visible = true
        //  清空id---切换成新增
        this.List_Corporate_posters_id = ''
      //  清空
      //   this.$refs.content_EnterprisePosters_ruleForm.resetFields()
      },
      EnterprisePosters_list_del(){

      },
      //删除
      async EnterprisePostersDel(id) {
        const response = await delCorporatePoster(id)
        if (response.code === 200) {
          this.$message.success(response.message)
          await this.getFirmList()
        } else {
          this.$message.warning(response.message)
        }
      },
      EnterprisePostersUp(id, type) {
        this.EnterprisePostersUpDown(id, type)
      },
      EnterprisePostersDown(id, type) {
        this.EnterprisePostersUpDown(id, type)
      },
      async EnterprisePostersUpDown(id, type) {
        const response = await putCorporatePoster(id, type)
        if (response.code === 0) {
          this.$message.success(response.message)
          await this.getFirmList()
        } else {
          this.$message.warning(response.message)
        }
      },
      closeEnterprisePosters(){
        this.enterprisePosters = false
        this.meettitle = ''
        this.meetPageNo = 1
        this.meetList = []
        this.enterprisePosters_meetingId = undefined
      },
      //获取企业海报列表
      async getFirmList() {
        const response = await corporatePoster(this.$route.query.id) //直播会议
        if(response.code === 0){
          this.EnterprisePostersData = response.data
        }else {
          this.$message.warning(response.message)
        }
      },
      async addMeetBtn() {
        if (this.enterprisePosters_meetingId) {
          let data = {
            "conventionId": this.$route.query.id,
            "liveMeetingId": this.enterprisePosters_meetingId
          }
          const response = await postCorporatePoster(data)
          if (response.code === 200) {
            this.$message.success(response.message)
            await this.getFirmList()
          } else {
            this.$message.warning(response.message)
          }
        } else {
          this.$message.warning("请先选择直播会议~")
        }
      },
      selectOption(value){
        this.content_CalendarCalendar_form.isLiveSelect = undefined
        this.content_CalendarCalendar_form.liveAddress = ''
        this.content_CalendarCalendar_form.isCheck = []
        this.getLiveRoom(value)
      },
      //获取直播会议列表
      async getMeetList() {
        let meetYear = this.content_CalendarCalendar_form.liveTime
        let data = {
          title:this.meettitle,
          pageNo:this.meetPageNo,
          // sortTime:this.meetYear,
          sortTime:meetYear ? meetYear.substr(0,4) + '-01-01 00:00:00':null,
        }
        const response = await getLiveMeetList(data) //直播会议
        let list  = response.data.rows
          for (let i=0;i<list.length;i++){
            //判断是医学会议还是直播会议
              this.meetList.push({
                id: list[i].id,
                title: list[i].title,
                url:list[i].targetUrl
              })
          }
      },
      //滚动条监听
      popupScrollMeetingId(e){
        const {target} = e
        const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
        const clientHeight = target.clientHeight //滑动最低高度
        if(scrollHeight < clientHeight + 2){
          this.meetPageNo++
          this.getMeetList()
        }
      },
      //鼠标搜索
      selectMeetingId(value){
        //首先清空数组，否则会数据重复
        this.meetList = []
        this.meettitle = value
        this.meetPageNo = 1
        this.getMeetList()
      },
      //移动公用
      async Enterprise_Poster_Movement(id,action) {
        const response = await EnterprisePosterMovement(id,action)
        if (response.code === 0) {
          this.$message.success("操作成功！")
        //  刷新列表
          await this.getEnterprisePosters_list(this.EnterprisePosters_pagination_page_no,this.EnterprisePosters_pagination_page_size)
        } else {
          this.$message.warning(response.message)
        }
      },
      EnterprisePosters_list_up(id){
        this.Enterprise_Poster_Movement(id,'up')
      },
      EnterprisePosters_list_down(id){
        this.Enterprise_Poster_Movement(id,'down')
      },

      //  内容-点播视频展示-----------------------------------------------------
      //获取列表
      async get_content_video_list(pageNum) {
        let data = {
          pageNum:pageNum,
          pageSize:10
        }
        let id = this.$route.query.id
        const response = await get_demand_playback_list(id,data)
        if (response.code === 0) {
          this.video_demand_data = response.data.vodContents
          this.video_demand_pagination_total = response.data.count
        } else {
          this.$message.warning(response.message)
        }
      },
      //上移下移
      async upDown_content_video_list(id,moveUpDown) {
        const response = await demand_playback_list_upDown(id,moveUpDown)
        if (response.code === 0) {
          //刷新列表
          await this.get_content_video_list(this.video_demand_pagination_page_no)
          this.$message.success('操作成功！')
        } else {
          this.$message.warning(response.message)
        }
      },
      upDown_video_demand(moveUpDown,id){
        this.upDown_content_video_list(id,moveUpDown)
      },
      //富文本编辑器
      async create_editor_config() {
        //等待DOM加载完毕
        const editor = new E(this.$refs.editor)
        this.editor = editor
        //去除图片视频上传
        editor.config.excludeMenus = [
          'image',
          'video'
        ]
        // 设置编辑区域高度为 500px
        editor.config.height = 200
        //创建编辑器
        await editor.create()
      },
      rest_video_demand(row) {
        this.duration = row.duration
        //获取专家列表
        this.content_video_experts_list()
        this.video_experts_list = []
        this.content_video_demand_visible_upVideo = true
        this.videoList_id = row.manageId
        this.file_id_list_rest = row.file_id
        //片头
        this.getStartEndList('start')
        //片尾
        this.getStartEndList('end')
        this.get_video_demand_oneList(row.manageId)
        //创建以及销毁富文本编辑器
        this.$nextTick(() => {
          if (this.editor === null) {
            this.create_editor_config()
          } else {
            this.editor.destroy()
            this.create_editor_config()
          }
        })
      },
      //获取单个视频列表详情
      async get_video_demand_oneList(id) {
        let data = {
          id:id
        }
        const response = await getVideoList(data)
        if (response.code === 0) {
          let data = response.data[0]
          if(data.remark){
            this.editor.txt.html(data.remark)
          }
          let form = this.content_video_demand_form
          form.title = data.title
          form.surface_plot = data.coverImg
          form.video = data.mediaUrl
          this.yunVideo_fileId = data.fileId
          form.specialist = data.expertId
          form.titleStart = data.videoStartId
          form.titleEnd = data.videoEndId
        } else {
          this.$message.warning(response.message)
        }
      },
      upVideo_btn(){
        this.content_video_experts_list()
        this.video_experts_list = []
        //片头
        this.getStartEndList('start')

        //片尾
        this.getStartEndList('end')
        this.content_video_demand_visible_upVideo = true
        //创建以及销毁富文本编辑器
        this.$nextTick(()=>{
          if(this.editor === null){
            this.create_editor_config()
          }else {
            this.editor.destroy()
            this.create_editor_config()
          }
        })
      },
      close_video_demand(){
        this.content_video_demand_visible = false
      },
      close_video_demand_upVideo(){
        this.content_video_demand_visible_upVideo = false
        this.video_experts_list = []
        //清空表单
        this.$refs.content_video_ruleForm.resetFields();
        this.content_video_demand_form.title = '';
        this.content_video_demand_form.titleStart = undefined;
        this.content_video_demand_form.titleEnd = undefined;
        this.videoList_id = '';
        this.yunVideo_fileId = ''
        this.duration = 0
      },
      onsubmit_video_demand_upVideo() {
        this.content_video_demand_form.include = this.editor.txt.html()
        this.$refs.content_video_ruleForm.validate(valid => {
          if (valid) {
            let form = this.content_video_demand_form
            //修改  this.videoList_id判断有无id
            if(this.videoList_id){
              let data = {
                "id": this.videoList_id,
                "coverImg": form.surface_plot,
                "title": form.title,
                "fileId": this.file_id_list_rest,
                "mediaUrl": form.video,
                "coverUrl": form.surface_plot,
                "duration": this.duration,
                "expertId":  form.specialist,
                "remark": form.include,
                "videoStartId":  form.titleStart ,
                "videoEndId":  form.titleEnd
                // //"是否有片头  1是   0否"
                // "isVideoStart": form.titleStart ? 1:0,
                // //"是否有片尾  1是   0否"
                // "isVideoEnd": form.titleEnd ? 1:0,
              }
              this.put_video_demand(data)
            }else {
              let dataAdd = {
                "coverImg": form.surface_plot,
                "title": form.title,
                "fileId": this.yunVideo_fileId,
                "mediaUrl": form.video,
                "coverUrl": form.surface_plot,
                "duration": this.duration,
                "expertId": form.specialist,
                "remark": form.include,
                "videoStartId":  form.titleStart,
                "videoEndId": form.titleEnd,
                'conventionId':parseInt(this.$route.query.id)
              }
              //新增
              this.post_video_demand(dataAdd)
            }
            this.content_video_demand_visible_upVideo = false
            this.video_experts_list = []

            this.$refs.content_video_ruleForm.resetFields();
            this.content_video_demand_form.title = '';
            this.content_video_demand_form.titleStart = undefined;
            this.content_video_demand_form.titleEnd = undefined;
            this.videoList_id = ''
            this.yunVideo_fileId = ''
            this.duration = 0
          } else {
            this.$message.warning("表单有空！")
          }})
      },
      //新增点播视频
      async post_video_demand(data) {
        const response = await postVideoManage(data)
        if (response.code === 0) {
          this.$message.success("操作成功~")
          await this.get_content_video_list(this.video_demand_pagination_page_no)
        } else {
          this.$message.warning(response.message)
        }
      },
      //修改点播视频
      async put_video_demand(data) {
        const response = await putVideoManage(data)
        if (response.code === 0) {
          //刷新列表
          await this.get_content_video_list(this.video_demand_pagination_page_no)
          this.$message.success('修改成功！')
        } else {
          this.$message.warning(response.message)
        }
      },
      //滚动条监听
      popupScroll_live(e){
        const {target} = e
        const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
        const clientHeight = target.clientHeight //滑动最低高度
        if(scrollHeight < clientHeight + 2){
          this.experts_list_page_no++
          this.content_video_experts_list(this.selectTitle_experts_list,this.experts_list_page_no)
        }
      },
      //鼠标搜索
      select_live(value){
        //首先清空数组，否则会数据重复
        this.video_experts_list = []
        this.selectTitle_experts_list = value
        this.content_video_experts_list(this.selectTitle_experts_list,'')
      },
      video_demand_handleChange(id,name){
        this.content_video_demand_form.specialist = id
      },
      async content_video_delBtn(id) {
        const response = await del_videoList(id)
        if (response.code === 0) {
          //刷新列表
          await this.get_content_video_list(this.video_demand_pagination_page_no)
          this.$message.success('删除成功！')
        } else {
          this.$message.warning(response.message)
        }
      },
      async content_zcjz_delBtn(id) {
        const response = await del_zcjz(id)
        if (response.code === 0) {
          this.$message.success("删除成功！")
          await this.getLiveBroadcastCalendar_list_list(this.LiveBroadcastCalendar_list_page_no, this.LiveBroadcastCalendar_list_top_name)
        } else {
          this.$message.warning("删除失败"+response.message)
        }
      },
      async content_surface_plot_image() {
        let inputDOM = this.$refs.content_surface_plot_original_image.files[0];
        //判断图片大小
        if (inputDOM.size < 512000) {
          const image = await update(inputDOM)
          if (image.code === 0) {
            this.content_video_demand_form.surface_plot = image.data.url
            this.$message.success("上传成功")

          } else {
            this.$message.warning("上传失败")
          }
        } else {
          this.$message.warning("请上传小于500KB的图片！")
        }
        document.getElementById('up_img_surface_plot').value = null;
      },
      //获取签名
      async content_surface_plot_getSignature() {
          const response = await get_signature()
          if (response.code === 0) {
            this.signature = response.data.signature;
          }
      },
      async content_surface_plot_original_video(e){
        if (e.currentTarget.files[0]) { // 选择了文件
          if (e.currentTarget.files[0].type === 'video/mp4') {
            this.vFile = e.currentTarget.files[0] // 获取上传文件中的File对象信息
            await this.content_surface_plot_getSignature()
            await this.uploadVideoFile_toyun()
          } else {
            this.$message.warning('仅支持mp4格式的视频上传')
          }
        } else { // 取消选择文件
          this.vFile = {}
          this.vIsFileExist = false
        }
        document.getElementById('up_video_surface_plot').value = null;
      },
      //获取视频url地址
      async content_video_url(videoId) {
        const response = await get_videoLink(videoId)
        if (response.code === 0) {
          this.content_video_demand_form.video = response.data.mediaUrl
          let audio = new Audio(response.data.mediaUrl)
          let self = this
          audio.addEventListener("loadedmetadata",function (e){
            self.duration = parseInt(audio.duration)
          })
        }else {
          this.$message.warning(response.message)
        }
      },
      openVideoUrl(url){
        window.open(url)
      },
      // 上传腾讯云
      uploadVideoFile_toyun(){
        let _this = this
        const getSignature = () => {
          return _this.signature;
        }
        const tcVod = new _this.$TcVod({
          getSignature: getSignature // 前文中所述的获取上传签名的函数
        })
        const mediaFile = this.vFile
        this.vUploader = tcVod.upload({
          mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
        })
        this.vUploader.done().then(res => { // 上传完成回调
          //视频上传后获取到的视频fileId
          this.yunVideo_fileId = res.fileId
          this.content_video_url(res.fileId)
          this.$message.success('视频文件上传成功')
        })
      },
      //获取专家列表——上传视频
      async content_video_experts_list(name,pageNum) {
        const response = await get_experts_id(name,pageNum,10)
        if (response.code === 0) {
          // this.video_experts_list = response.data
          //当鼠标滑动到底部时向数组中添加数据
          let list  = response.data
          for (let i=0;i<list.length;i++){
            this.video_experts_list.push({
              id: list[i].id,
              name: list[i].name,
            })
          }
        }else {
          this.$message.warning(response.message)
        }
      },

      video_demand_pagination_Change(page_no,page_size){
        this.video_demand_pagination_page_no = page_no
        this.get_content_video_list(page_no)
      },

      //地点-日程日历展示------------------------------------------------------------------
      close_place_demand(){
        this.content_place_visible = false
        // //清空id,判断修改与新增
        this.place_list_rest_id = ''
        this.content_place_modal_address = ''
        this.mobileId = undefined
      },
      async content_place_modal_Ok() {
        if(this.content_place_modal_address && this.mobileId){

          if(this.place_list_rest_id){
            //修改
            let data = {
              address: this.content_place_modal_address,
              mobileId: this.mobileId
            }
            const response = await putPlaceList(this.place_list_rest_id,data)
            if(response.code === 0){
              this.$message.success("修改成功")
              //刷新列表
              await this.get_place_list_address()
              //关闭
              this.content_place_modal_visible = false
              this.content_place_modal_address = ''
              this.mobileId = undefined
              this.place_list_rest_id = ''
            }else {
              this.$message.warning("失败"+response.message)
            }
          }else {
            //新增
            let data = {
              address: this.content_place_modal_address,
              mobileId:this.mobileId,
              conventionId: this.$route.query.id
            }
            const response = await postPlaceList(data)
            if(response.code === 0){
              this.$message.success("新增成功")
              //刷新列表
              await this.get_place_list_address()
              //关闭
              this.content_place_modal_visible = false
              this.content_place_modal_address = ''
              this.mobileId = undefined
              this.place_list_rest_id = ''
            }else {
              this.$message.warning("失败"+response.message)
            }
          }
          // this.$message.warning("表单有空~")
        }else {
          this.$message.warning("表单有空~")
        }
      },

      place_list_rest(row){
        this.content_place_modal_visible = true
        this.content_place_modal_address = row.address
        this.mobileId = row.mobileId
        this.place_list_rest_id = row.id
      },
      async place_list_del(id) {
        const response = await del_CalendarCalendar_AddressList(id)
        if (response.code === 0) {
          this.$message.success("删除成功 ！")
          await this.get_place_list_address()
        } else {
          this.$message.warning("失败" + response.message)
        }
      },
      async moveAddress(id, down) {
        const response = await UpOrDown_AddressList(id,down)
        if (response.code === 0) {
          this.$message.success("操作成功 ！")
          await this.get_place_list_address()
        } else {
          this.$message.warning(response.message)
        }
      },

      // 获取列表内容-轮播广告---LBGG
      async getLBGGList() {
        this.spinning = true
        const response = await get_content_ByAdvertising(this.$route.query.id)
        if (response.code === 0) {
          this.content_ByAdvertising_data = response.data
        } else {
          this.$message.warning("失败"+ response.message)
        }
        this.spinning = false
      },
      //获取列表内容-字幕文字通知---ZMTZ
      async getZMTZList() {
        this.spinning = true
        const response = await get_content_alphabetic_list(this.$route.query.id)
        if (response.code === 0) {
          this.content_alphabetic_data = response.data
        } else {
          this.$message.warning("失败"+ response.message)
        }
        this.spinning = false
      },
      //获取列表内容-图文链接展示---YZT
      async getYZTList() {
        this.spinning = true
        const response = await get_content_GraphicLinks_list(this.$route.query.id,this.funcId)
        if (response.code === 0) {
          this.content_GraphicLinks_data = response.data
        }
        this.spinning = false
      },
      //获取时间  内容-日程日历展示
      async getCalendarCalendar() {
        const response = await get_CalendarCalendar_timeList(this.$route.query.id,this.funcId)
        if (response.code === 0) {
          this.content_CalendarCalendar_timeList_data = response.data
        //  获取第一个时间
          this.firstTime = response.data[0].beginDate
        } else {
          this.$message.warning("失败"+ response.message)
        }
      },
      //获取列表   内容-日程日历展示
      async getCalendarCalendar_list(beginDate) {
        this.spinning = true
        const response = await get_CalendarCalendar_timeList_listTable(this.$route.query.id,beginDate,this.funcId,this.firstAdressId)
        if (response.code === 0) {
          this.content_CalendarCalendar_data = response.data
          let form = this.content_CalendarCalendar_data
          if(form.length){   //初始赋值
            this.selectIndex = form[0].id
            this.selectIndex = form[0].id
            this.fartherList = form[0]
            //获取父级的id--日期
            this.farther_id = form[0].beginDate
            //  父级会议地点
            this.farther_id_addressId = form[0].siteId
          }
          // for(let i=0;i<form.length;i++){
          //   Vue.set(form[i],"childrens",[])
          // }
        }
        // else {
        //   this.content_CalendarCalendar_data = []
        // }
        this.spinning = false
      },
      //获取地点
      async getCalendarCalendar_addressList() {
        // this.spinning = true
        const response = await get_CalendarCalendar_AddressList(this.$route.query.id)
        if (response.code === 0) {
          this.CalendarCalendar_AddressList = response.data
          let arr = response.data
          if(arr.length){
            this.firstAdressId = response.data[0].id
          }else {
            this.$message.warning("会议地点为空，无法设置日程!")
          }
        } else {
          this.$message.warning("会议地点为空，无法设置日程!")
        }
        // this.spinning = false
      },
      //获取主持讲者
      async getCalendarCalendar_chairmanList(){
        this.CalendarCalendar_chairmanList = []
        let data = {
          conventionId:this.$route.query.id,
          page_size:999
        }
        const response = await get_CalendarCalendar_chairmanList(data)
        if (response.code === 0) {
          let list= response.data.rows
           for (let i=0; i<list.length; i++){
             let vote = {}
               vote.id = list[i].id
               vote.name = list[i].name
               vote.expertId = list[i].expertId
               this.CalendarCalendar_chairmanList.push(vote)
         }
        } else {
          this.$message.warning("会议主持讲者为空，无法设置详细日程!")
        }
      },
      //获取企业海报列表
      async getEnterprisePosters_list(page_no,page_size) {
        this.spinning = true
        const response = await getEnterprisePosters(this.$route.query.id,this.funcId,page_no,page_size)
        if (response.code === 0) {
          this.EnterprisePosters_list_data = response.data.rows
        } else {
          this.$message.warning("失败"+response.message)
        }
        this.spinning = false
      },
      //获取列表
      async getLiveBroadcast_list(page_no) {
        this.spinning = true
        const response = await getLiveBroadcast(this.$route.query.id,page_no)
        if (response.code === 0) {
          this.content_LiveBroadcast_data = response.data.rows
          this.content_LiveBroadcast_data_total = response.data.count
        } else {
          this.$message.warning("失败"+response.message)
        }
        this.spinning = false
      },
      async getLiveBroadcast_list_one(id) {
        this.spinning = true
        const response = await getOneLiveList(id)
        if (response.code === 0) {
          this.content_LiveBroadcast_data_one = response.data
        } else {
          this.$message.warning("失败"+response.message)
        }
        this.spinning = false
      },
      //获取直播间列表---单个会议下的
      async getLiveRoom(startDate) {
        this.spinning = true
        let data = {
          // conventionId:this.$route.query.id,
          liveMettingId:this.content_CalendarCalendar_form.liveMeetingId,
          page_size: 999,
          liveDate:this.content_CalendarCalendar_form.liveTime,
        }
        const response = await getSetLiveList_feature(data)
        if (response.code === 0) {
          this.live_Room = response.data
        } else {
          this.$message.warning(response.message)
        }
        this.spinning = false
      },
      //内容对话框---------------------------------------------------------------
      async contentBtn(list) {
        //获取功能id
        this.funcId = list.id
        switch (list.funcCode) {
          case 'DBHF':
            this.$message.info("请去点播视频管理中维护~")
            // this.get_content_video_list()
            // this.content_video_demand_visible = true
            break;
          case 'LBGG':
            this.content_ByAdvertising_visible = true
            this.getLBGGList()
            break;
          case 'ZMTZ':
            this.content_alphabetic_visible = true
            this.getZMTZList()
            break;
          case 'YZT':
            this.content_GraphicLinks_visible = true
            this.getYZTList()
            break;
          case 'HYC':
            this.picture_id = list.id
            this.picture_visible = true
            //  点击获取内容
              await this.getFeatureListOne(list.id)
            if(this.dataListOne.content){
              this.picture_form.picture = this.dataListOne.content.value
            }
            break;
          case 'HTML':
            this.HTML_id = list.id
            this.content_LinkShow_visible = true
            //  点击获取内容
            await this.getFeatureListOne(list.id)
            this.content_LinkShow_dataVal = this.dataListOne.url
            break;
          case 'HYTZ':
            this.HYTZ_id = list.id
            this.content_pdf_visible = true
            //  点击获取内容
            await this.getFeatureListOne(list.id)
            if(this.dataListOne.content){
            this.content_pdf_name = this.dataListOne.content.value}
            break;
          case 'DHZB':
            this.$router.push({path:'/featureDHZB',query:{id:this.$route.query.id}})
            // this.content_LiveBroadcast_visible = true
            //   this.getLiveBroadcast_list()
            break;
          case 'RCYL':
            this.content_CalendarCalendar_visible = true
            // //  获取直播间列表
            //   this.getLiveRoom()
            //  获取地址
            await this.getCalendarCalendar_addressList()
            //  主持讲者
            this.getCalendarCalendar_chairmanList()

            await this.getCalendarCalendar()
            //  获取列表   内容-日程日历展示
            await this.getCalendarCalendar_list(this.firstTime)
            //  获取二级学术列表
            if(this.content_CalendarCalendar_data.length){

              await this.get_two_list(this.content_CalendarCalendar_data[0].id)
            }else {
              this.content_CalendarCalendar_data_two = []
            }
            break;
          case 'ZCJZ':
            this.content_LiveBroadcastCalendar_list_visible = true
              this.getLiveBroadcastCalendar_list_list()
            // this.content_LiveBroadcastCalendar_visible = true
            break;
          case 'QYHB':
            this.meetList = [];
            this.getMeetList()
            this.getFirmList()
            this.enterprisePosters = true
            // this.content_EnterprisePosters_list_visible = true
            // this.getEnterprisePosters_list()
            //   this.cardId = list.id
            break;

          default:
            break;
        }
      },
      //关联
      relevanceBtn(list,liveMeetId){
        if(liveMeetId){
        //  已关联
          //  通过判断url中有没有conventionId来判断勾选状态  -- 有 :未勾选  --无: 勾选  默认勾选
          this.LiveBroadcastForm.isCheck = list.url === null ? ['保留大会直播界面']:[]
        }else {
        //  未关联
          this.LiveBroadcastForm.isCheck = ['保留大会直播界面']
        }
        //获取大会直播列表
        this.liveMeetId = liveMeetId
        this.getLiveBroadcast(1,liveMeetId)
        this.conferenceLiveBroadcastVisible = true
        this.LiveBroadcastForm.conferenceLiveBroadcastValue = list.liveMeetingId !== null ? list.liveMeetingId : undefined

      },
      //获取大会直播列表
      async getLiveBroadcast(pageNo,liveMeetId) {
        let data = {
          page_no:pageNo,
          liveMeetId:liveMeetId
        }
        const response = await getLiveBroadcastLists(data)
        if (response.code === 0) {
        //当鼠标滑动到底部时向数组中添加数据
        let list  = response.data
        for (let i=0;i<list.length;i++){
          this.conferenceLiveBroadcast.push({
            id: list[i].id,
            title: list[i].title,
          })
        }
        } else {
          this.$message.warning(response.message)
        }
      },
      //滚动条监听
      popupScroll_LiveBroadcast(e){
        const {target} = e
        const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
        const clientHeight = target.clientHeight //滑动最低高度
        if(scrollHeight < clientHeight + 2){
          this.conferenceLiveBroadcast_pageNo++
          this.getLiveBroadcast(this.conferenceLiveBroadcast_pageNo, this.liveMeetId)
        }
      },
      //鼠标搜索
      // select_LiveBroadcast(value){
      //   //首先清空数组，否则会数据重复
      //   this.video_experts_list = []
      //   this.conferenceLiveBroadcast_title = value
      //   this.getLiveBroadcast(value,'')
      // },
      LiveBroadcastChange(){

      },
      onSubmit_LiveBroadcast(){
        this.$refs.ruleFormLiveBroadcast.validate(async valid => {
          if (valid) {
            await this.relevanceLiveBroadcast()
            this.conferenceLiveBroadcast = []
            this.conferenceLiveBroadcastVisible = false
            this.conferenceLiveBroadcast_pageNo = 1
            this.$refs.ruleFormLiveBroadcast.resetFields();
            this.$route.query.liveMeetingId = this.LiveBroadcastForm.conferenceLiveBroadcastValue
          //  刷新列表
            await this.getFeatureList()
          } else {
            return false;
          }
        });
      },
      //关联会议
      async relevanceLiveBroadcast() {
        // let data = {
        //   conventionId:this.$route.query.id,
        //   liveMeetId:this.LiveBroadcastForm.conferenceLiveBroadcastValue
        // }
        const response = await putLiveBroadcastLists(this.$route.query.id,this.LiveBroadcastForm.conferenceLiveBroadcastValue,this.LiveBroadcastForm.isCheck.length === 0 ? 0:1)
        if (response.code === 0) {
          this.$message.success(response.message)
        } else {
          this.$message.warning(response.message)
        }
      },
      onClose_LiveBroadcast(){
        this.conferenceLiveBroadcast = []
        this.conferenceLiveBroadcastVisible = false
        this.conferenceLiveBroadcast_pageNo = 1
        this.$refs.ruleFormLiveBroadcast.resetFields();
      },
      //设置
      setBtn(list) {
        this.set_form.isNeedLogin = list.isNeedLogin
        this.set_form.isShowApplyBar = list.isShowApplyBar
        this.set_form.isNeedPay = list.isNeedPay

        this.set_visible = true
        this.set_id = list.id
        this.card_list_content = list
      },
      //获取片头片尾
      async getStartEndList(type,pageNum,pageSize) {
        const response = await get_startEnd(type,pageNum,pageSize)
        if(response.code === 0){
          if(type === 'start'){
            this.startList = response.data
          }else {
            this.endList = response.data
          }
        }else {
          this.$message.warning(response.message)
        }
      },
      //人数
      async per_OfThe_ok() {
        let data = {
          "limitEnrollCount":this.per_OfThe_input
        }
        const response = await put_per_OfThe_one_list_ofNumber(this.$route.query.id,data)
        if (response.code === 0) {
          this.$message.success("操作成功！")
          this.per_OfThe_visible = false
        } else {
          this.$message.warning("失败"+ response.message)
        }
      },
      //获取单个会议详情
      async getOneLiveContent() {
        const response = await get_per_OfThe_one_list(this.$route.query.id)
        if (response.code === 0) {
          this.per_OfThe_input = response.data.limitEnrollCount

        } else {
          this.$message.warning("失败"+response.message)
        }
      },
      //人数-----------------------------------------------------------------
      numberBtn(list){
        switch (list.funcCode){
          case 'ZCJF':
            // this.per_OfThe_input = list.
            this.per_OfThe_visible = true
            //  给input赋值
            this.getOneLiveContent()
            break;
          default:
            break;
        }
      },
      //数据
      dataBtn(){

      },

      //获取列表--名单-学分注册----------------------------------------
      async get_List_credit_registration_list(page_no) {
        this.spinning = true
        //  获取大会的会议日期，用于展示大会的每日观看时长
        let fartherLive = JSON.parse(localStorage.getItem('convertionImg'))
        //  beginDate -- endDate  向表格头部插入日期标题
        this.editCoumns(fartherLive.beginDate,fartherLive.endDate)

        //获取表格数据
        let data = {
          page_no:page_no,
          conventionId:this.$route.query.id,
          name:this.List_credit_registration_name,
          telephone:this.List_credit_registration_telephone,
          type:this.radioGroup
        }
        const response = await get_credit_registration_lists(data)
        if (response.code === 0) {
          // this.List_credit_registration_data = response.data.rows
          //向表格数据中添加数据（通过id和日期）
          let arr = response.data
          for (let i=0; i<arr.length; i++){
            // 向每条数据中加入相应日期的观看量
            for(let j=0; j<this.ViewingDurationCount; j++){
              // this.$set(arr[i], 'ViewingDuration'+j,arr[i].duration[j]);
              // this.$set(arr[i], 'ViewingDuration'+j,current_times_min(arr[i].duration[j]));
              this.$set(arr[i], 'ViewingDuration'+j,arr[i].duration[j]);
            }
            // 每条数据中的总时长 【sumDuration 之和】
            // this.$set(arr[i], 'sumDuration',current_times_min(this.sum(arr[i].duration)));
            this.$set(arr[i], 'sumDuration',this.sum(arr[i].duration));

            // let getDateNum = await this.getView(arr[i].memberId)
            // for(let k=0; k < getDateNum.length; k++){
            //   this.$set(arr[i], 'ViewingDuration'+k,getDateNum[k]);
            // }
            // this.$set(arr[i], 'sumDuration',this.sum(getDateNum));
          }
          this.List_credit_registration_data = arr
          // console.log('arr',arr)
          this.List_credit_registration_count = response.count
        } else {
          this.$message.warning(response.message)
        }
        this.spinning = false
      },
      sum(arr) {
        var sum = 0;
        for (var i = 0; i < arr.length; i++) {
          sum += arr[i];
        }
        return sum;
      },
      List_credit_registration_pagination_Change(page_no){
        this.List_credit_registration_page_no = page_no
        this.get_List_credit_registration_list(page_no,
            this.List_credit_registration_name,
            this.List_credit_registration_telephone)
      },
      List_credit_registration_selectBtn(){
        //判断有没有选择计算时长
        if(this.radioGroup){
          this.get_List_credit_registration_list()
        }else {
         this.$message.warning("查看数据时请选择计算时长~")
        }
      },
      List_credit_registration_restBtn(){
        if(this.radioGroup){
          this.List_credit_registration_name = ''
          this.List_credit_registration_telephone = ''
          // this.restColumns()
          this.get_List_credit_registration_list()
        }else {
          this.$message.warning("查看数据时请选择计算时长~")
        }
      },
      // async List_credit_registration_deriveBtn() {
      //   let data = {
      //     "conventionId": this.$route.query.id,
      //     "name": this.List_credit_registration_name,
      //     "telephone": this.List_credit_registration_telephone,
      //     "ExelName":"学分注册"
      //   }
      //   await batch_export_exal(data)
      // },
      //通过id与日期获取当前日期的观看时长

      async List_credit_registration_deriveBtn() {
        if(this.radioGroup){
          let data = {
            page_no:1,
            conventionId:this.$route.query.id,
            type:this.radioGroup,
            page_size:999999
          }
          const response = await get_credit_registration_lists(data)
          let exc_arr = response.data
          //通过for循环将数据转换成以下格式
          // let exc_data=[
          //   ['第一列', '第二列' ,'第三列'],
          //   ['aa', 'bb' ,'cc'],
          //   ['dd', 'ee' ,'ff'],
          // ]
          let exc_data = [];
          let arr1 = ['姓名', '手机号' ,'省份/城市','医院','科室','职称','注册时间'];
          for (let k=0; k<this.ViewingDurationCount; k++){
            arr1.push(this.ViewingDurationDate[k])
          }
          arr1.push('总时长（秒）')
          exc_data[0] = arr1 // 头部标题

          let arr_son = []; // 内容
          for (let i=0; i<exc_arr.length; i++){
            arr_son = [exc_arr[i].name,exc_arr[i].telephone,exc_arr[i].province,exc_arr[i].hospitalName,exc_arr[i].hospitalOffices,exc_arr[i].jobTitle,exc_arr[i].memberCreatedTime];

            for (let j=0; j<this.ViewingDurationCount; j++){
            // await this.getView(exc_arr[i].memberId,this.ViewingDurationDates[j])
            // arr_son.push(current_times_min(exc_arr[i].duration[j]))
            arr_son.push(exc_arr[i].duration[j])
            }
            arr_son.push(this.sum(exc_arr[i].duration))
            exc_data[i+1] = arr_son
            // let getDateNum = this.getView(exc_arr[i].memberId)
            // for(let k=0; k<getDateNum.length; k++){
            //   arr_son.push(getDateNum[k])
            // }
            // arr_son.push(this.sum(getDateNum))
            // exc_data[i+1] = arr_son

          }
          let groups = '';
          if(this.radioGroup === 'handMovement'){
          //  手动上报
            groups = '手动上报'
          }else if(this.radioGroup === 'voluntarily'){
          //  自动上报
            groups = '自动上报'
          }
          exportExcel(groups+'名单-学分注册',exc_data);
        }else {
          this.$message.warning("导出数据时请选择计算时长~")
        }
      },

      //获取列表--名单-注册缴费----------------------------------------
      async get_Tuition_fees_register(page_no) {
        this.spinning = true
        let data = {
          page_no:page_no,
          conventionId:this.$route.query.id,
          name:this.Tuition_fees_register_name,
          mobilePhone:this.Tuition_fees_register_telephone
        }
        const response = await get_Tuition_fees_register_list(data)
        if (response.code === 0) {
          this.Tuition_fees_register_data = response.data.rows
          this.Tuition_fees_register_count = response.data.count
        } else {
          this.$message.warning("失败"+ response.message)
        }
        this.spinning = false
      },
      Tuition_fees_register_pagination_Change(page_no){
        this.List_Tuition_fees_register_page_no = page_no
        this.get_Tuition_fees_register(page_no,
            this.Tuition_fees_register_name,
            this.Tuition_fees_register_telephone)
      },
      Tuition_fees_register_selectBtn(){
        this.get_Tuition_fees_register(
            this.List_Tuition_fees_register_page_no,
            this.Tuition_fees_register_name,
            this.Tuition_fees_register_telephone)
      },
      Tuition_fees_register_restBtn(){
        this.Tuition_fees_register_name = ''
        this.Tuition_fees_register_telephone = ''
        this.get_Tuition_fees_register(
            this.List_Tuition_fees_register_page_no,
            this.Tuition_fees_register_name,
            this.Tuition_fees_register_telephone)
      },
      async Tuition_fees_register_deriveBtn() {
        let data = {
          "conventionId": this.$route.query.id,
          "name": this.Tuition_fees_register_name,
          "mobilePhone": this.Tuition_fees_register_telephone,
          "ExelName":"注册缴费"
        }
        const responsse = await batch_export_exal_register(data)
      },
      Tuition_fees_register_confirm(){
        this.$message.warning("还没有此项功能")
      },
      //获取某个时间段中有几天  按日查询
      // 获取每一天
      dealDate(start,end){
        let dateList = [];
        let dateListDate = [];
        var startTime = new Date(start);
        var endTime = new Date(end);

        while ((endTime.getTime() - startTime.getTime()) >= 0) {
          var year = startTime.getFullYear();
          var month = startTime.getMonth() + 1 < 10 ? '0' + (startTime.getMonth() + 1) : startTime.getMonth() + 1;
          var day = startTime.getDate().toString().length == 1 ? "0" + startTime.getDate() : startTime.getDate();
          // dateList.push(year + "/" + month + "/" + day);
          dateList.push(month + '月' + day + '日观看时长（秒）');
          dateListDate.push(year + '-' + month + '-' + day);
          startTime.setDate(startTime.getDate() + 1);
        }
        this.ViewingDurationDate = dateList
        this.ViewingDurationDates = dateListDate
        // return dateList;
      },
      editCoumns(beginDate,endDate){
        //重置
        this.restColumns()
        //获取时间数组
        this.dealDate(beginDate,endDate)
        let arr = this.ViewingDurationDate
        let i=0;
        for (i; i<arr.length; i++){
          //所有日期
          this.List_credit_registration_columns.push({
            title: arr[i],
            dataIndex: 'ViewingDuration'+i,
            key: 'ViewingDuration'+i,
            scopedSlots: { customRender: 'ViewingDuration'+i},
          })
        }
        //总时长
        this.List_credit_registration_columns.push({
          title: '总时长（秒）',
          dataIndex: 'sumDuration',
          key: 'sumDuration',
          // scopedSlots: { customRender: 'sumDuration'},
        })
        //  向表格中添加日期的个数
        this.ViewingDurationCount = i
      },
      //通过id,日期获取观看量
      getView(memberId, dateTime) {
        // return 1
        return this.selDurationZD(memberId, dateTime)
        // const response = await selDurationZD(memberId, this.$route.query.id)
        // // if (response.code === 0) {
        //   let stringArr = response.data
        //   console.log('stringArr',stringArr)
        //   return stringArr
        // // }

      },
      async selDurationZD(memberId, dateTime) {
        this.spinning = true
        const response = await selDurationZD(memberId, this.$route.query.id)
        if (response.code === 0) {
         let stringArr = response.data
          return stringArr
        }
        this.spinning = false
      },
      registrationClose(){
        this.List_credit_registration_visible = false;
        this.List_credit_registration_telephone = ''
        this.List_credit_registration_name = ''
        //清空table
        this.List_credit_registration_data = []
        this.radioGroup = 'voluntarily'
        this.restColumns()
      },
      //重置colunm
      restColumns(){
        this.List_credit_registration_columns =  [
          {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            scopedSlots: { customRender: 'name' },
          },
          {
            title: '手机号',
            dataIndex: 'telephone',
            key: 'telephone',
            fixed: 'left',
            scopedSlots: { customRender: 'telephone' },
          },
          {
            title: '省份/城市',
            dataIndex: 'province',
            key: 'province',
            scopedSlots: { customRender: 'province' },
          },
          {
            title: '医院',
            dataIndex: 'hospitalName',
            key: 'hospitalName',
            scopedSlots: { customRender: 'hospitalName' },
          },
          {
            title: '科室',
            dataIndex: 'hospitalOffices',
            key: 'hospitalOffices',
            scopedSlots: { customRender: 'hospitalOffices' },
          },
          {
            title: '职称',
            dataIndex: 'jobTitle',
            key: 'jobTitle',
            scopedSlots: { customRender: 'jobTitle' },
          },
          {
            title: '注册时间',
            dataIndex: 'memberCreatedTime',
            key: 'memberCreatedTime',
            scopedSlots: { customRender: 'memberCreatedTime' },
          },
        ]
      },
      //名单---------------------------------------------------------
      nameBtn(list){
        switch (list.funcCode){
          case 'XFSJ':
            //学分注册
            this.List_credit_registration_visible = true
            this.get_List_credit_registration_list(this.List_credit_registration_page_no)
            break;
          case 'ZCJF':
            //注册缴费
            this.Tuition_fees_register_visible = true
            this.get_Tuition_fees_register(this.List_Tuition_fees_register_page_no)
            break;
          default:
            break;
        }
      },
      //时长
      timeBtn(list){
        this.$router.push({path:'/featureDuration',query:{id:this.$route.query.id}})
      },
      //配置
      configurationBtn(list){
        this.listId = list.id
        if(list.content){
          let form = this.configuration_form;
          form.remark = list.content.remark;
          form.credit = list.content.value;
          form.limitEnrollCount = list.content.limitEnrollCount ? list.content.limitEnrollCount:0 ;
        }
        this.configuration_visible = true
      },
      //学分配置取消按钮
      configurationClose(){
        this.$refs.configuration_ruleForm.resetFields();
        this.configuration_visible = false
      },
      //学分配置确定按钮
      configurationSubmit(){
        this.$refs.configuration_ruleForm.validate(valid => {
          if (valid) {
            let form = this.configuration_form
            if(form.credit || form.remark){
              // 学分注册配置
              this.putConfiguration(form)
              this.configuration_visible = false
            }else {
              this.$message.warning("有空~")
            }
          } else {
            return false;
          }
        });
      },
      //学分注册配置
      async putConfiguration(form) {
        // let data = {
        //   funcId:this.listId,
        //   value:form.credit,
        //   remark:form.remark
        // }
        const response = await putUpdXFZC(this.listId,form.credit,form.remark,form.limitEnrollCount)
        if (response.code === 200) {
          this.$message.success(response.message)
          //  刷新列表
          await this.getFeatureList()
        } else {
          this.$message.warning(response.message)
        }
      },
      //点播回放点击时长修改时长开启状态
      timeDBHFBtn(list){
        this.durationRemark = list.durationRemark
        this.timeOpenIsOpen = list.vodIsOpenDuration !==null ? list.vodIsOpenDuration ? true:false : false
        this.timeOpenList = list
        this.timeOpen_visible = true
      },
      //获取会议地点列表
      async get_place_list_address() {
        const response = await getPlaceList(this.$route.query.id)
        if (response.code === 0) {
          this.place_list_data = response.data
        } else {
          this.$message.warning("失败"+ response.message)
        }
      },
      //地点
      addressBtn(list){
        switch (list.funcCode){
          case 'RCYL':
            this.content_place_visible = true
              this.get_place_list_address()
            break;
          default:
            break;
        }
      },
      returnBtn(){
        window.history.back()
      },
      openLinkUrl(row){
        window.open(row.targetUrl)
      },
      delPicListBtn(index){
        this.picture_form.picture.splice(index,1)
      },
      timeOpen(){
        this.timeOpenIsOpen = 0;
        this.durationRemark = '';
      },
      timeOpenChange(isOpen){
        this.timeOpenIsOpen = !isOpen
      },
      async timeOpenOk() {
        const response = await updVodIsOpenDuration(this.timeOpenList.id,this.timeOpenIsOpen ? 1 : 0,this.durationRemark)
        if (response.code === 200) {
          this.$message.success("修改成功~")
          await this.getFeatureList()
        } else {
          this.$message.warning(response.message)
        }
        this.timeOpen_visible = false
      },
      addBtn(){
        this.LiveBroadcastCalendar_pagination_onSubmit_name = ''
        this.expertForm={
          formType:'add',
          formShow:true,
        }
      },
      async listPersons() {
        this.listPageNo = 1
        await this.get_columnsList()
        this.ExcelMember = true
      },
      async get_columnsList() {
        let data = {
          page_no:this.listPageNo,
          page_size:this.listpageSize,
          telephone:this.listTel,
          conventionId:this.$route.query.id
        }
        const response = await get_credit_registration_list(data)
        if (response.code === 0) {
          this.dataLists = response.data
          this.listCount = response.count
        } else {
          this.$message.warning(response.message)
        }
      },
      excelClose(){
        this.ExcelMember = false;
        this.listPageNo = 1;
        this.listpageSize = 10;
        this.listTel = '';
        this.changeFile = '';
        this.formData = {}
      },
      async changeFileElsx() {
        let inputDOM = this.$refs.uploadFile_inputFile.files[0];
        let formData = new FormData()
        formData.append('file', inputDOM)
        this.changeFile = inputDOM.name
        this.formData = formData
        // console.log(this.formData)
      },
      async importAddFile() {
        const response = await postUploadFile(this.$route.query.id,this.formData)
        if (response.code === 200) {
          //上传成功后将value制空防止上传一样名称文件时不触发change
          document.getElementById('uploadFile').value = null;
          //刷新列表
          await this.get_columnsList()
          this.$message.success(response.message)
        } else {
          this.$message.warning(response.message)
        }
      },
      async DownloadTemplates() {
        let data = {
          ExelName:'人员名单模板',
        }
        await getImportExcelMemberTemplate(data)
      },
      inquireBtn(){
        this.listPageNo = 1
        this.listpageSize = 10
        this.get_columnsList()
      },
      addPerson(){ //人员名单新增
        this.addPersonDisabled = true
        this.addPersonVisile = true
      },
      async confirmDelList(id) {
        const response = await delMember(id)
        if (response.code === 200) {
          //刷新列表
          await this.get_columnsList()
          this.$message.success("操作成功")
        } else {
          this.$message.warning("出错，请联系管理员", response.message)
        }
      },
      templateChange(pagNo,pageSize){
        this.listPageNo = pagNo
        this.listpageSize = pageSize
        this.get_columnsList()
      },
      templateChangeSize(current, size){
        this.listPageNo = current
        this.listpageSize = size
        this.get_columnsList()

      },
      addPersonClose(){ //人员名单新增-关闭对话框
        this.addPersonVisile = false
        // 切换身份时清空数据
        this.personnelListForm = {
          type:"1001",
          province:undefined,
          hospitalName:undefined,
          school:undefined,
          certStatus:"UNCERTIFIED",
          registerChannel:"后台添加",
        }
        this.hospitalNameList = [];
        this.schoolListData = [];
        this.hosNamePageNo = 1;
        this.schoolNamePageNo = 1;
        this.hosName = '';
        this.schoolName = '';
      },
      onSearchs(value){
        if(value.length === 11){
          this.echo(value)
        }else {
          this.$message.warning("请规范输入手机号~")
        }
      },
      async echo(value) {
        let data = {
          tel: value,
        }
        const response = await getMemberList(data)
        if (response.code === 0) {
          if (response.count) {
            //  获取用户信息并赋值
            let perDate = response.data[0]
            // 设置数据
            perDate.province = perDate.province?[perDate.province,perDate.city]:[]
            perDate.hospitalOffices = perDate.hospitalOffices?perDate.hospitalOffices.split('-'):null
            if(perDate.name !== '未完善'){
              document.getElementById('name').style.color = ''
            }else {
              document.getElementById('name').style.color = 'red'
            }
            this.personnelListForm = perDate
            // console.log( this.personnelListForm )
          } else {
            this.$message.warning("该用户不存在请添加~")
            this.personnelListForm = {
              type:'1006',
              tel:this.personnelListForm.tel,
              province:undefined,
              hospitalName:undefined,
              school:undefined,
              certStatus:"UNCERTIFIED",
              registerChannel:"后台添加",
              name:'未完善'
            }
            document.getElementById('name').style.color = "red"
          }
        } else {
          this.$message.warning(response.message)
        }
      },
      selectNumberType(value) {
        this.personnelListForm.type = value
        this.$set(this.personnelListForm,'jobTitle',undefined)
        //其他医务从业者--医务从业者不需要选择省份城市
        if (value !== '1006' || value !== '1007') {
          this.getJobTitleList(value)
        }
        if(this.personnelListForm.province){
          this.provinceChange(this.personnelListForm.province)
        }
      },
      nameChange(e){
        if(e.target.value !== '未完善'){
          document.getElementById('name').style.color = ''
        }else {
          document.getElementById('name').style.color = 'red'
        }
      },
      provinceChange(value) {
        this.hosNamePageNo = 1
        this.schoolNamePageNo = 1
        //更改省份城市后清空医院或者学校
        let form = this.personnelListForm
        if(!this.addPersonDisabled){ //当表单在能编辑状态下，选择省份后清空医院或者学校
          form.hospitalName = undefined
          this.hospitalNameList = []
          this.schoolListData = []
          form.school = undefined
        }
        if(form.type === '1001' || form.type === '1002' || form.type === '1003' || form.type === '1004'){
          let data = {
            province:value[0],
            city:value[1],
            pagenum:this.hosNamePageNo,
            name:this.hosName
          }
          this.getHosNameList(data)
        }else if(form.type === '1005'){
          let data = {
            province:value[0],
            city:value[1],
            pagenum:this.schoolNamePageNo,
            name:this.schoolName
          }
          this.getschoolList(data)
        }
      },
      //获取医院列表
      async getHosNameList(data) {
        const response = await getHospital(data)
        if(response.code === 0){
          let list  = response.data.rows
          for (let i=0;i<list.length;i++){
            this.hospitalNameList.push({
              id: list[i].id,
              name: list[i].name,
            })
          }
        }
      },
      //滚动条监听
      popupScrollHos(e){
        const {target} = e
        const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
        const clientHeight = target.clientHeight //滑动最低高度
        if(scrollHeight < clientHeight + 2){
          this.hosNamePageNo++
          let value = this.personnelListForm.province
          let data = {
            province:value[0],
            city:value[1],
            pagenum:this.hosNamePageNo,
            name:this.hosName
          }
          this.getHosNameList(data)
        }
      },
      //鼠标搜索
      selectHos(value){
        //首先清空数组，否则会数据重复
        this.hospitalNameList = []
        this.hosName = value
        this.hosNamePageNo = 1
        let values = this.personnelListForm.province
        let data = {
          province:values[0],
          city:values[1],
          pagenum:this.hosNamePageNo,
          name:value
        }
        this.getHosNameList(data)
      },
      //获取学院列表
      async getschoolList(data) {
        const response = await getSchool(data)
        if(response.code === 0){
          let list  = response.data.rows
          for (let i=0;i<list.length;i++){
            this.schoolListData.push({
              id: list[i].id,
              name: list[i].name,
            })
          }
        }
      },
      //滚动条监听
      popupScrollSchool(e){
        const {target} = e
        const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
        const clientHeight = target.clientHeight //滑动最低高度
        if(scrollHeight < clientHeight + 2){
          this.schoolNamePageNo++
          let value = this.personnelListForm.province
          let data = {
            province:value[0],
            city:value[1],
            pagenum:this.schoolNamePageNo,
            name:this.schoolName
          }
          this.getschoolList(data)
        }
      },
      //鼠标搜索
      selectSchool(value){
        //首先清空数组，否则会数据重复
        this.schoolListData = []
        this.schoolName = value
        this.schoolNamePageNo = 1
        let values = this.personnelListForm.province
        let data = {
          province:values[0],
          city:values[1],
          pagenum:this.schoolNamePageNo,
          name:value
        }
        this.getschoolList(data)
      },
      editPerBtn(){
        this.addPersonDisabled = false
        // document.getElementById('name').style.color = "black"
        if(this.personnelListForm.province){
          // this.provinceChange(this.personnelListForm.province)

          //更改省份城市后清空医院或者学校
          let form = this.personnelListForm
          if(form.type === '1001' || form.type === '1002' || form.type === '1003' || form.type === '1004'){
            let data = {
              province:form.province[0],
              city:form.province[1],
              pagenum:this.hosNamePageNo,
              name:this.hosName
            }
            this.getHosNameList(data)
          }else if(form.type === '1005'){
            let data = {
              province:form.province[0],
              city:form.province[1]
            }
            this.getschoolList(data)
          }
        }
      },
      async saveEditPer() {
        this.$refs.PersonnelListRef.validate(async valid => {
          if (valid) {
            let form = this.personnelListForm
            let data = {};
            switch (form.type) {
              case '1001': {
                data = {
                  "type": form.type,
                  "name": form.name,
                  "tel": form.tel,

                  "province": form.province ? form.province[0] : null,
                  "city": form.province ? form.province[1] : null,

                  "hospitalName": form.hospitalName,
                  "hospitalOffices": form.hospitalOffices ? form.hospitalOffices[0] + '-' + form.hospitalOffices[1] : null,
                  "jobTitle": form.jobTitle,

                  // "doctorNum": form.doctorNum,
                  "sex": form.sex,
                  "email": form.email,
                  "birthday": form.birthday,
                  "certStatus": form.certStatus,
                  "registerChannel": form.registerChannel,
                  "nvqUrl": '',
                }
                break;
              }
              case '1002': {
                data = {
                  "type": form.type,
                  "name": form.name,
                  "tel": form.tel,
                  "hospitalName": form.hospitalName,
                  "hospitalOffices": form.hospitalOffices ? form.hospitalOffices[0] + '-' + form.hospitalOffices[1] : null,
                  "jobTitle": form.jobTitle,

                  "province": form.province ? form.province[0] : null,
                  "city": form.province ? form.province[1] : null,

                  // "doctorNum": form.doctorNum,
                  "sex": form.sex,
                  "email": form.email,
                  // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                  "birthday": form.birthday,
                  "certStatus": form.certStatus,
                  "registerChannel": form.registerChannel,
                  "nvqUrl": '',
                }
                break;
              }
              case '1003': {
                data = {
                  "type": form.type,
                  "name": form.name,
                  "tel": form.tel,
                  "hospitalName": form.hospitalName,
                  "hospitalOffices": form.hospitalOffices ? form.hospitalOffices[0] + '-' + form.hospitalOffices[1] : null,
                  "jobTitle": form.jobTitle,

                  "province": form.province ? form.province[0] : null,
                  "city": form.province ? form.province[1] : null,

                  // "doctorNum": form.doctorNum,
                  "sex": form.sex,
                  "email": form.email,
                  // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                  "birthday": form.birthday,
                  "certStatus": form.certStatus,
                  "registerChannel": form.registerChannel,
                  "nvqUrl": '',
                }
                break;
              }
              case '1004': {
                data = {
                  "type": form.type,
                  "name": form.name,
                  "tel": form.tel,
                  "hospitalName": form.hospitalName,
                  "hospitalOffices": form.hospitalOffices ? form.hospitalOffices[0] + '-' + form.hospitalOffices[1] : null,
                  "jobTitle": form.jobTitle,

                  "province": form.province ? form.province[0] : null,
                  "city": form.province ? form.province[1] : null,

                  // "doctorNum": form.doctorNum,
                  "sex": form.sex,
                  "email": form.email,
                  // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                  "birthday": form.birthday,
                  "certStatus": form.certStatus,
                  "registerChannel": form.registerChannel,
                  "nvqUrl": '',
                }
                break;
              }
              case '1005': {
                data = {
                  "type": form.type,
                  "name": form.name,
                  "tel": form.tel,
                  "school": form.school,
                  "education": form.education,
                  "specialty": form.specialty,

                  "province": form.province ? form.province[0] : null,
                  "city": form.province ? form.province[1] : null,

                  // "doctorNum": form.doctorNum,
                  "sex": form.sex,
                  "email": form.email,
                  // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                  "birthday": form.birthday,
                  "certStatus": form.certStatus,
                  "registerChannel": form.registerChannel,
                  "nvqUrl": '',
                }
                break;
              }
              case '1006': {
                data = {
                  "type": form.type,
                  "name": form.name,
                  "tel": form.tel,
                  "workUnit": form.workUnit,
                  "post": form.post,

                  "province": form.province ? form.province[0] : null,
                  "city": form.province ? form.province[1] : null,

                  // "doctorNum": form.doctorNum,
                  "sex": form.sex,
                  "email": form.email,
                  // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                  "birthday": form.birthday,
                  "certStatus": form.certStatus,
                  "registerChannel": form.registerChannel,
                  "nvqUrl": '',
                }
                break;
              }
              case '1007': {
                data = {
                  "type": form.type,
                  "name": form.name,
                  "tel": form.tel,
                  "workUnit": form.workUnit,
                  "post": form.post,

                  "province": form.province ? form.province[0] : null,
                  "city": form.province ? form.province[1] : null,

                  // "doctorNum": form.doctorNum,
                  "sex": form.sex,
                  "email": form.email,
                  "birthday": form.birthday,
                  // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                  "certStatus": form.certStatus,
                  "registerChannel": form.registerChannel,
                  "nvqUrl": '',
                }
                break;
              }
              default: {
                break;
              }
            }
            // 判断form中包不包含id
            if (form.id) {
              // console.log('修改')
              await this.putList(form.id, data)
            } else {
              // console.log('新增')
              await this.postList(data)
              //新增成功后再获取用户信息，并赋值
              await this.echo(data.tel)
            }
            this.addPersonDisabled = true
          } else {
            return false;
          }
        });
      },
      //  新增注册会员信息
      async postList(data) {
        const response = await postMember(data)
        if(response.code === 0){
          this.$message.success("新增成功~")
        }else {
          this.$message.warning(response.message)
        }
      },
      //  修改注册会员信息
      async putList(id,data) {
        const response = await putMember(id,data)
        if(response.code === 0){
          this.$message.success("修改成功~")
        }else {
          this.$message.warning(response.message)
        }
      },
      addPersonOnSubmit(){  ////人员名单新增-确认对话框
        this.$refs.PersonnelListRef.validate(async valid => {
          if (valid) {
            let form = this.personnelListForm
            if(form.id){
              let data = {
                liveMeetingId:this.$route.query.liveMeetingId
              }
              const response = await insCreditRecord(form.id,this.$route.query.id,data)
              if(response.code === 200){
                this.$message.success("添加成功~")
                //  关闭对话框并刷新列表
                await this.get_columnsList()
                this.addPersonClose()
              }else {
                this.$message.warning(response.message)
              }
            }else {
              this.$message.warning("该手机号未注册，请先注册~")
            }
          } else {
            return false;
          }
        });
      },
      //获取省份城市医院
      getPCH(){
        let province = []
        let hospitalName = []
        this.City.forEach((item,index)=>{
          let city = []
          province.push({
            value:item.name,
            label:item.name,
            children:city
          })
          item.cityList.forEach((list)=>{
            city.push({
              value:list.name,
              label:list.name,
              loading:false,
            })
          })
          province[index].children = city
        })
        this.provincePtions = province
      },
      //获取科室列表
      async getHospitalOfficesList() {
        const response = await getLoginDepartment()
        if(response.code === 0){
          let oneOff = []
          let list = response.data.wySy
          list.forEach((item,index)=>{
            let twoOff = []
            oneOff.push({
              value:item.title,
              label:item.title,
              children:twoOff
            })
            item.zoffices.forEach((lists)=>{
              twoOff.push({
                value:lists.title,
                label:lists.title,
              })
            })
            oneOff[index].children = twoOff
          })
          this.hospitalOfficesList = oneOff
        }
      },
      //获取职称列表
      async getJobTitleList(identify,title) {
        //type:identify,pageNum,title
        const response = await getTitle(identify,1,title)
        if (response.code === 0) {
          this.jobTitleList = response.data.row
        }
      },
    }
  }
  </script>

  <style lang="scss" scoped>
  .feature-top{
    background-color: #fff;
    display: flex;
    height: 70px;
    line-height: 50px;
    border-bottom: 15px solid #f0f2f5;
    .feature-top-left{
      width: 50%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: black;
      font-weight: bold;
      padding-left: 15px;
      font-size: 16px;

    }.feature-top-right{
      width: 50%;
    text-align: right;
    margin-right: 10px;
    .feature-top-right-icon{
      color: #1890ff;
      cursor: pointer;
      font-size: 18px;
    }
    }
  }
  .feature-list{
    width: 100%;
    margin: auto;
    background-color: #f0f2f5;
  }
  .gutter-example >>> .ant-row > div {
    background: transparent;
    border: 0;
  }
  .gutter-box-add{
    border: 2px #c5c5c5 dashed;
    margin: 10px;
    height: 185px;
    line-height: 185px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
  }
  .gutter-box {
    background: #fff;
    border: 1px solid #ececec;
    margin: 10px;
    .gutter-box-top{
      display: flex;
      width: 80%;
      margin: auto;
      margin-top: 15px;
      .image{
        width: 50px;
        height: 50px;
        border-radius: 50px;
        overflow: hidden;
        background-color: #217dd4;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .gutter-box-top-title{
        margin-left: 15px;
        height: 100px;
        .gutter-box-top-title-title{
          font-size: 16px;
          height: 30px;
          font-weight: bold;
          color: black;
        }
        .gutter-box-top-title-type{
          height: 40px;
          color: #8c8a8a;
          margin-top: 5px;
        }
        .gutter-box-top-title-open{
          margin-top:5px;
          .upDown{
            width: auto;margin-left: 15px;
            .upDown_up{
              color: black;
              font-size: 14px;
            }
            .upDown_up:hover{
              color: #1890ff;
            }
          }
        }
      }
    }
    .gutter-box-btn{
      height: 50px;
      margin-top: 20px;
      background-color: #f7f9fa;
      border-top: 1px solid #ececec;
      .gutter-box-btn-font{
        display: flex;
        height: 50px;
        line-height: 50px;
        font-size: 14px;
        cursor: pointer;
        color: black;
        justify-content: space-around;
        .font:hover{
          color: #1890ff;
        }
      }
    }
  }
  .uploadImg{
    border: #DDDDDD 2px dashed;
    width: 120px;
    height: 120px;
    background-image: url("../../../assets/add.png");
    background-size:100% 100%;
    background-repeat:no-repeat;
    cursor: pointer;
  }
  .upload_deyails{
    border: #DDDDDD 2px dashed;
    width: 220px;
    height: 120px;
    margin-left: 20px;
    text-align: center;
  }
  .upload_LiveBroadcast{
    border: #DDDDDD 2px dashed;
    width: 120px;
    height: 120px;
    margin-left: 20px;
    text-align: center;
  }
  .upload_gutter{
    border: #DDDDDD 2px dashed;
    width: 100%;
    height: auto;
  }
  .uploadpdf{
    width: 450px;
    height: 200px;
    background-image: url("../../../assets/img_2.png");
    background-size:100% 100%;
    background-repeat:no-repeat;
    cursor: pointer;
  }
  .upload_pdf{
    margin-top: 15px;
    text-align: center;
  }
  .content_LiveBroadcastCalendar{
    width: 100%;
    height: auto;
    .content_LiveBroadcastCalendar-top{
      width: 40%;
      height: 50px;
      display: flex;
      .add-file{
        width:80px;
        //text-align: center;
        //border: 1px solid #dedcdc;
        //border-radius: 5px;
        height:32px;
        //background-color: #b0b0b0;
        float:left;
        .fileAdd{
          width:80px;
          height:32px;
          opacity:0;
          margin-top: -25px;
          margin-left: -15px;
        }
      }
    }
    .content_LiveBroadcastCalendar-mid{
      width: 100%;
      display: flex;
      .content_LiveBroadcastCalendar-mid-left{
        width: 40%;
      }
      .content_LiveBroadcastCalendar-mid-right{
        width: 60%;
        //background-color: #00FFFF;
      }
    }
    //.changeBjys{
    //  background-color: red !important;
    //}
    .changeBjys {
      background-color: #cd1313;
      color: #fff;
    }
  }
  .addressSpan{
    width: 100%;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  </style>
